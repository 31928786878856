import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationStart, Params, Router } from '@angular/router';
import { FlightSearch, TimeWindow as FlightSearchArrivalTimeWindow, TimeWindow as FlightSearchDepartureTimeWindow, ITravelAgentDetails2, PackageRequest, TravelAgentDetails2 } from '@shared/api/be-api.generated';
import { toPriceFormat, unique } from '@shared/common';
import { IQueryStringAddOn, pages, SearchQueryParams } from '@shared/models/common';
import { filter } from 'rxjs';
import { ComponentBaseService } from './component-base.service';
import { createQueryParamsParser, toQueryParams } from './query-params.helper';

@Injectable()
export class QueryParamsService {
  private _value!: SearchQueryParams;

  get value(): SearchQueryParams {
    return this._value;
  }

  set value(params: Params) {
    const {
      getDate, getBoolean, getString, getNumber, getNumberType, getUppercased, getArray, getObject
    } = createQueryParamsParser(params);

    const travelAgent = getObject<ITravelAgentDetails2>('travelAgent');

    this._value = {
      request: new PackageRequest({
        fromDate: getDate('fromDate'),
        toDate: getDate('toDate'),
        resortId: getString('resortId'),
        ratePlanTypeId: getString('ratePlanTypeId'),
        adults: getNumber('adults'),
        promoCode: getString('promoCode'),
        flightSearch: new FlightSearch({
          fromAirportCode: getUppercased('flightSearch[fromAirportCode]'),
          toAirportCode: getUppercased('flightSearch[toAirportCode]'),

          stops: getNumber('flightSearch[stops]'),
          airline: getString('flightSearch[airline]'),
          cabinType: getString('flightSearch[cabinType]'),
          cabinTypes: getArray<string>('flightSearch[cabinTypes]'),

          departureTimeWindow: getNumberType<FlightSearchDepartureTimeWindow>('flightSearch[departureTimeWindow]'),
          arrivalTimeWindow: getNumberType<FlightSearchArrivalTimeWindow>('flightSearch[arrivalTimeWindow]')
        }),
        children: getArray<number>('children'),
      }),
      roomTypeId: getString('roomTypeId'),
      specialOfferId: getString('specialOfferId'),
      sortType: getString('sortType'),
      flightId: getString('flightId'),
      inboundAirportTransferId: getString('inboundAirportTransferId'),
      outboundAirportTransferId: getString('outboundAirportTransferId'),
      addOns: getArray<IQueryStringAddOn>('addOns'),
      travelAgent: travelAgent ? new TravelAgentDetails2(travelAgent) : undefined,
      priceFormat: toPriceFormat(getString('priceFormat')),
      isTest: getBoolean('isTest'),
      cartEmail: getString('cartEmail')
    }
  }

  routeChanged = new EventEmitter<SearchQueryParams>();

  constructor(
    private readonly _services: ComponentBaseService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router
  ) {
    let isClickedBackOrNext = false;

    this._router.events
      .subscribe((event: Event) => {
        if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
          isClickedBackOrNext = true;
        }
      });

    this._route.queryParams
      .pipe(filter(x => !!x))
      .subscribe(params => {
        this.value = params;

        if (isClickedBackOrNext) {
          this.routeChanged.emit();
          isClickedBackOrNext = false;
        }
      });
  }

  get isConfirmationPage() {
    return this._services.isPage(pages.confirmation);
  }

  patchQueryParams(newValues: Partial<SearchQueryParams>) {
    this._value = { ...this._value, ...newValues };

    if (this.isConfirmationPage) {
      // no need to update query string here
      return;
    }

    const newQueryParams: Params = toQueryParams(this.value);
    const snapshotParams = this._route.snapshot.queryParams;
    const valueKeys = [...Object.keys(this.value), ...Object.keys(this.value.request), ...Object.keys(newQueryParams)];

    const allowedThirdPartyParams = Object.keys(snapshotParams)
      .filter(key => !valueKeys.includes(key))
      .reduce((data, key) => ({ ...data, [key]: snapshotParams[key] }), {} as Params);

    const queryParams = { ...allowedThirdPartyParams, ...newQueryParams };
    const uniqueKeys = unique([...Object.keys(snapshotParams), ...Object.keys(newQueryParams)]);
    const isNeedToUpdate = uniqueKeys.find(key => queryParams[key] !== snapshotParams[key]);

    if (isNeedToUpdate) {
      this._router.navigate([], { relativeTo: this._route, queryParams });
    }
  }
}
