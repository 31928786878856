<ng-container *transloco="let t">
  <div class="flight-filter background-color-default">
    <div class="flight-filter-content">
      <div class="block-header sidebar-display-none">
        <div class="block-header-text">
          <app-icon-button
            (click)="reset()"
            name="sort"
            alt="sortBy"
            stroked="iconStroke">
          </app-icon-button>
          {{t('sidebar.flight.header')}}
          &nbsp;
        </div>
      </div>
      <div class="flight-filter-groups">
        <div class="flight-filter-group-item"
          *ngFor="let group of groups; let i = index; trackBy: 'scope' | createTrackBy">
          <app-filter-group
            (selectedChanged)="select(group.scope, $event)"
            [selectedItem]="group.selectedItem"
            [scope]="group.scope"
            [isFirst]="i === 0"
            [translateKeyPrefix]="translateKeyPrefix"
            [items]="group.items">
          </app-filter-group>
        </div>
      </div>
    </div>
  </div>
</ng-container>
