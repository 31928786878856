import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-payment-cards',
  templateUrl: './payment-cards.component.html',
  styleUrls: ['./payment-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentCardsComponent {
  @Input() justifyContent: 'center' | 'flex-start' | 'flex-end' = 'center';
  paymentCards = ['visa', 'maestro', 'amex', 'mastercard']; // 'jcb', 'discover'
}
