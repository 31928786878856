import { Injectable } from '@angular/core';
import { TravelAgentDetails2 } from '@shared/api/be-api.generated';
import { QueryParamsService } from './query-params.service';

@Injectable()
export class TravelAgentService {
  private _travelAgentDetails?: TravelAgentDetails2;

  constructor(
    private readonly _queryParams: QueryParamsService
  ) {
    if (this._queryParams.value.travelAgent) {
      this._travelAgentDetails = new TravelAgentDetails2(this._queryParams.value.travelAgent);
    }
  }

  set travelAgentDetails(data: TravelAgentDetails2 | undefined) {
    this._travelAgentDetails = data;
    this._queryParams.patchQueryParams({ travelAgent: this.travelAgentDetails });
  }

  get travelAgentDetails() {
    return this._travelAgentDetails;
  }
}
