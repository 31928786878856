import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { PackageService } from '@shared/services/package.service';
import { Package, RatePlanType, Resort, RoomType, SpecialOffer } from '@shared/api/be-api.generated';
import { CarouselImage, WithId } from '@shared/models/common';
import { ComponentBase } from '@shared/base/component.base';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { HtmlContentService } from '@shared/services/html-content.service';
import { TenantService } from '@shared/services/tenant.service';

@Component({
  selector: 'app-room-viewer',
  templateUrl: './room-viewer.component.html',
  styleUrls: [
    './scss/room-viewer.component.desktop.scss',
    './scss/room-viewer.component.mobile.scss',
  ]
})
export class RoomViewerComponent extends ComponentBase {
  @ViewChild('packageDetails') packageDetails!: TemplateRef<unknown>;
  @ViewChild('hotelierMessage') hotelierMessage!: TemplateRef<unknown>;

  isActive = true;

  ratePlanType?: RatePlanType;
  images: WithId<CarouselImage>[] = [];
  selectedPackage?: WithId<Package>;
  specialOffers?: SpecialOffer[];
  specialOffer?: SpecialOffer;
  roomType?: RoomType;
  resort?: Resort;

  isLoadingHtmlContent = false;
  hotelierMessageText?: string;

  priceFormat = this._tenant.mainPriceFormat;

  constructor(
    private readonly _htmlContent: HtmlContentService,
    private readonly _package: PackageService,
    private readonly _sidebar: SidebarService,
    private readonly _tenant: TenantService,
    services: ComponentBaseService
  ) {
    super(services);
    super.pushSub(this._package.setWhenDataChanged(() => this.setData()));
  }

  setData() {
    this.ratePlanType = this._package.ratePlanType;
    this.images = this._package.images;
    this.selectedPackage = this._package.selectedPackage;
    this.specialOffers = this._package.specialOffers;
    this.specialOffer = this._package.specialOffer;
    this.roomType = this._package.roomType;
    this.resort = this._package.resort;
  }

  selectSpecialOffer(event: MatRadioChange) {
    this._package.selectSpecialOffer(event.value);
  }

  openPackageDetails() {
    this._sidebar.open({
      template: this.packageDetails,
      position: 'end',
      width: 'big'
    });
  }

  clickSubheaderLink() {
    this.setHotelierMessage();

    this._sidebar.open({
      template: this.hotelierMessage,
      position: 'end',
      width: 'big'
    });
  }

  setHotelierMessage() {
    this.isLoadingHtmlContent = true;
    this._htmlContent.get(result => {
      this.isLoadingHtmlContent = false;
      this.hotelierMessageText = result;
    }, 'hotelier-message');
  }
}
