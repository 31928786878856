<app-sidebar-layout
  #addOnDetailsLayout>
  <ng-container>
    <ng-template #header>
      <div class="sidebar-layout-header">
        <div class="sidebar-layout-header-text">
          <h2>{{currentAddOn.name}}</h2>
          <app-icon-button
            class="bs-right"
            stroked="iconInactive"
            buttonClass="close-button"
            (click)="addOnDetailsLayout.close()"
            name="close">
          </app-icon-button>
        </div>
      </div>
    </ng-template>
    <ng-template #content>
      <div class="sidebar-content sidebar-scroll-container">
        <div class="sidebar-carousel-images-container">
          <app-gallery
            [imagePopup]="false"
            [images]="images">
          </app-gallery>
        </div>
        <div class="custom-html-content"
          appTargetBlank
          [innerHTML]="currentAddOn.description | safe: 'html'">
        </div>
      </div>
    </ng-template>
    <ng-template #footer>
      <div class="pricing-container">
        <app-addon-age-groups-pricing
          *ngIf="isPerAgeGroupTypeAddon"
          (countToPurchaseChanged)="updateCountToPurchase($event.quantity, $event.ageGroupId)"
          [isSoldOut]="isSoldOut"
          [currentAddOn]="currentAddOn"
          [addOnsToPurchase]="addOnsToPurchase">
        </app-addon-age-groups-pricing>
        <div class="pricing-details"
          *ngIf="!isPerAgeGroupTypeAddon">
          <app-addon-pricing
            [unavailabilityReason]="unavailabilityReason"
            [currentAddOn]="currentAddOn"
            [addOnsToPurchase]="addOnsToPurchase"
            [isSelectedPackage]="isSelectedPackage"
            [isSoldOut]="isSoldOut"
            (countToPurchaseChanged)="updateCountToPurchase($event)">
          </app-addon-pricing>
          <div class="pricing-details-button">
            <app-select-button
              size="default"
              (click)="purchaseAddOn()"
              [isActive]="isActive"
              [isBlockedWhenActive]="!isSelectedPackage"
              [disabled]="disabled">
            </app-select-button>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</app-sidebar-layout>