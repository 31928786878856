let isInServer = false;

export function setServerMode() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isInServer = true;
}

export function getClientRootFolder() {
  if (!isInServer) {
    throw Error('This method should never be called from client.');
  }

  return process.env['CLIENT_FOLDER'] || 'wwwroot';
}
