import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { defaultPriceFormat, PriceFormat } from '@shared/models/common';
// import { PriceFormat } from '@shared/models/common';
import { QueryParamsService } from '@shared/services/query-params.service';
import { AppCurrencyPipe } from './app-currency.pipe';

@Pipe({ name: 'formatPrice' })
export class FormatPricePipe implements PipeTransform {
  get format() {
    return this._queryParams.value.priceFormat || defaultPriceFormat;
  }

  constructor(
    private readonly _transloco: TranslocoService,
    private readonly _queryParams: QueryParamsService,
    private readonly _appCurrency: AppCurrencyPipe
  ) {
  }

  // pass format: PriceFormat using second parameter if it will be possible to change in app directly
  transform(total: number | undefined, days: number, guests: number, rooms: number, format?: PriceFormat, priceStyle: 'short' | 'full' = 'short') {
    const result = total
      ? this.getPriceByFormat(total, days, guests, rooms, format || this.format)
      : undefined;

    return !!result
      ? this._transloco.translate(['price', format || this.format, priceStyle].join('.'), {
        value: this._appCurrency.transform(result)
      })
      : '';
  }

  getPriceByFormat(total: number, days: number, guests: number, rooms: number, format?: PriceFormat): number {
    switch (format) {
      case 'pppn': return total / days / guests;
      case 'prpn': return total / days / rooms;
      case 'totalRounded': return Math.ceil(total);
      default: return total;
    }
  }
}
