import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'translateFormComponentText'
})
export class TranslateFormComponentTextPipe implements PipeTransform {
  constructor(
    private readonly transloco: TranslocoService
  ) {
  }

  /**
  * The pipe will build translation keys using prefixes and will return first exists translation key
  *
  * e.g. prefixes = ['scope', 'subscope'], keys = ['key1', 'key2']
  *
  * First, pipe will try to load translation for 'scope.subscope' key
  * If failed, then it will try to load translation key: 'scope.subscope.key1'
  * If translation for the key 'scope.subscope.key1' does not exist,
  * Then the pipe will try to translate key 'scope.subscope.key2'
  *
  * @param prefixes - array of string keys, e.g. ['scope', 'subscope']
  * @param keys - arrays of string, to search for specific key existance
  * @returns
  */
  transform(prefixes: string[], ...keys: string[]) {
    const prefix = prefixes.join('.');
    // this is needed to skip errors
    const activeLang = this.transloco.getActiveLang();
    const translations = this.transloco.getTranslation(activeLang);
    let result = this.transloco.getTranslation(activeLang)[prefix];
    if (!result) {
      for (let i = 0; i < keys.length; i++) {
        const translationResult = translations[[prefix, keys[i]].join('.')];
        if (translationResult) {
          result = translationResult;
          break;
        }
      }
    }

    return result;
  }
}
