import { ComponentBaseService } from '@shared/services/component-base.service';
import { ComponentBase } from './component.base';

interface AnimateInput {
  onAnimatingStopped?: () => void;
  type?: 'default' | 'enter' | 'out';
  animationTimeoutMs?: number;
}

export abstract class AnimationComponentBase extends ComponentBase {
  isAnimating = false;
  isEnterAnimating = false;
  isOutAnimating = false;

  protected _onAnimatingStopped?: () => void;
  protected _animationTimeoutMs = 1;

  constructor(
    services: ComponentBaseService
  ) {
    super(services);
  }

  protected _animate({ type, onAnimatingStopped, animationTimeoutMs }: AnimateInput = { type: 'default' }) {
    if (this.isBrowser) {
      onAnimatingStopped = onAnimatingStopped || this._onAnimatingStopped;

      setTimeout(() => {
        switch (type) {
          case 'enter': this.isEnterAnimating = !this.isEnterAnimating; break;
          case 'out': this.isOutAnimating = !this.isOutAnimating; break;
          default: this.isAnimating = !this.isAnimating; break;
        }

        onAnimatingStopped && onAnimatingStopped();
      }, animationTimeoutMs || this._animationTimeoutMs);
    }
  }

  protected _animateCustom(animationTrigger: (isActive: boolean) => void, timeout?: number) {
    animationTrigger(true);
    setTimeout(() => animationTrigger(false), timeout);
  }
}
