import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AddOnPricingType as AddOnPricingResponseType, AddOnResponse } from '@shared/api/be-api.generated';
import { maxCountAddonsToPurchase } from '@shared/consts/common';
import { AddOnToPurchaseViewModel, AddonType, Size, UnavailabilityReason } from '@shared/models/common';

@Component({
  selector: 'app-addon-pricing',
  templateUrl: './addon-pricing.component.html',
  styleUrls: ['./addon-pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddonPricingComponent implements OnChanges {
  @Input() size: Size = 'default';
  @Input() currentAddOn!: AddOnResponse;
  @Input() addOnsToPurchase!: AddOnToPurchaseViewModel[];
  @Input() isSoldOut = false;
  @Input() isSelectedPackage = true;
  @Input() unavailabilityReason?: UnavailabilityReason;
  @Output() countToPurchaseChanged = new EventEmitter<number>();
  @Output() ageGroupsSidebarChanged = new EventEmitter();
  price?: number;
  countToPurchase!: number;
  addOnType?: AddOnPricingResponseType;
  maxCountAddonsToPurchase = maxCountAddonsToPurchase;
  isAgeGroupsAddOn = false;

  ngOnChanges(): void {
    const { price = undefined, type, prices = [] } = this.currentAddOn.pricingModel || {};
    this.price = price;
    this.addOnType = type;
    this.isAgeGroupsAddOn = !!prices.length;

    if (this.addOnType !== AddonType.PerAgeGroup) {
      this.countToPurchase = this.addOnsToPurchase.find(() => true)?.quantity || 1;
    }
  }

  increase() {
    if (this.countToPurchase < this.maxCountAddonsToPurchase && !this.isSoldOut) {
      this.countToPurchase++;
      this.countToPurchaseChanged.emit(this.countToPurchase);
    }
  }

  decrease() {
    if (this.countToPurchase > 1 && !this.isSoldOut) {
      this.countToPurchase--;
      this.countToPurchaseChanged.emit(this.countToPurchase);
    }
  }

  change() {
    this.ageGroupsSidebarChanged.emit();
  }
}
