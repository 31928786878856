<ng-container *transloco="let t">
  <div class="guest-details"
    *ngIf="guestForm">
    <div class="block-header">
      <div class="block-header-text">
        {{t(guestType +'.header', { value: guestNumber })}}
      </div>
    </div>
    <div class="adult-details-content">
      <app-form
        #form
        scope="guest"
        [form]="guestForm"
        [anchorNumber]="[guestType, guestNumber].join('-')"
        [anchorType]="anchorType"
        (selectChanged)="selectChange($event)">

        <ng-template #content
          let-text="text"
          let-label="label"
          let-select="select"
          let-caption="caption"
          let-autocomplete="autocomplete">

          <ng-container #templates>
            <ng-template #country let-field="field">
              <ng-container *ngTemplateOutlet="label; context: { field }"></ng-container>
              <ng-container *ngTemplateOutlet="select; context:{ field, options: countries }"></ng-container>
            </ng-template>
          </ng-container>

          <ng-container #blocks>
            <div class="block-name">
              <div [class]="namePart"
                *ngFor="let namePart of name">
                <ng-container *ngIf="isFlightSelected || namePart !== 'middleName'">
                  <ng-container *ngTemplateOutlet="text; context: { field: namePart}"></ng-container>
                </ng-container>
              </div>
            </div>

            <div class="block-dob"
              *ngIf="isDOB">
              <div class="dob">
                <div class="label"
                  *ngIf="isValidDob; else dobError">
                  <ng-container *ngTemplateOutlet="caption; context: { field: 'dob', required: true }"></ng-container>
                </div>
                <ng-template #dobError>
                  <div class="label">
                    <app-anchor
                      *ngIf="anchorType && firstDobErrorField"
                      [type]="anchorType"
                      [index]="[[guestType, guestNumber].join('-'), firstDobErrorField]">
                    </app-anchor>
                    <mat-error class="error-message bold">
                      {{t('guest.error.dob', { field: dobErrorFields | join: ', ' })}}
                    </mat-error>
                  </div>
                </ng-template>
              </div>
              <div class="dob-details">
                <div [class]="dobPart"
                  *ngFor="let dobPart of dob">
                  <div class="label"></div>
                  <ng-container *ngTemplateOutlet="select; context: {
                    field: dobPart,
                    options: dobOptions[dobPart],
                    hideLabel: true,
                    trackId: 'value'
                  }">
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="block-data">
              <div class="phone"
                *ngIf="isContactInfoRequired">
                <ng-container *ngTemplateOutlet="text; context: { field: 'phone', required: true }"></ng-container>
              </div>
              <div class="country"
                *ngIf="isFlightSelected || isMainAdult">
                <ng-container *ngTemplateOutlet="autocomplete;
                  context: { field: 'country', options: countriesOptions }">
                </ng-container>
              </div>
              <ng-container
                *ngIf="isMainAdult">
                <div class="address"
                  hidden>
                  <ng-container *ngTemplateOutlet="autocomplete; context: {
                    field: 'address',
                    options: addressOptions,
                    isLoading: isLoadingAddress,
                    isSeparatedButton: true
                  }">
                  </ng-container>
                </div>
                <div class="address-data flex-column">
                  <div class="flex-between gap">
                    <div class="street flex-1">
                      <ng-container *ngTemplateOutlet="text; context: { field: 'street', required: true }"></ng-container>
                    </div>
                    <div class="city flex-1">
                      <ng-container *ngTemplateOutlet="text; context: { field: 'city', required: true }"></ng-container>
                    </div>
                  </div>
                  <div class="flex-between gap">
                    <div class="state flex-1">
                      <ng-container *ngTemplateOutlet="text; context: { field: 'state', required: true }"></ng-container>
                    </div>
                    <div class="postcode flex-1">
                      <ng-container *ngTemplateOutlet="text; context: {
                        field: 'postcode',
                        required: true,
                        labelKey: isPostcode ? 'postcode' : 'zipcode'
                    }"></ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="block-gender">
              <div class="gender">
                <ng-container *ngTemplateOutlet="label; context: { field: 'gender'}"></ng-container>
                <div class="value">
                  <div [class]="gender"
                    *ngFor="let gender of genders"
                    [@genderChange]="animatingStateGender[gender]"
                    (click)="genderChange(gender)">
                    <app-icon
                      name="checked"
                      size="default"
                      [class.active]="genderValues[gender] === form.get['gender']()"
                      [class.background-color-selected]="genderValues[gender] === form.get['gender']()"
                      class="select-button select-icon">
                    </app-icon>
                    <div class="not-selectable">
                      {{t('guest.genders.'+ gender)}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </app-form>
    </div>
  </div>
</ng-container>