import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notification-warning',
  templateUrl: './notification-warning.component.html',
  styleUrls: ['./notification-warning.component.scss']
})
export class NotificationWarningComponent {
  @Input() htmlContent!: string;
}
