<app-special-offer-caption-primary
  *ngIf="isPrimary"
  [currentSpecialOffer]="currentSpecialOffer"
  [isAlwaysActive]="isAlwaysActive"
  [isVisibleNotes]="isVisibleNotes">
</app-special-offer-caption-primary>

<app-special-offer-caption-secondary
  *ngIf="isSecondary"
  [currentSpecialOffer]="currentSpecialOffer"
  [isAlwaysActive]="isAlwaysActive"
  [isVisibleNotes]="isVisibleNotes"
  [isVisiblePrice]="isVisiblePrice">
</app-special-offer-caption-secondary>