<div class="account-layout-item"
  [class.edit-mode]="isEditMode"
  *ngIf="!isHidden">
  <div class="account-layout-item-viewer"
    *ngIf="!isEditMode"
    (click)="clickViewText()">
    <span>{{viewText}}</span>
    <app-icon name="next"
      size="small"
      stroked="selected">
    </app-icon>
  </div>
  <div class="account-layout-item-editor"
    *ngIf="isEditMode">
    <ng-template [ngTemplateOutlet]="editor"></ng-template>
  </div>
</div>
