<ng-container *transloco="let t">
  <div class="room-summary flex">
    <div class="content-container">
      <app-confirmation-section
        #section
        scope="roomSummary">
        <app-confirmation-section-record position="full">
          <img width="100%"
            loading="lazy"
            [alt]="resort?.name"
            [src]="firstRoomImageSrc" />
        </app-confirmation-section-record>
        <app-confirmation-section-record
          #record
          labelWidth="60%"
          position="end">
          <div [ngClass]="record.htmlClasses.label"
            [style.min-width]="record.labelWidth">
            {{roomName}}
          </div>
          <div [ngClass]="record.htmlClasses.value">
            {{resortName}}
          </div>
        </app-confirmation-section-record>
        <app-confirmation-section-record position="end"
          field="guests"
          [scope]="section.scope">
          {{t([section.scope, 'guestsValue'] | join, { value: guestsCount })}}
        </app-confirmation-section-record>
        <app-confirmation-section-record position="end"
          field="arrivalDate"
          labelWidth="45%"
          [scope]="section.scope">
          {{arrivalDate | appDate: 'type3'}}
        </app-confirmation-section-record>
        <app-confirmation-section-record position="end"
          field="departureDate"
          labelWidth="45%"
          [scope]="section.scope">
          {{departureDate| appDate: 'type3'}}
        </app-confirmation-section-record>
        <app-confirmation-section-record position="full"
          field="roomDescription"
          [scope]="section.scope">
          {{roomDescription}}
        </app-confirmation-section-record>
        <app-confirmation-section-record position="full">
          <div class="flex-column gap">
            <div [ngClass]="record.htmlClasses.label">{{resortName}}</div>
            <div [ngClass]="record.htmlClasses.value">{{resortAddress}}</div>
          </div>
        </app-confirmation-section-record>
        <app-confirmation-section-record position="full"
          field="specialOffers"
          *ngIf="specialOffers && specialOffers.length"
          [scope]="section.scope">
          <div class="flex-column gap">
            <div *ngFor="let specialOffer of specialOffers"
              [ngClass]="[record.htmlClasses.value, 'flex-start gap']">
              <app-icon name="checkmark"
                filled="successText"
                alt="checked">
              </app-icon>
              <app-special-offer-caption
                [currentSpecialOffer]="specialOffer"
                [isAlwaysActive]="true"
                [isVisibleNotes]="false">
              </app-special-offer-caption>
            </div>
          </div>
        </app-confirmation-section-record>
      </app-confirmation-section>
    </div>
  </div>
</ng-container>