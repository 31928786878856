<ng-container *transloco="let t">
  <div class="package-details">
    <div class="package-details-header">
      <div class="package-details-header-item">
        <app-info-label
          [text]="ratePlanType?.name"
          type="ratePlanType">
        </app-info-label>
        <app-icon-button
          class="bs-right"
          stroked="iconInactive"
          buttonClass="close-button"
          (click)="closeSidebar()"
          name="close">
        </app-icon-button>
      </div>
      <div class="package-details-header-item">
        <div class="room-name"
          *ngIf="currentRoomType">
          {{currentRoomType.name}}
        </div>
      </div>
    </div>
    <div class="package-details-content">
      <div class="photos"
        *ngIf="images.length">
        <app-gallery [images]="images"></app-gallery>
      </div>
      <div class="room-description"
        *ngIf="currentRoomType">
        {{currentRoomType.description}}
      </div>
      <div class="room-amenities">
        <div class="room-amenities-item"
          *ngFor="let amenity of amenities; trackBy: 'id' | createTrackBy">
          {{amenity.item}}
        </div>
      </div>
      <div class="flight"
        *ngIf="flight">
        <div class="flight-header">
          {{t('flight.selected')}}
        </div>
        <div class="flight-subheader">
          <div class="flight-subheader-item">
            {{t('flight.doNotLike')}}
            <div class="link"
              (click)="selectFlight()">
              {{t('flight.choose')}}
            </div>
          </div>
        </div>
        <div class="flight-content">
          <app-flight-details [flight]="flight"></app-flight-details>
        </div>
      </div>
      <div class="included-in-package">
        <div class="included-in-package-header">
          {{t('package.whatIncluded')}}
        </div>
        <div class="included-in-package-description">
          {{resort?.description}}
        </div>
      </div>
    </div>
  </div>
</ng-container>
