<ng-container *transloco="let t">
  <div class="airport-transfer-select"
    *ngIf="transfers.length && arrivalAirport">
    <div class="content-container">
      <div>
        <div class="section-header">
          <div class="section-header-text">
            <app-anchor type="section" index="3"></app-anchor>
            3. {{t('airportTransfer.header')}}
          </div>
          <app-section-toggler [isOnlyForTablet]="false" #toggler></app-section-toggler>
        </div>
        <div class="collapsable"
          [class.collapsed]="toggler.isHidden">
          <div class="section-subheader"
            hidden>
            <div class="section-subheader-text">
              {{t('airportTransfer.subheader')}}
            </div>
          </div>
          <cdk-virtual-scroll-viewport
            [appendOnly]="false"
            class="card-options-container"
            *ngIf="isBrowser && sortedTransfers.length && isActive"
            orientation="horizontal"
            #options
            [itemSize]="itemSize">
            <ng-container
              *cdkVirtualFor="let airportTransfer of sortedTransfers; trackBy: 'id' | createTrackBy;">
              <app-select-option
                [currentAirportTransfer]="airportTransfer"
                *ngIf="!!airportTransfer">
              </app-select-option>
            </ng-container>
          </cdk-virtual-scroll-viewport>
          <div class="not-found"
            *ngIf="!isActive && !unavailabilityReason">
            {{t('soldOut')}}
          </div>
          <div class="not-found"
            *ngIf="unavailabilityReason">
            {{t('price.' + unavailabilityReason.code, { value: unavailabilityReason.value })}}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>