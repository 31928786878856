<ng-container *transloco="let t">
  <ng-template #paginator>
    <div class="block-header-navigation"
      *ngIf="isPrev || isNext">
      <app-icon-button
        (click)="changePage(false)"
        iconSize="big"
        name="left"
        stroked="iconStroke"
        filled="iconFill">
      </app-icon-button>
      {{t('pagination', { from: from + 1, to })}}
      <app-icon-button
        (click)="changePage(true)"
        iconSize="big"
        name="right"
        stroked="iconStroke"
        filled="iconFill">
      </app-icon-button>
    </div>
  </ng-template>
  <app-two-columns
    [isSticky]="false"
    [isReversed]="true"
    *ngIf="isFlightSearchAllowed">
    <ng-template #leftColumn>
      <div class="flight-select">
        <div class="section-header">
          <div class="section-header-text">
            <app-anchor type="section" index="2"></app-anchor>
            2. {{t('flight.confirm.header')}}
          </div>
          <app-info-label
            [text]="ratePlanType?.name"
            type="ratePlanType">
          </app-info-label>
          <app-section-toggler #toggler></app-section-toggler>
        </div>
        <div class="collapsable"
          [class.collapsed]="toggler.isHidden">
          <div class="section-subheader">
            <div class="section-subheader-text">
              {{t('flight.confirm.description')}}
            </div>
            <div class="section-subheader-button visible-tablet">
              <app-icon-button
                (click)="openFilter()"
                name="sort"
                stroked="iconStroke"
                [customButtonSize]="true"
                type="raised">
                {{t('sortBy')}}
              </app-icon-button>
            </div>
          </div>
          <ng-container
            *ngIf="recommendedPackages.length">
            <div class="flight-select-recommended">
              <div class="block-header">
                <div class="block-header-text">
                  1. {{t('flight.recommended.header')}}
                  &nbsp;
                </div>
                <app-icon-button
                  tooltipKey="flight.recommended"
                  tooltipPosition="below"
                  name="help"
                  stroked="iconInactive">
                </app-icon-button>
              </div>
              <div class="flight-select-recommended-content">
                <div class="flight-select-recommended-content-item"
                  [@flightChange]="isAnimatingRecommended"
                  *ngFor="let itemPackage of recommendedPackages; trackBy: 'id' | createTrackBy">
                  <app-flight-details
                    *ngIf="itemPackage"
                    [flight]="itemPackage.flight"
                    [isSelected]="itemPackage.id === selectedPackage?.id">
                    <br />
                    <app-pricing-details
                      size="big"
                      [currentPackage]="itemPackage"
                      [currentRoomType]="roomType">
                    </app-pricing-details>
                  </app-flight-details>
                </div>
              </div>
              <div class="section-subheader">
                <div class="section-subheader-text">
                  {{t('flight.recommended.description')}}
                </div>
              </div>
            </div>
            <app-anchor type="flight" index="list"></app-anchor>
            <div class="flight-select-others"
              *ngIf="visiblePackages.length">
              <div class="block-header">
                <div class="block-header-text">
                  2. {{t('flight.others.header')}}
                  &nbsp;
                </div>
                <ng-container *ngTemplateOutlet="paginator"></ng-container>
              </div>
              <div class="flight-select-others-content">
                <div class="flight-select-others-content-item"
                  [@flightChange]="isAnimatingList"
                  *ngFor="let itemPackage of visiblePackages; trackBy: 'id' | createTrackBy">
                  <app-flight-details
                    [flight]="itemPackage.flight"
                    [isSelected]="itemPackage.id === selectedPackage?.id">
                    <br />
                    <app-pricing-details
                      size="big"
                      [currentPackage]="itemPackage"
                      [currentRoomType]="roomType">
                    </app-pricing-details>
                  </app-flight-details>
                </div>
                <div class="flight-select-others-content-item link-underlined text-center"
                  *ngIf="isVisibleLoadMore"
                  (click)="loadMore()">
                  {{t('flight.others.loadMore')}}
                </div>
              </div>
              <div class="block-header">
                <div class="block-header-text"></div>
                <ng-container *ngTemplateOutlet="paginator"></ng-container>
              </div>
            </div>
          </ng-container>
          <div class="flight-select-not-found"
            *ngIf="!recommendedPackages.length">
            <app-icon
              stroked="selected"
              size="custom"
              [height]="4.375"
              name="airplane"
              scope="images"
              alt="Airline">
            </app-icon>
            <div class="block-header">
              <div class="block-header-text">
                {{t('flight.notFound.header')}}
              </div>
            </div>
            <div class="block-subheader">
              <div class="block-subheader-text">
                {{t('flight.notFound.subheader')}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #rightColumn>
      <app-flight-filter></app-flight-filter>
    </ng-template>
  </app-two-columns>
</ng-container>

<ng-template #flightFilter>
  <app-sidebar-layout scope="flight">
    <ng-template #content>
      <app-flight-filter class="sidebar-scroll-container"></app-flight-filter>
    </ng-template>
  </app-sidebar-layout>
</ng-template>