import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LuxonDateAdapter } from '@angular/material-luxon-adapter';

import { BrowserStateInterceptor } from '@shared/interceptors/browser-state.interceptor';
import { environment } from 'src/environments/environment';
import { luxonCalendarSettings } from './formats/date-time';

import { HTMLTagService } from '@shared/services/html-tag.service';
import { TransferStateService } from '@shared/services/transfer-state.service';
import { BlockOverlayService } from '@shared/services/block-overlay.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';

import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from '@components/app/app.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { NgImageSliderModule } from 'ng-image-slider';

import { API_BASE_URL } from '@shared/base-href.token'

@NgModule({
  declarations: [],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    BrowserTransferStateModule,

    CommonModule,
    AppRoutingModule,
    HttpClientModule,

    ComponentsModule,

    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    NgImageSliderModule
  ],
  providers: [
    MatNativeDateModule,
    TransferStateService,

    SidebarService,
    BlockOverlayService,
    HTMLTagService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true
    },
    {
      provide: API_BASE_URL,
      useValue: environment.baseApiUrl
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline'
      }
    },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: luxonCalendarSettings
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
