import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { BlockOverlayService } from '@shared/services/block-overlay.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { NavigationService } from '@shared/services/navigation.service';
import { SearchService } from '@shared/services/search/search.service';
import { TravelAgentService } from '@shared/services/travel-agent.service';

@Component({
  selector: 'app-account-layout',
  templateUrl: './account-layout.component.html',
  styleUrls: ['./account-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountLayoutComponent extends ComponentBase {
  currentScope?: 'account' = 'account';

  isTravelAgentEditMode = false;
  isPromoCodeEditMode = false;

  isEditMode = false;
  promoCode?: string;

  get travelAgent() {
    const details = this._travelAgent?.travelAgentDetails;

    if (details) {
      return `${details.travelAgencyName} / ${details.travelAgentName}`;
    }

    return undefined;
  }

  constructor(
    private readonly _navigation: NavigationService,
    private readonly _blockOverlay: BlockOverlayService,
    private readonly _travelAgent: TravelAgentService,
    readonly search: SearchService,
    readonly cdr: ChangeDetectorRef,
    services: ComponentBaseService
  ) {
    super(services);

    search.setWhenContextChanged(({ promoCode }) => {
      this.promoCode = promoCode;
      cdr.markForCheck();
    });
  }

  toggleEditTravelAgent() {
    this.isTravelAgentEditMode = !this.isTravelAgentEditMode;
    this.setIsEditMode();
  }

  toggleEditPromoCode() {
    this.isPromoCodeEditMode = !this.isPromoCodeEditMode;
    this.setIsEditMode();
  }

  setIsEditMode() {
    this.isEditMode = this.isPromoCodeEditMode || this.isTravelAgentEditMode;
    this.currentScope = this.isEditMode ? undefined : 'account';
  }

  closeEditMode() {
    if (this.isPromoCodeEditMode) {
      this.toggleEditPromoCode();
    }
    if (this.isTravelAgentEditMode) {
      this.toggleEditTravelAgent();
    }
  }

  closeSidebar() {
    this._blockOverlay.close();
  }

  backToSearch() {
    this._navigation.openSearch();
    this.closeSidebar();
  }
}
