<ng-container *transloco="let t">
  <app-sidebar-layout
    scope="termsAndConditions">
    <ng-template #content>
      <div class="term-and-conditions sidebar-scroll-container"
        *ngIf="isVisible">
        <ng-container *ngFor="let item of items;">
          <div
            class="gap margin-1-y"
            [class.block-header]="!sections.includes(item)"
            [class.section-header]="sections.includes(item)">
            <div
              [innerHtml]="custom[item]
                ? custom[item].header
                : t('termsAndConditions.'+ item + '.header', { value: tradingName })"
              [class.block-header-text]="!sections.includes(item)"
              [class.section-header-text]="sections.includes(item)">
            </div>
          </div>
          <div
            *ngIf="!contentLess.includes(item)"
            [innerHtml]="custom[item]
              ? custom[item].content
              : t('termsAndConditions.'+ item + '.content', { value: tradingName })"
            [class.block-content]="!sections.includes(item)"
            [class.section-conent]="sections.includes(item)">
          </div>
        </ng-container>
      </div>
    </ng-template>
  </app-sidebar-layout>
</ng-container>
