import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SpecialOffer } from '@shared/api/be-api.generated';
import { TenantService } from '@shared/services/tenant.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-special-offer-caption',
  templateUrl: './special-offer-caption.component.html',
  styleUrls: ['./special-offer-caption.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpecialOfferCaptionComponent {
  @Input() currentSpecialOffer!: SpecialOffer;
  @Input() isVisibleNotes = true;
  @Input() isAlwaysActive = false;
  @Input() isVisiblePrice = false;

  isPrimary = false;
  isSecondary = false;

  constructor(
    tenant: TenantService
  ) {
    this.isPrimary = !environment.secondarySpecialOfferView?.includes(tenant.id);
    this.isSecondary = !this.isPrimary;
  }
}
