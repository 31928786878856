<ng-container *transloco="let t">
  <div class="airport-transfer-pricing default">
    <div class="header"
      [class.with-price]="isSelectedPackage"
      *ngIf="currentAirportTransfer">
      <div class="header-default uppercased">
        {{t('airportTransfer.pricingType.perPerson')}}
      </div>
      <div class="price-final"
        *ngIf="currentAirportTransfer.price && currentAirportTransfer.price > 0">
        {{currentAirportTransfer.price | appCurrency}}
      </div>
      <div class="price-final color-textSuccessPrice no-padding w-100"
        *ngIf="currentAirportTransfer.price === 0">
        {{t('complimentary')}}
      </div>
    </div>
    <div class="price"
      *ngIf="isSoldOut">
      <div class="not-found">
        {{t('soldOut')}}
      </div>
    </div>
  </div>
</ng-container>