import { Component } from '@angular/core';
import { BookingService } from '@booking/services/booking.service';
import { AddOnResponse } from '@shared/api/be-api.generated';
import { ReservationVerificationSectionBase } from '@shared/base/reservation-verification-section.base';
import { AddOnService } from '@shared/services/add-on.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { TenantService } from '@shared/services/tenant.service';

@Component({
  selector: 'app-protect-vacation',
  templateUrl: './protect-vacation.component.html',
  styleUrls: ['./protect-vacation.component.scss']
})
export class ProtectVacationComponent extends ReservationVerificationSectionBase<'protect'> {
  addOn?: AddOnResponse;
  isYesSelected?: boolean = undefined;
  isTouched = false;
  isShort = this._createDefaultIsShortForm();
  couplesTopLinks: string[] = ['https://www.tripmate.com/wpUF438C'];
  topLinks: string[] = [];

  anchorId = this.getAnchorId('protectVacationError');
  get isValid() {
    return !this.addOn || this.isYesSelected !== undefined;
  }

  constructor(
    private readonly _addOn: AddOnService,
    private readonly _tenant: TenantService,
    booking: BookingService,
    services: ComponentBaseService
  ) {
    super('protectVacation', booking, services);

    this._initializeData();
    this.pushSub(_addOn.setWhenDataChanged(() => this._initializeData()));
    this.executeTest(() => this._testSelect());
  }

  private _testSelect() {
    if (this.addOn && !this.isYesSelected) {
      this.select(true);
    }
  }

  private _initializeData() {
    this.addOn = this._addOn.tripProtectionAddon;
    this.topLinks = this._tenant.isCouplesTenant ? this.couplesTopLinks : [];

    if (this._addOn.isTripProtectionPurchased) {
      this.isTouched = true;
      this.isYesSelected = true;
      this._openShortForm();
    }
  }


  select(includeProtect: boolean) {
    this.isTouched = true;
    this.isYesSelected = includeProtect;

    if (includeProtect) {
      this._openShortForm();
    }

    this._addOn.changeTripProtection(includeProtect);
    this._animate();
    this._booking.saveCartRequest();
  }

  private _openShortForm() {
    this._animateCustom(() => this.isShort.component = true)
    this._animateCustom(() => this.isShort.label = true, 200);
    this._animateCustom(() => this.isShort.price = true, 400);
  }

  closeShortForm() {
    this.isShort = this._createDefaultIsShortForm();
  }

  _createDefaultIsShortForm() {
    return {
      component: false,
      label: false,
      price: false
    };
  }

  protected override verificationStarted() {
    this.isTouched = true;
  }
}
