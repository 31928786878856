<ng-container *transloco="let t">
  <div [ngClass]="['addon-pricing', size]"
    *ngIf="isSelectedPackage">
    <div class="header"
      [class.with-price]="price !== undefined && isSelectedPackage"
      *ngIf="currentAddOn">
      <div class="header-default uppercased">
        {{addOnType | addOnType}}
      </div>
      <div class="price-final"
        *ngIf="price && price > 0">
        {{price | appCurrency}}
      </div>
      <div class="price-final color-textSuccessPrice"
        *ngIf="price === 0">
        {{t('free')}}
      </div>
    </div>
    <div class="price"
      *ngIf="currentAddOn && !isSoldOut">
      <div class="age-groups" *ngIf="isAgeGroupsAddOn; else singlePriceTemplate">
        <div>
          <ng-container *ngIf="addOnsToPurchase.length">
            <span class="link" (click)="change()">
              {{t('change')}}
            </span>
          </ng-container>
        </div>
      </div>
      <ng-template #singlePriceTemplate>
        <div class="price-counter">
          <app-icon-button
            name="minus"
            filled="default"
            buttonSize="big"
            [disabled]="countToPurchase <= 1 || isSoldOut"
            [stroked]="(countToPurchase > 1 && !isSoldOut) ? 'selected': 'border'"
            alt="decrease"
            (click)="decrease()">
          </app-icon-button>
          <span class="price-counter-text">{{countToPurchase}}</span>
          <app-icon-button
            name="plus"
            filled="default"
            buttonSize="big"
            [disabled]="countToPurchase >= maxCountAddonsToPurchase || isSoldOut"
            [stroked]="(countToPurchase < maxCountAddonsToPurchase && !isSoldOut) ? 'selected': 'border'"
            alt="increase"
            (click)="increase()">
          </app-icon-button>
        </div>
      </ng-template>
    </div>
    <div class="price"
      *ngIf="isSoldOut">
      <div class="not-found"
        *ngIf="!unavailabilityReason">
        {{t('soldOut')}}
      </div>
      <div class="not-found"
        *ngIf="unavailabilityReason">
        {{t('price.' + unavailabilityReason.code , { value: unavailabilityReason.value})}}
      </div>
    </div>
  </div>
</ng-container>