<ng-container *transloco="let t">
  <div class="room-viewer"
    *ngIf="!roomType">
    <div class="section-subheader wrap flex">
      <app-anchor type="section" index="1"></app-anchor>
      <div class="not-found">
        {{t('room.noRoom')}}
      </div>
    </div>
  </div>
  <div class="room-viewer"
    *ngIf="roomType">
    <div class="content-container">
      <div>
        <div class="section-header">
          <div class="section-header-text">
            <app-anchor type="section" index="1"></app-anchor>
            1. {{t('room.header')}}
          </div>
          <app-info-label
            [text]="ratePlanType?.name"
            type="ratePlanType">
          </app-info-label>
          <app-section-toggler #toggler></app-section-toggler>
        </div>
        <div class="collapsable"
          [class.collapsed]="toggler.isHidden">
          <div class="section-subheader wrap">
            <div class="section-subheader-text">
              {{t('room.viewer.subheader.text')}}&nbsp;
            </div>
            <!-- <div class="section-subheader-link"
              (click)="clickSubheaderLink()">
              {{t('room.viewer.subheader.link')}}
            </div> -->
          </div>
          <div class="room-information"
            [class.no-special-offer]="!specialOffer">
            <div class="room-photos"
              [class.no-images]="!images.length">
              <app-gallery [images]="images"></app-gallery>
            </div>
            <div class="room-data">
              <div class="room-details">
                <div class="room-details-main">
                  <div class="hotel-name">
                    {{resort?.name}}
                  </div>
                  <div class="room-name"
                    (click)="openPackageDetails()">
                    <div class="room-name-caption">
                      {{roomType.caption}}
                    </div>
                    <app-icon-button
                      class="bs-right"
                      iconSize="big"
                      buttonSize="big"
                      name="info"
                      filled="selected"
                      alt="room.info">
                    </app-icon-button>
                  </div>
                  <div class="room-description">
                    {{roomType.description}}
                  </div>
                  <div class="link"
                    *ngIf="specialOffers?.length"
                    (click)="openPackageDetails()">
                    {{t('moreDetails')}}
                  </div>
                </div>
              </div>
              <div class="room-amenities"
                *ngIf="roomType?.amenities?.length && !specialOffers?.length">
                <div class="room-amenities-item"
                  *ngFor="let amenity of roomType?.amenities">
                  {{amenity}}
                </div>
              </div>
              <div class="link"
                *ngIf="!specialOffers?.length"
                (click)="openPackageDetails()">
                {{t('moreDetails')}}
              </div>
              <div class="special-offers"
                *ngIf="specialOffer">
                <ng-container>
                  <div class="special-offers-label">
                    {{t('offer.select')}}:
                  </div>
                  <mat-radio-group class="special-offers-content"
                    [ngModel]="specialOffer"
                    (change)="selectSpecialOffer($event)">
                    <mat-radio-button
                      *ngFor="let currentSpecialOffer of specialOffers; trackBy: 'specialOfferId' | createTrackBy"
                      [value]="currentSpecialOffer">
                      <app-special-offer-caption
                        [isVisiblePrice]="true"
                        [currentSpecialOffer]="currentSpecialOffer">
                      </app-special-offer-caption>
                    </mat-radio-button>
                  </mat-radio-group>
                </ng-container>
              </div>
              <app-pricing-details
                *ngIf="roomType"
                size="big"
                class="big-not-found"
                [priceFormat]="priceFormat"
                [currentRoomType]="roomType"
                [showWhenUnavailable]="true"
                [currentPackage]="selectedPackage">
              </app-pricing-details>
            </div>
          </div>
          <div class="room-footer">
            <app-room-select></app-room-select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #packageDetails>
    <app-sidebar-layout>
      <ng-template #content>
        <app-package-details
          *ngIf="roomType"
          [currentRoomType]="roomType"
          [currentPackage]="selectedPackage">
        </app-package-details>
      </ng-template>
      <ng-template #footer>
        <app-pricing-details
          size="big"
          [showWhenUnavailable]="true"
          [currentRoomType]="roomType"
          [currentPackage]="selectedPackage">
        </app-pricing-details>
      </ng-template>
    </app-sidebar-layout>
  </ng-template>

  <ng-template #hotelierMessage>
    <app-sidebar-layout #sidebarLayout>
      <ng-template #header>
        <div class="sidebar-layout-header">
          <div class="sidebar-layout-header-text">
            <h2>{{t('room.viewer.subheader.link')}}</h2>
            <app-icon-button
              class="bs-right"
              stroked="iconInactive"
              buttonClass="close-button"
              (click)="sidebarLayout.close()"
              name="close">
            </app-icon-button>
          </div>
        </div>
        <div class="sidebar-layout-subheader">
          <p>Some subheader text</p>
        </div>
      </ng-template>
      <ng-template #content>
        <div
          [class.flex-start]="!isLoadingHtmlContent"
          [class.flex]="isLoadingHtmlContent"
          [class.loader]="isLoadingHtmlContent"
          class="margin-1-y">
          <ng-container *ngIf="isLoadingHtmlContent; else hotelierMessageTextTemplate">
            <app-loading-icon></app-loading-icon>
            <h2 class="color-selected">{{t('contentLoading')}}</h2>
          </ng-container>
          <ng-template #hotelierMessageTextTemplate>
            <div *ngIf="hotelierMessageText; else defaultHotelierMessageTextTemplate"
              [innerHtml]="hotelierMessageText"></div>
          </ng-template>
          <ng-template #defaultHotelierMessageTextTemplate>
            Default message
          </ng-template>
        </div>
      </ng-template>
    </app-sidebar-layout>
  </ng-template>
</ng-container>