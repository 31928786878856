import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FlightItinerary, Package, RatePlanType, Resort, RoomType } from '@shared/api/be-api.generated';
import { itemsWithId, createCarouselImages } from '@shared/common';
import { CarouselImage, WithId } from '@shared/models/common';
import { PackageService } from '@shared/services/package.service';
import { ComponentBase } from '@shared/base/component.base';
import { BlockOverlayService } from '@shared/services/block-overlay.service';
import { ComponentBaseService } from '@shared/services/component-base.service';

@Component({
  selector: 'app-package-details',
  templateUrl: './package-details.component.html',
  styleUrls: ['./package-details.component.scss']
})
export class PackageDetailsComponent extends ComponentBase implements OnChanges {
  @Input() currentRoomType!: RoomType;
  @Input() currentPackage?: WithId<Package>;

  resort?: Resort;
  ratePlanType?: RatePlanType;
  flight?: FlightItinerary;
  images: WithId<CarouselImage>[] = [];
  amenities: WithId<{ item: string }>[] = [];
  roomType?: RoomType;

  constructor(
    private readonly _package: PackageService,
    private readonly _blockOverlay: BlockOverlayService,
    services: ComponentBaseService
  ) {
    super(services);

    this.ratePlanType = this._package.ratePlanType
    this.resort = this._package.resort;
    this.roomType = this._package.roomType;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentPackage']) {
      this.flight = this.currentPackage ? this._package.getFlight(this.currentPackage) : undefined;
    }

    if (changes['currentRoomType']) {
      this.amenities = itemsWithId(this.currentRoomType?.amenities?.map(item => ({ item })));
      this.images = createCarouselImages(this.currentRoomType);
    }
  }

  closeSidebar() {
    this._blockOverlay.close();
  }

  selectFlight() {
    this.closeSidebar();
    this.scrollTo('section', 2);
  }
}
