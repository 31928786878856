import { Directive, Output, EventEmitter, AfterViewInit, ViewContainerRef } from '@angular/core';
import { SsrHelperService } from '@shared/services/ssr-helper.service';

@Directive({
  selector: '[appInit]'
})
export class InitDirective implements AfterViewInit {
  @Output() appInit = new EventEmitter<HTMLElement>();

  constructor(
    private ssrHelper: SsrHelperService,
    private _viewContainer: ViewContainerRef
  ) { }

  ngAfterViewInit(): void {
    if (this.ssrHelper.isBrowser) {
      window.setTimeout(() => this.appInit.emit(this._viewContainer.element.nativeElement));
    }
  }
}
