import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ConfirmationService } from '@confirmation/services/confirmation.service';
import { tripProtectionCategory } from '@shared/consts/common';

@Component({
  selector: 'app-protect-vacation-summary',
  templateUrl: './protect-vacation-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProtectVacationSummaryComponent {
  booking = this._confirmation.values.bookingDetails;
  addOn = (this.booking.addOns || [])
    .find(addOn => addOn.categoryCode === tripProtectionCategory);

  constructor(
    private readonly _confirmation: ConfirmationService
  ) {
  }
}
