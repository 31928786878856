import { SearchContext } from '@shared/api/be-api.generated';

export const machines = {
  search: {
    id: 'search',
    deserializer: SearchContext.fromJS
  },
  searchSidebar: {
    id: 'searchSidebar',
    deserializer: undefined
  },
  sidebar: {
    id: 'sidebar',
    deserializer: undefined
  },
  theme: {
    id: 'theme',
    deserializer: undefined
  }
} as const;

const derializers = Object.values(machines);

export function deserializeContext<T>(value: T, machineId: string) {
  const deserializer = derializers.find(d => d.id === machineId)?.deserializer;

  return deserializer ? deserializer(value) : value;
}
