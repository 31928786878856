import { Pipe, PipeTransform } from '@angular/core';
import { isMembershipEnabled } from '@shared/consts/common';

@Pipe({ name: 'isMemberhsipEnabled' })
export class IsMembershipEnabledPipe implements PipeTransform {

  transform(isIgnoreSettings = false) {
    return isIgnoreSettings || isMembershipEnabled;
  }
}
