import { Component } from '@angular/core';
import { BookingService } from '@booking/services/booking.service';
import { CancellationPolicy, PaymentPolicy, PaymentPolicyResponse, ScheduleItem } from '@shared/api/be-api.generated';
import { ReservationVerificationSectionBase } from '@shared/base/reservation-verification-section.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { PricingService } from '@shared/services/pricing.service';

@Component({
  selector: 'app-payment-plan',
  templateUrl: './payment-plan.component.html',
  styleUrls: ['./payment-plan.component.scss']
})
export class PaymentPlanComponent extends ReservationVerificationSectionBase<'paymentPlan'> {
  paymentPolicies?: PaymentPolicyResponse[];
  cancellationPolicy?: CancellationPolicy;
  activePolicy?: PaymentPolicy;
  scheduleItems: ScheduleItem[] = [];
  isTouched = false;

  anchorId = this.getAnchorId('paymentPlanError');

  get isValid() {
    return !!this.activePolicy;
  }

  constructor(
    private readonly _pricing: PricingService,
    booking: BookingService,
    services: ComponentBaseService
  ) {
    super('paymentPlan', booking, services);
    this.pushSub(
      booking.setWhenInitialized(() => {
        this.cancellationPolicy = booking.cancellationPolicy;
        if (booking.paymentPolicies?.length) {
          this.paymentPolicies = [
            ...booking.paymentPolicies.filter(item => item.isDefault),
            ...booking.paymentPolicies.filter(item => !item.isDefault)
          ];

          const cartPolicyId = this._booking.cartRequest?.paymentPolicyId;
          this.select(
            this.paymentPolicies.find(({ paymentPolicyId }) => cartPolicyId && paymentPolicyId === cartPolicyId)
            || this.paymentPolicies.find(({ isDefault }) => isDefault)
            || this.paymentPolicies.find(() => true));
        }
      }),
      booking.setWhenDataChanged(({ reservationInput }) =>
        this.scheduleItems = reservationInput.scheduleItems),
      this._pricing.setWhenDataChanged(() => this.select(this.activePolicy))
    );
  }

  select(policy?: PaymentPolicy) {
    this.activePolicy = policy;
    this.isTouched = true;
    const isPolicyChanged = this._booking.reservationInput.activePolicy?.paymentPolicyId !== policy?.paymentPolicyId;
    this._booking.reservationInput.setPaymentPolicy(policy);
    this._booking.loadPriceBreakdown(); // price can be changed load anyway
    if (isPolicyChanged) {
      this._booking.saveCartRequest();
    }
  }

  protected override verificationStarted() {
    this.isTouched = true;
  }
}
