import { Component } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { PackageService } from '@shared/services/package.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { pages } from '@shared/models/common';
import { jamaicaAirportsCodes } from '@shared/consts/common';

@Component({
  selector: 'app-notification-passenger',
  templateUrl: './notification-passenger.component.html',
  styleUrls: ['./notification-passenger.component.scss']
})
export class NotificationPassengerComponent extends ComponentBase {
  resortId?: string;
  isVisible = this._package.isVisibleJamaicaPassengerNotification;
  isVisibleCloseButton = this.page !== pages.confirmation;

  constructor(
    private _package: PackageService,
    services: ComponentBaseService
  ) {
    super(services);

    this.pushSub(
      this._package.setWhenDataChanged(({ resort: { resortId, airports } = {}, isVisibleJamaicaPassengerNotification }) => {
        if (!resortId || !airports?.length) {
          this.isVisible = false;
        }
        else if (isVisibleJamaicaPassengerNotification && this.resortId !== resortId) {
          this.resortId = resortId;
          this.isVisible = jamaicaAirportsCodes.some(code => airports.some(item => item.code == code));
        }
      })
    );
  }

  toggleVisiblity() {
    this._package.isVisibleJamaicaPassengerNotification = !this._package.isVisibleJamaicaPassengerNotification;
    this.isVisible = this._package.isVisibleJamaicaPassengerNotification;
  }
}
