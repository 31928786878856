import { Pipe, PipeTransform } from '@angular/core';
import { SpecialOffer } from '@shared/api/be-api.generated';
import { PackageDiscountPipe } from './package-discount.pipe';

@Pipe({ name: 'specialOfferDiscount' })
export class SpecialOfferDiscountPipe implements PipeTransform {
  constructor(
    private readonly _packageDiscountPipe: PackageDiscountPipe
  ) { }

  transform({ packages }: SpecialOffer) {
    if (packages && packages.length) {
      return this._packageDiscountPipe.transform(packages[0]);
    }

    return '';
  }
}
