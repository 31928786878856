import { BookingService } from '@booking/services/booking.service';
import { ReservationVerificationSection } from '@shared/models/reservation';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { AnimationComponentBase } from './animation-component.base';
import { AfterViewInit, Directive } from '@angular/core';
import { FormComponent } from '@shared/components/common/form/form.component';
import { debounceTime } from 'rxjs';


@Directive()
export abstract class ReservationVerificationSectionBase<TField extends string> extends AnimationComponentBase implements AfterViewInit {
  abstract isValid: boolean;
  abstract anchorId?: string;
  protected watchForms?: FormComponent<TField>[];
  protected abstract verificationStarted(): void;

  get cartRequest() {
    return this._booking.cartRequest;
  }

  constructor(
    section: ReservationVerificationSection,
    protected _booking: BookingService,
    services: ComponentBaseService
  ) {
    super(services);

    super.pushSub(
      _booking.startedVerification
        .subscribe(() => {
          this.verificationStarted();
          _booking.updateVerificationStatus(section, this.isValid, this.anchorId);
        })
    );
  }

  ngAfterViewInit() {
    if (this.services.ssrHelper.isBrowser) {
      this.afterViewInit();

      if (this.getFormsComponents) {
        this.watchForms = this.getFormsComponents().filter(item => item);
        if (this.watchForms) {
          this.watchFormsChanges(this.watchForms);
        }
      }
    }
  }

  private watchFormsChanges(formComponents: FormComponent<TField>[]) {
    formComponents.forEach(({ form }, index) =>
      this.pushSub(form.valueChanges
        .pipe(debounceTime(2000))
        .subscribe(_ => {
          if (form.valid) {
            if (this.afterFormValueChanged) {
              this.afterFormValueChanged(index);
            }
            this._booking.saveCartRequest();
          }
        })));
  }

  protected afterViewInit() {
    // nothing here, can be overrided
  }
  protected afterFormValueChanged(_formIndex: number) {
    // nothing here, can be overrided
  }

  protected getFormsComponents(): FormComponent<TField>[] {
    // nothing here, can be overrided
    return [];
  }
}
