import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { dateFormat, DateFormatOption, formatDateTime } from '../formats/date-time';

@Pipe({ name: 'appDate' })
export class AppDatePipe implements PipeTransform {
  transform(value: DateTime | undefined, format: DateFormatOption) {
    return formatDateTime(value, dateFormat[format]);
  }
}
