<ng-container *transloco="let t">
  <div class="search-loader">
    <div class="search-loader-icon">
      <app-loading-icon *ngIf="!resortLogo" size="window"></app-loading-icon>
      <img loading="lazy" [src]="resortLogo" *ngIf="resortLogo" />
    </div>
    <div class="search-loader-header">
      {{t(['loader', section, 'header'] | join)}}
    </div>
    <div class="search-loader-subheader"
      [innerHTML]="t(['loader', section, 'subheader'] | join) | safe: 'html'">
    </div>
    <app-loading-icon *ngIf="resortLogo" size="window"></app-loading-icon>
  </div>
</ng-container>