import { Component, AfterViewInit } from '@angular/core';
import { BookingService } from '@booking/services/booking.service';
import { DataService } from '@shared/services/data.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements AfterViewInit {
  tradingName = this._data.values.settings.tradingName;
  items = [
    'statement', 'policies', 'payment', 'cancellation', 'insurance', 'tickets',
    'codeShare', 'reconfirmation', 'hazardous', 'insecticide', 'privacy',
  ];

  paymentPolicy = this._booking.reservationInput.activePolicy;
  cancellationPolicy = this._booking.cancellationPolicy;

  custom: Record<typeof this.items[number], { header?: string, content?: string }> = {
    'payment': {
      'header': this.paymentPolicy?.name,
      'content': this.paymentPolicy?.description
    },
    'cancellation': {
      'header': this.cancellationPolicy?.name,
      'content': this.cancellationPolicy?.description
    }
  };

  sections = ['policies', 'tickets'];
  contentLess = ['policies'];

  isVisible = false; // this is need for bug with scrolling

  constructor(
    private readonly _booking: BookingService,
    private readonly _data: DataService
  ) { }

  ngAfterViewInit(): void {
    setTimeout(() => this.isVisible = true, 700);
  }
}
