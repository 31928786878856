import { Airport, AirportTransfer, AirportTransfer2, ExistingFlight, ExistingFlights2 as ExistingFlights, IInitializationData, Journey } from '@shared/api/be-api.generated';
import { DateTime } from 'luxon';

export class FlightSummary {
  departureAirportName?: string;
  arrivalAirportName?: string;

  departureDate?: DateTime;
  arrivalDate?: DateTime;

  flightNumber?: string;
  airportTransfer?: string;

  constructor(flight: ExistingFlight | undefined, airports: Airport[]) {
    if (flight) {
      this.departureAirportName = airports.find(airport =>
        airport.code === flight.departureAirportCode)?.displayText;

      this.arrivalAirportName = airports.find(airport =>
        airport.code === flight.arrivalAirportCode)?.displayText;

      this.departureDate = flight.departureDate;
      this.arrivalDate = flight.arrivalDate;

      this.flightNumber = flight.flightNumber;
      this.airportTransfer = flight.airportTransfer?.description;
    }
  }
}

export class JourneySummary {
  airlineCodes: string[] = [];

  constructor(public journey: Journey | undefined, public transfer: AirportTransfer2 | undefined) {
    if (journey && journey.flights) {
      this.airlineCodes = journey.flights
        .map(flight => flight.marketingAirline || '');
    }
  }
}

// TODO: keep for now
export const getFakeExistingFlights = ({ airports }: Required<IInitializationData>, airportTransfers: AirportTransfer[]) => new ExistingFlights({
  inboundFlight: new ExistingFlight({
    airportTransfer: airportTransfers?.find(() => true),
    arrivalAirportCode: airports?.find(() => true)?.code,
    arrivalDate: DateTime.now(),
    departureAirportCode: airports?.find(() => true)?.code,
    departureDate: DateTime.now(),
    flightNumber: 'Inbound flight number'
  }),
  outboundFlight: new ExistingFlight({
    airportTransfer: airportTransfers?.find(() => true),
    arrivalAirportCode: airports?.find(() => true)?.code,
    arrivalDate: DateTime.now(),
    departureAirportCode: airports?.find(() => true)?.code,
    departureDate: DateTime.now(),
    flightNumber: 'Outbound flight number'
  })
});
