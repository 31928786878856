<ng-container *transloco="let t">
  <div class="promo-code">
    <div class="promo-code-header">
      {{t('promoCode.caption')}}
    </div>
    <div class="promo-code-content">
      <app-form
        #form
        (nextChanged)="apply()"
        [form]="promoCodeForm"
        scope="promoCode">
        <ng-template #content let-text="text">
          <div class="promo-code-value">
            <ng-container *ngTemplateOutlet="text; context: { field: 'value'}"></ng-container>
          </div>
        </ng-template>
      </app-form>
    </div>
    <div class="promo-code-footer">
      <button mat-raised-button
        (click)="cancel()">
        {{t('cancel')}}
      </button>
      <button mat-raised-button
        color="primary"
        (click)="apply()">
        {{t('apply')}}
      </button>
    </div>
  </div>
</ng-container>
