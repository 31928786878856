import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { DefaultHeadersInterceptor } from './interceptors/default-headers.interceptor';
import { BookingEngineClient } from './api/be-api.generated';

import { TranslocoRootModule } from '../transloco-root.module';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTimepickerModule } from 'mat-timepicker';

import { TranslateFormComponentTextPipe } from '../pipes/translate-form-component-text.pipe';
import { IsFormControlRequiredPipe } from '../pipes/is-form-control-required.pipe';
import { SpecialOfferDiscountPipe } from '../pipes/special-offer-discount.pipe';
import { IsMembershipEnabledPipe } from '../pipes/is-membership-enabled.pipe';
import { PackageDiscountPipe } from '../pipes/package-discount.pipe';
import { FlightDurationPipe } from '../pipes/flight-duration.pipe';
import { CreateTrackByPipe } from '../pipes/create-track-by.pipe';
import { FormatPricePipe } from '../pipes/format-price.pipe';
import { AppCurrencyPipe } from '../pipes/app-currency.pipe';
import { FlightTimePipe } from '../pipes/flight-time.pipe';
import { CabinTypePipe } from '../pipes/cabin-type.pipe';
import { AddOnTypePipe } from '../pipes/addon-type.pipe';
import { IconPathPipe } from '../pipes/icon-path.pipe';
import { PosistionPipe } from '../pipes/position.pipe';
import { AppDatePipe } from '../pipes/app-date.pipe';
import { AirportPipe } from '../pipes/airport.pipe';
import { JoinPipe } from '../pipes/join.pipe';
import { SafePipe } from '../pipes/safe.pipe';

import { InitDirective } from '../directives/init.directive';
import { TargetBlankDirective } from '../directives/target-blank.directive';

import { DataService } from './services/data.service';
import { AddOnService } from './services/add-on.service';
import { TenantService } from './services/tenant.service';
import { AddressService } from './services/address.service';
import { PackageService } from './services/package.service';
import { PricingService } from './services/pricing.service';
import { TrackingService } from './services/tracking.service';
import { NavigationService } from './services/navigation.service';
import { TravelAgentService } from './services/travel-agent.service';
import { QueryParamsService } from './services/query-params.service';
import { ComponentBaseService } from './services/component-base.service';
import { ComponentsCommunicationService } from './services/components-communication.service';

import { IconButtonComponent } from './components/button/icon-button/icon-button.component';
import { SelectButtonComponent } from './components/button/select-button/select-button.component';
import { BookingButtonComponent } from './components/button/booking-button/booking-button.component';

import { CartTotalComponent } from './components/cart/cart-total/cart-total.component';
import { CartViewerComponent } from './components/cart/cart-viewer/cart-viewer.component';
import { CartSummaryComponent } from './components/cart/cart-summary/cart-summary.component';

import { FormComponent } from './components/common/form/form.component';
import { IconComponent } from './components/common/icon/icon.component';
import { AnchorComponent } from './components/common/anchor/anchor.component';
import { InfoLabelComponent } from './components/common/info-label/info-label.component';
import { PaymentCardsComponent } from './components/common/payment-cards/payment-cards.component';
import { SectionTogglerComponent } from './components/common/section-toggler/section-toggler.component';
import { SpecialOfferCaptionComponent } from './components/special-offers/special-offer-caption/special-offer-caption.component';

import { FlightJourneyComponent } from './components/flight/flight-journey/flight-journey.component';

import { ErrorComponent } from './components/layout/error/error.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { NotFoundComponent } from './components/layout/not-found/not-found.component';
import { TwoColumnsComponent } from './components/layout/two-columns/two-columns.component';
import { LoadingIconComponent } from './components/layout/loading-icon/loading-icon.component';
import { HotelFilterComponent } from './components/layout/hotel-filter/hotel-filter.component';
import { LoadingScreenComponent } from './components/layout/loading-screen/loading-screen.component';
import { SidebarLayoutComponent } from './components/layout/sidebar-layout/sidebar-layout.component';

import { NotificationComponent } from './components/notification/notification/notification.component';
import { NotificationErrorComponent } from './components/notification/notification-error/notification-error.component';
import { NotificationSignupComponent } from './components/notification/notification-signup/notification-signup.component';
import { NotificationPricesComponent } from './components/notification/notification-prices/notification-prices.component';
import { NotificationBookingComponent } from './components/notification/notification-booking/notification-booking.component';
import { NotificationTravelComponent } from './components/notification/notification-travel/notification-travel.component';
import { NotificationWarningComponent } from './components/notification/notification-warning/notification-warning.component';
import { NotificationWarningsComponent } from './components/notification/notification-warnings/notification-warnings.component';
import { NotificationPassengerComponent } from './components/notification/notification-passenger/notification-passenger.component';

import { SpecialOfferCaptionPrimaryComponent } from './components/special-offers/special-offer-caption-primary/special-offer-caption-primary.component';
import { SpecialOfferCaptionSecondaryComponent } from './components/special-offers/special-offer-caption-secondary/special-offer-caption-secondary.component';
import { NotificationAmenitiesComponent } from './components/notification/notification-amenities/notification-amenities.component';
import { ModalPanelV2Component } from './components/common/modal-panel/modal-panel.component';

@NgModule({
  declarations: [
    IconButtonComponent,
    BookingButtonComponent,
    SelectButtonComponent,

    CartViewerComponent,
    CartSummaryComponent,
    CartTotalComponent,

    SpecialOfferCaptionComponent,
    SectionTogglerComponent,
    PaymentCardsComponent,
    InfoLabelComponent,
    AnchorComponent,
    IconComponent,
    FormComponent,

    FlightJourneyComponent,

    SidebarLayoutComponent,
    LoadingScreenComponent,
    ModalPanelV2Component,
    HotelFilterComponent,
    LoadingIconComponent,
    TwoColumnsComponent,
    NotFoundComponent,
    HeaderComponent,
    FooterComponent,
    ErrorComponent,

    NotificationComponent,
    NotificationErrorComponent,
    NotificationSignupComponent,
    NotificationPricesComponent,
    NotificationTravelComponent,
    NotificationBookingComponent,
    NotificationWarningComponent,
    NotificationWarningsComponent,
    NotificationPassengerComponent,

    SpecialOfferCaptionPrimaryComponent,
    SpecialOfferCaptionSecondaryComponent,

    JoinPipe,
    SafePipe,
    AirportPipe,
    AppDatePipe,
    IconPathPipe,
    PosistionPipe,
    CabinTypePipe,
    AddOnTypePipe,
    FlightTimePipe,
    AppCurrencyPipe,
    FormatPricePipe,
    CreateTrackByPipe,
    FlightDurationPipe,
    PackageDiscountPipe,
    IsMembershipEnabledPipe,
    SpecialOfferDiscountPipe,
    IsFormControlRequiredPipe,
    TranslateFormComponentTextPipe,

    InitDirective,
    TargetBlankDirective,
    NotificationAmenitiesComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslocoRootModule,

    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTimepickerModule
  ],
  providers: [
    JoinPipe,
    SafePipe,
    AirportPipe,
    AppDatePipe,
    IconPathPipe,
    PosistionPipe,
    CabinTypePipe,
    AddOnTypePipe,
    FlightTimePipe,
    AppCurrencyPipe,
    FormatPricePipe,
    CreateTrackByPipe,
    FlightDurationPipe,
    PackageDiscountPipe,
    SpecialOfferDiscountPipe,
    IsFormControlRequiredPipe,
    TranslateFormComponentTextPipe,

    BookingEngineClient,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultHeadersInterceptor,
      multi: true
    },

    DataService,
    AddOnService,
    TenantService,
    AddressService,
    PackageService,
    PricingService,
    TrackingService,
    NavigationService,
    TravelAgentService,
    QueryParamsService,
    ComponentBaseService,
    ComponentsCommunicationService
  ],
  exports: [
    IconButtonComponent,
    BookingButtonComponent,
    SelectButtonComponent,

    CartViewerComponent,
    CartSummaryComponent,
    CartTotalComponent,

    SpecialOfferCaptionComponent,
    SectionTogglerComponent,
    PaymentCardsComponent,
    InfoLabelComponent,
    AnchorComponent,
    IconComponent,
    FormComponent,

    FlightJourneyComponent,

    SidebarLayoutComponent,
    LoadingScreenComponent,
    ModalPanelV2Component,
    HotelFilterComponent,
    LoadingIconComponent,
    TwoColumnsComponent,
    NotFoundComponent,
    HeaderComponent,
    FooterComponent,
    ErrorComponent,

    NotificationComponent,
    NotificationErrorComponent,
    NotificationSignupComponent,
    NotificationPricesComponent,
    NotificationTravelComponent,
    NotificationBookingComponent,
    NotificationWarningComponent,
    NotificationWarningsComponent,
    NotificationPassengerComponent,

    SpecialOfferCaptionPrimaryComponent,
    SpecialOfferCaptionSecondaryComponent,

    JoinPipe,
    SafePipe,
    AirportPipe,
    AppDatePipe,
    IconPathPipe,
    PosistionPipe,
    CabinTypePipe,
    AddOnTypePipe,
    FlightTimePipe,
    AppCurrencyPipe,
    FormatPricePipe,
    CreateTrackByPipe,
    FlightDurationPipe,
    PackageDiscountPipe,
    IsMembershipEnabledPipe,
    SpecialOfferDiscountPipe,
    IsFormControlRequiredPipe,
    TranslateFormComponentTextPipe,

    InitDirective,
    TargetBlankDirective,
    NotificationAmenitiesComponent
  ]
})
export class SharedModule { }
