import Logger from 'js-logger';
import { AppEnvironment } from './environment-type';

export const environment: AppEnvironment = {
  baseUrl: 'https://booking.uat.c-breez.com',
  baseApiUrl: 'https://booking-api.uat.c-breez.com',
  production: true,
  minLogLevel: Logger.INFO,
  smartyApiKey: '153588772584256836',
  isTestAllowed: true,
  productUrl: 'https://c-breez.com',
  tenants: ['test', 'sunset', 'coconutbay', 'thecrane'],
  tenantsWithLoyalty: ['test', 'sunset'],
  trackingEnabled: true,
  tenantsLogos: {
    'test': 'https://2486634c787a971a3554-d983ce57e4c84901daded0f67d5a004f.ssl.cf1.rackcdn.com/couples-resorts/media/couples-horiz-resorts_gray_80k-6410835b58b2f.png',
    'sunset': 'https://thepalmsjamaica.com/images/Sunset-at-the-plams_negril_dec_2019_black-v2.png',
    'thecrane': 'http://res.cloudinary.com/traveltripperweb/image/upload/c_limit,h_2500,w_2500/v1578477927/lh9ekave7kcucn2nzack.png'
  },
  secondarySpecialOfferView: ['sunset'],
  abandonedCartEnabled: true,
  tenantsPriceFormat: {
    'test': {
      'main': 'prpn',
      'button': 'totalRounded',
      'mainFlights': 'totalRounded'
    }
  }
};
