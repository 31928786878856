import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { newGuid } from '@utils/guid';
import { getKeys } from '@utils/object';
import { defaultTags, HtmlAppendOptions } from '@utils/state-transfer';
import { SsrHelperService } from './ssr-helper.service';

const { head } = defaultTags;

@Injectable()
export class HTMLTagService {
  private readonly _parents: Record<string, HTMLElement> = {};
  private readonly _appended: Record<string, HTMLElement> = {};

  constructor(
    private readonly _ssrHelper: SsrHelperService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  append({
    attributes = {},
    parentQuery = head,
    element,
    allowInSSR,
    style,
    cssContent
  }: HtmlAppendOptions): string | undefined {
    if (this._ssrHelper.isBrowser || allowInSSR) {
      const id = newGuid();
      this._appended[id] = this.document.createElement(element);

      if (!this._appended[id].id) {
        this._appended[id].id = id.toString();
      }

      getKeys(attributes)
        .forEach(name =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this._appended[id] as any)[name] = attributes[name]);

      if (style) {
        this._appended[id]
          .setAttribute('style',
            getKeys(style)
              .map(key => `${key}: ${style[key]}`)
              .join(';'));
      }

      const parent = this.document.querySelector(parentQuery) as HTMLElement;

      if (parent) {
        this._parents[id] = parent;
        this._parents[id].appendChild(this._appended[id]);

        if (cssContent) {
          const styleElement = this._appended[id] as HTMLStyleElement;
          styleElement.appendChild(this.document.createTextNode(cssContent));
        }

        return id;
      } else {
        this._appended[id].remove();
        delete this._appended[id];
      }
    }

    return undefined;
  }

  /**
   * Create html tag with attribute and append it to parent with params
   * @param ${HtmlAppendOptions} param0 - parentQuery default value is head
   * parentQuery - default value is head
   */
  appendAndLoad(options: HtmlAppendOptions): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      const id = this.append(options);

      if (id) {
        this._appended[id].onload = () => {
          resolve(id);
        };

        this._appended[id].onerror = () => {
          reject(undefined);
        };
      } else {
        reject(undefined);
      }
    });
  }

  remove(id?: string): void {
    if (this._ssrHelper.isBrowser && id) {
      this._parents[id]?.removeChild(this._appended[id]);
      delete this._parents[id];
      delete this._appended[id];
    }
  }
}
