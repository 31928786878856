import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TransferStateService } from '../services/transfer-state.service';
import { HttpRequestTransferState } from './transfer-state';

@Injectable({
  providedIn: 'root'
})
export class BrowserStateInterceptor implements HttpInterceptor {

  constructor(
    private transferState: TransferStateService,
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = new HttpRequestTransferState(req);

    if (request.isInterceptable || request.isSvg) {
      const storedResponse = this.transferState.get(request.transferStateKey);
      if (storedResponse) {
        return of(new HttpResponse({ body: storedResponse, status: 200 }));
      }
    }

    return next.handle(req);
  }
}
