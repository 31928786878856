import { Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AddOnResponse, AirportTransfer, Package, RoomType } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { createCarouselImages } from '@shared/common';
import { CarouselImage, WithId } from '@shared/models/common';
import { AddOnService } from '@shared/services/add-on.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { PackageService } from '@shared/services/package.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { PricingDetailsComponent } from '../pricing-details/pricing-details.component';
import { TenantService } from '@shared/services/tenant.service';

const room = 'room' as const;
const addOn = 'addOn' as const;
const airportTransfer = 'airportTransfer' as const;
const unset = 'unset' as const;
type OptionType = typeof room | typeof addOn | typeof airportTransfer | typeof unset;

@Component({
  selector: 'app-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss'],
})
export class SelectOptionComponent extends ComponentBase implements OnChanges {
  @ViewChild('addOnDetails') addOnDetails!: TemplateRef<unknown>;
  @ViewChild('packageDetails') packageDetails!: TemplateRef<unknown>;
  @ViewChild('airportTransferDetails') airportTransferDetails!: TemplateRef<unknown>;
  @ViewChild('pricingDetails') pricingDetails?: PricingDetailsComponent;

  @Input() currentPackage?: WithId<Package>;
  @Input() currentRoomType!: RoomType;
  @Input() currentAddOn!: AddOnResponse;
  @Input() currentAirportTransfer!: AirportTransfer;

  caption?: string;
  description?: string;
  images: WithId<CarouselImage>[] = [];
  isActive = false;

  optionType!: OptionType;
  isRoomOption!: boolean;
  isAddOnOption!: boolean;
  isAirportTransferOption!: boolean;
  isDisabled!: boolean;

  priceFormat = this._tenant.mainPriceFormat;

  constructor(
    private readonly _package: PackageService,
    private readonly _addon: AddOnService,
    private readonly _sidebar: SidebarService,
    private readonly _tenant: TenantService,
    services: ComponentBaseService
  ) {
    super(services);
    super.pushSub(
      this._package.setWhenDataChanged(() => this._initData()),
      this._addon.setWhenDataChanged(() => this._initData())
    );
  }

  private _initData() {
    switch (this.optionType) {
      case room: this.isActive = this._package.isActiveRoomType(this.currentRoomType); break;
      case addOn: this.isActive = !!this._addon.getToPurchase(this.currentAddOn); break;
      case airportTransfer: this.isActive = this.currentAirportTransfer?.id === this._package.inboundAirportTransfer?.id
        || this.currentAirportTransfer.id === this._package.outboundAirportTransfer;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.optionType = !!this.currentRoomType
      ? room
      : !!this.currentAddOn
        ? addOn
        : !!this.currentAirportTransfer ?
          airportTransfer
          : unset;

    this.isRoomOption = this.optionType === room;
    this.isAddOnOption = this.optionType === addOn;
    this.isAirportTransferOption = this.optionType === airportTransfer;

    this.isDisabled = false;

    switch (this.optionType) {
      case room:
        this.isDisabled = !this.currentPackage;

        // no need to update the view, especially images if package is changed
        if (changes['currentRoomType']?.currentValue !== changes['currentRoomType']?.previousValue) {
          this.images = createCarouselImages(this.currentRoomType);
          this.caption = this.currentRoomType.caption;
          this.description = this.currentRoomType.description;
        }

        break;
      case addOn:
        if (changes['currentAddOn']?.currentValue !== changes['currentAddOn']?.previousValue) {
          this.images = createCarouselImages(this.currentAddOn);
          this.caption = this.currentAddOn.name;
          this.description = this.currentAddOn.description;
        }
        break;
      case airportTransfer:
        if (changes['currentAirportTransfer']?.currentValue !== changes['currentAirportTransfer']?.previousValue) {
          this.images = createCarouselImages(this.currentAirportTransfer);
          this.caption = this.currentAirportTransfer.name;
          this.description = this.currentAirportTransfer.description;
        }
        break;
      default:
        this.isDisabled = true;
        break;
    }

    this._initData();
  }

  openDetails() {
    switch (this.optionType) {
      case room:
        this._sidebar.open({ template: this.packageDetails, position: 'end', width: 'big' });
        break;
      case addOn:
        this._sidebar.open({ template: this.addOnDetails, width: 'default', position: 'end', });
        break;
      case airportTransfer:
        this._sidebar.open({ template: this.airportTransferDetails, position: 'end', width: 'default' });
        break;
    }
  }
}
