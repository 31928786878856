<ng-container *transloco="let t">
  <div class="agreement-conditions-summary flex">
    <div class="content-container">
      <app-confirmation-section
        #section
        [hasHeaderMargin]="false"
        scope="agreementConditionsSummary">
        <app-confirmation-section-record #record
          [position]="record.positions.full"
          [isDivider]="{ top: true, bottom: true }">
          <div [ngClass]="[record.htmlClasses.row, record.positions.end]">
            <div [ngClass]="record.htmlClasses.label"
              [style.min-width]="record.labelWidth">
              {{t([section.scope, 'policy', 'payment'] | join)}}
            </div>
            <div [ngClass]="record.htmlClasses.value">
              {{paymentPolicy?.name}}
            </div>
          </div>
          <div [ngClass]="[record.htmlClasses.row, record.positions.full]"
            *ngIf="paymentPolicy?.description">
            <div [ngClass]="record.htmlClasses.value">
              {{paymentPolicy?.description}}
            </div>
          </div>
        </app-confirmation-section-record>
        <app-confirmation-section-record #record
          labelWidth="50%"
          [position]="record.positions.full">
          <div [ngClass]="[record.htmlClasses.row, record.positions.end]">
            <div [ngClass]="record.htmlClasses.label"
              [style.min-width]="record.labelWidth">
              {{t([section.scope, 'policy', 'cancellation'] | join)}}
            </div>
            <div [ngClass]="record.htmlClasses.value">
              {{cancellationPolicy?.name}}
            </div>
          </div>
          <div [ngClass]="[record.htmlClasses.row, record.positions.full]"
            *ngIf="cancellationPolicy?.description">
            <div [ngClass]="record.htmlClasses.value">
              {{cancellationPolicy?.description}}
            </div>
          </div>
        </app-confirmation-section-record>
        <app-confirmation-section-record
          [scope]="section.scope"
          field="paymentCards"
          [isGap]="false"
          position="full"
          [isDivider]="{}">
          <app-payment-cards justifyContent="flex-start"></app-payment-cards>
        </app-confirmation-section-record>
      </app-confirmation-section>
    </div>
  </div>
</ng-container>