import { EventEmitter, Injectable } from '@angular/core';
import { BookingEngineClient, ConfirmationData, IConfirmationData } from '@shared/api/be-api.generated';
import { InitializationServiceBase } from '@shared/base/initialization-service.base';
import { SsrHelperService } from '@shared/services/ssr-helper.service';
import { TenantService } from '@shared/services/tenant.service';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService extends InitializationServiceBase<IConfirmationData> {
  bookingId?: string;
  dataChanged = new EventEmitter();

  constructor(
    private readonly _apiClient: BookingEngineClient,
    ssrHelper: SsrHelperService,
    tenant: TenantService
  ) {
    super('confirmationData', ConfirmationData.fromJS, tenant, ssrHelper);
  }

  override initialize() {
    return super.initialize(() => ({
      bookingDetails: this._apiClient.getBooking(this.tenant.id, this.bookingId || '')
        .pipe(catchError(error => {
          this.initialazed.emit(true);
          return error;
        }))
    }));
  }

  get booking() {
    return this.values?.bookingDetails;
  }

  get payment() {
    return this.booking?.payment;
  }

  get firstPaymentScheduleItem() {
    return (this.payment?.paymentScheduleItems || []).find(() => true);
  }

  get paid() {
    return this.payment?.paymentsSum;
  }

  get unpaid() {
    return (this.firstPaymentScheduleItem?.amount || 0) - (this.paid || 0);
  }
}
