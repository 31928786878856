import { Component, Input } from '@angular/core';
import { Airport, AirportTransfer } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { CarouselImage, WithId } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { PackageService } from '@shared/services/package.service';
import { SearchService } from '@shared/services/search/search.service';

@Component({
  selector: 'app-airport-transfer-details',
  templateUrl: './airport-transfer-details.component.html',
  styleUrls: ['./airport-transfer-details.component.scss']
})
export class AirportTransferDetailsComponent extends ComponentBase {
  @Input() currentAirportTransfer!: AirportTransfer;
  @Input() images!: WithId<CarouselImage>[];

  arrivalAirport?: Airport;
  inboundAirportTransfer?: AirportTransfer;
  outboundAirportTransfer?: AirportTransfer;

  constructor(
    services: ComponentBaseService,
    private readonly _package: PackageService,
    private readonly _search: SearchService
  ) {
    super(services);
    this._setData;
    this.pushSub(
      _package.setWhenDataChanged(() => this._setData())
    );
  }

  private _setData() {
    this.inboundAirportTransfer = this._package.inboundAirportTransfer;
    this.outboundAirportTransfer = this._package.outboundAirportTransfer;
    this.arrivalAirport = this._search.state.context.arrivalAirport;
  }

  selectAsInbound() {
    this._package.selectInboundAirportTransfer(
      this.inboundAirportTransfer?.id !== this.currentAirportTransfer.id
        ? this.currentAirportTransfer
        : undefined
    );
  }

  selectAsOutbound() {
    this._package.selectOutboundAirportTransfer(
      this.outboundAirportTransfer?.id !== this.currentAirportTransfer.id
        ? this.currentAirportTransfer
        : undefined);
  }
}
