<ng-container *transloco="let t">
  <div class="account-layout">
    <app-sidebar-layout
      [scope]="currentScope">
      <ng-template #header
        *ngIf="isEditMode">
        <div class="account-layout-header">
          <div class="account-layout-header-row"
            (click)="closeEditMode()">
            <app-icon name="previous"
              size="small"
              stroked="selected">
            </app-icon>
            {{t('back')}}
          </div>
          <app-icon-button
            class="bs-right"
            stroked="iconInactive"
            buttonClass="close-button"
            (click)="closeSidebar()"
            name="close">
          </app-icon-button>
        </div>
      </ng-template>

      <ng-template #content>
        <div class="sidebar-scroll-container">
          <div class="account-layout-header without-space-left"
            *ngIf="(isOnBookingPage || isOnConfirmationPage) && !isEditMode">
            <div class="account-layout-header-row"
              (click)="backToSearch()">
              <app-icon
                name="previous"
                size="small"
                stroked="selected">
              </app-icon>
              {{t('backToSearch')}}
            </div>
          </div>
          <div class="account-layout-content">
            <app-account-layout-item
              *ngIf="isOnSearchPage || isOnBookingPage"
              [isEditMode]="isTravelAgentEditMode"
              [isHidden]="isEditMode && !isTravelAgentEditMode"
              (viewTextClick)="toggleEditTravelAgent()"
              [viewText]="travelAgent || t('account.caption.travelAgent')">
              <ng-template #editor>
                <app-travel-agent (cancelEdit)="toggleEditTravelAgent()"></app-travel-agent>
              </ng-template>
            </app-account-layout-item>
            <app-account-layout-item
              *ngIf="isOnSearchPage"
              [isEditMode]="isPromoCodeEditMode"
              [isHidden]="isEditMode && !isPromoCodeEditMode"
              (viewTextClick)="toggleEditPromoCode()"
              [viewText]="promoCode ? t('promoCode.applied', { value: promoCode }) : t('account.caption.promoCode')">
              <ng-template #editor>
                <app-promo-code (cancelEdit)="toggleEditPromoCode()"></app-promo-code>
              </ng-template>
            </app-account-layout-item>
          </div>
        </div>
      </ng-template>
    </app-sidebar-layout>
  </div>
</ng-container>
