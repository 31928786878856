import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Package, SpecialOffer } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { WithId } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { PackageService } from '@shared/services/package.service';
import { QueryParamsService } from '@shared/services/query-params.service';
import { FormatPricePipe } from 'src/app/pipes/format-price.pipe';

@Component({
  selector: 'app-special-offer-caption-secondary',
  templateUrl: './special-offer-caption-secondary.component.html',
  styleUrls: ['./special-offer-caption-secondary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpecialOfferCaptionSecondaryComponent extends ComponentBase implements OnChanges, AfterViewInit {
  @Input() currentSpecialOffer!: SpecialOffer;
  @Input() isVisibleNotes = true;
  @Input() isAlwaysActive = false;
  @Input() isVisiblePrice = false;

  @ViewChild('notesContainer') notesContainers?: ElementRef;

  selectedPackage?: WithId<Package>;
  specialOffer?: SpecialOffer;

  name?: string;
  notes?: string;
  discount!: string;
  isSelected!: boolean;
  isNonRefundable!: boolean;
  isNotesExpanded = false;

  finalPrice?: string;
  finalPriceFormat?: string;
  basePrice?: string;
  basePriceFormat?: string;

  constructor(
    private readonly _package: PackageService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _queryParams: QueryParamsService,
    private readonly _formatPrice: FormatPricePipe,
    services: ComponentBaseService
  ) {
    super(services);
    if (!this.isAlwaysActive) {
      this.pushSub(
        this._package.setWhenDataChanged(() => this._setData(), false)
      );
    } else {
      this._setData();
    }
  }

  ngAfterViewInit(): void {
    if (this.services.ssrHelper.isBrowser) {
      this._trackReadMoreVisibility();
    }
  }

  private _trackReadMoreVisibility() {
    if (this.notesContainers) {
      const hiddenMoreClass = 'hidden-more';
      const observer = new ResizeObserver(items => {
        items.forEach(({ target }) => {
          if (target.clientHeight === target.scrollHeight) {
            if (!target.classList.contains(hiddenMoreClass)) {
              target.classList.add(hiddenMoreClass);
            }
          } else if (target.classList.contains(hiddenMoreClass)) {
            target.classList.remove(hiddenMoreClass);
          }
        });
      });

      observer.observe(this.notesContainers.nativeElement);
      this.onDestroyHandler = () => this.notesContainers && observer.unobserve(this.notesContainers.nativeElement);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { previousValue, currentValue } = changes['currentSpecialOffer'] || {};
    if (previousValue !== currentValue) {
      this._setSpecialOfferData(this.currentSpecialOffer);
    }
  }

  toggleIsExpandedNotes(event: Event) {
    event?.preventDefault();
    event?.stopPropagation();
    this.isNotesExpanded = !this.isNotesExpanded;
  }

  private _setData() {
    this.specialOffer = this._package.specialOffer;
    this.selectedPackage = this._package.selectedPackage;

    this._setSpecialOfferData(this.currentSpecialOffer);
    this._cdr.detectChanges();
  }

  private _setSpecialOfferData(value: SpecialOffer) {
    this.notes = value.notes;

    const isFlightSelected = this._package.isFlightSelected;

    const specialOfferPackage = isFlightSelected
      ? this.currentSpecialOffer?.packages?.find(item =>
        item.flightId === (this._queryParams.value.flightId || '0'))
      : this.currentSpecialOffer.packages?.find(() => true);

    const { periodNights, guestsCount, roomsCount } = this._package;

    const setFormattedPrice = (total: number | undefined) => this._formatPrice
      .transform(total, periodNights, guestsCount, roomsCount, isFlightSelected ? 'total' : 'prpn');

    const finalResult = setFormattedPrice(specialOfferPackage?.finalPrice)?.split(' ');
    if (finalResult) {
      this.finalPriceFormat = finalResult.length > 1 ? finalResult.pop() : undefined
      this.finalPrice = finalResult.join(' ');
    }

    const baseResult = setFormattedPrice(specialOfferPackage?.basePrice)?.split(' ');
    if (baseResult) {
      this.basePriceFormat = baseResult.length > 1 ? baseResult.pop() : undefined
      this.basePrice = baseResult.join(' ');
    }

    this.name = value?.name?.toLowerCase();
    this.isSelected = value === this._package.specialOffer || this.isAlwaysActive;

    this.isNonRefundable = !!this.currentSpecialOffer.cancellationPolicy?.isNonRefundable;
  }
}
