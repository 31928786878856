<ng-container *transloco="let t">
  <div class="addon-summary flex"
    *ngIf="addOns.length">
    <div class="content-container">
      <app-confirmation-section
        #section
        [hasHeaderMargin]="false"
        scope="addOnsSummary">
        <app-confirmation-section-record #record
          [isDivider]="{ top: true }"
          *ngFor="let addOn of addOns">
          <div [ngClass]="record.htmlClasses.label"
            [style.min-width]="record.labelWidth">
            <ng-container *ngIf="(addOn.quantity || 0) > 1">
              {{addOn.quantity}}
              x
            </ng-container>
            {{addOn.name}}
            <ng-container *ngIf="addOn.ageGroup">
              ({{addOn.ageGroup}} {{t('yearOrYears')}})
            </ng-container>
          </div>
          <div [ngClass]="[record.htmlClasses.value, 'custom-html-content']"
            [innerHtml]="addOn.description | safe: 'html'">
          </div>
        </app-confirmation-section-record>
      </app-confirmation-section>
    </div>
  </div>
</ng-container>