import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TenantService {
  constructor(
    private readonly _route: ActivatedRoute
  ) {
  }

  get id() {
    return this._route.snapshot.firstChild?.params['tenantId'];
  }

  get isCouplesTenant() {
    return ['couples', 'test'].indexOf(this.id) > -1;
  }

  get tenantPriceFormats() {
    return (environment.tenantsPriceFormat && environment.tenantsPriceFormat[this.id]) || {
      'main': 'total',
      'button': 'prpn',
      'mainFlights': 'total'
    };
  }

  get mainPriceFormat() {
    return this.tenantPriceFormats['main'];
  }

  get mainFlightsPriceFormat() {
    return this.tenantPriceFormats['mainFlights'];
  }

  get buttonPriceFormat() {
    return this.tenantPriceFormats['button'];
  }
}
