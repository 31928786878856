<ng-container *transloco="let t">
  <div class="room-select">
    <div class="block-header">
      <div class="block-header-text">
        {{t('moreOptions')}}
      </div>
      <div class="block-header-navigation">
        <app-icon-button
          (click)="scroll(true)"
          iconSize="big"
          buttonSize="big"
          name="left"
          stroked="iconStroke"
          filled="iconFill">
        </app-icon-button>
        <app-icon-button
          (click)="scroll(false)"
          iconSize="big"
          buttonSize="big"
          name="right"
          stroked="iconStroke"
          filled="iconFill">
        </app-icon-button>
      </div>
    </div>
    <div class="room-select-options"
      #options>
      <app-select-option
        *ngFor="let room of roomTypesPackagesOptions; trackBy: 'id' | createTrackBy"
        [currentRoomType]="room.roomType"
        [currentPackage]="room.package">
      </app-select-option>
    </div>
  </div>
</ng-container>
