<div class="under-search-bar" *ngIf="isVisibleAirports"></div>
<ng-container *transloco="let t">
  <div class="search-bar"
    [style.background-color]="resortId === 'acde1ce6-cf48-4569-af00-64848cd58d78' ? '#000000': ''"
    [class.fixed]="isVisibleAirports"
    [class.flights-included]="ratePlanType?.flightsIncluded || isVisibleAirports">
    <div class="mat-drawer-backdrop"
      [class.mat-drawer-shown]="(
        departureAirportSelect.airportSettings.isActive ||
        arrivalAirportSelect.airportSettings.isActive
        ) && isVisibleAirports">
    </div>
    <div class="main-container">
      <div class="range-container"
        [@airportsVisibilityChange]="isAnimateDatePicker">
        <div class="p-inputgroup">
          <mat-form-field
            *ngIf="dateRange">
            <mat-date-range-input
              [rangePicker]="picker"
              [formGroup]="dateRange"
              (click)="picker.open()">
              <input
                id="startDate"
                formControlName="start"
                readonly
                matStartDate
                [placeholder]="t('startDate')">
              <input
                id="endDate"
                formControlName="end"
                readonly
                matEndDate
                [placeholder]="t('endDate')">
            </mat-date-range-input>
            <mat-datepicker-toggle
              matPrefix
              [for]="picker">
              <app-icon matDatepickerToggleIcon
                name="calendar">
              </app-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker
              #picker
              (closed)="closedDateRangePicker(picker)">
            </mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>
      <div class="airports-container"
        [@airportsVisibilityChange]="isVisibleAirports"
        [hidden]="!(ratePlanType?.flightsIncluded || isVisibleAirports)">
        <app-airport-select
          #departureAirportSelect
          [airport]="departureAirport"
          [airports]="departureAirports"
          [showFirstByDefault]="false"
          type="departure"
          (selectChange)="onAirportSelect($event, true)">
        </app-airport-select>
        <!-- <app-icon
        stroked="default"
        name="replace"
        size="big"
        (click)="switchRatePlanType()"
        [ngClass]="{ 'airport-button': true, 'disabled': !isAvailableRatePlanTypeSwitch }">
      </app-icon> -->
        <app-airport-select
          #arrivalAirportSelect
          class="rotate-arrival-airport-icon"
          [airport]="arrivalAirport"
          [airports]="arrivalAirports"
          [showFirstByDefault]="true"
          type="arrival"
          (selectChange)="onAirportSelect($event, false)">
        </app-airport-select>
      </div>
    </div>
  </div>
</ng-container>