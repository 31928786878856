<ng-container *transloco="let t">
  <app-sidebar-layout
    #airportTransferDetailsLayout>
    <ng-container
      *ngIf="currentAirportTransfer">
      <ng-template #header>
        <div class="sidebar-layout-header">
          <div class="sidebar-layout-header-text">
            <h2>{{currentAirportTransfer.name}}</h2>
            <app-icon-button
              class="bs-right"
              stroked="iconInactive"
              buttonClass="close-button"
              (click)="airportTransferDetailsLayout.close()"
              name="close">
            </app-icon-button>
          </div>
        </div>
      </ng-template>
      <ng-template #content>
        <div class="sidebar-content sidebar-scroll-container">
          <div class="sidebar-carousel-images-container">
            <app-gallery
              [imagePopup]="false"
              [images]="images">
            </app-gallery>
          </div>
          <p>{{currentAirportTransfer.description}}</p>
          <div class="flex-column gap">
            <div>{{t('airportTransfer.label')}}</div>
            <div class="flex-between gap-half cursor-pointer"
              *ngIf="arrivalAirport"
              (click)="selectAsInbound()">
              <app-icon
                name="checked"
                size="default"
                [class.active]="currentAirportTransfer.id === inboundAirportTransfer?.id"
                [class.background-color-selected]="currentAirportTransfer.id === inboundAirportTransfer?.id"
                class="select-button select-icon">
              </app-icon>
              {{t('airportTransfer.fromAirportToHotel', { value: arrivalAirport.name })}}
            </div>
            <div class="flex-between gap-half cursor-pointer"
              *ngIf="arrivalAirport"
              (click)="selectAsOutbound()">
              <app-icon
                name="checked"
                size="default"
                [class.active]="currentAirportTransfer.id === outboundAirportTransfer?.id"
                [class.background-color-selected]="currentAirportTransfer.id === outboundAirportTransfer?.id"
                class="select-button select-icon">
              </app-icon>
              {{t('airportTransfer.fromHotelToAirport', { value: arrivalAirport.name })}}
            </div>
            <div [innerHTML]="t('airportTransfer.note')"></div>
          </div>
        </div>
      </ng-template>
      <ng-template #footer>
        <div class="pricing-container">
          <app-pricing-details
            #pricingDetails
            size="default"
            showTextClass="hidden"
            [currentAirportTransfer]="currentAirportTransfer">
          </app-pricing-details>
        </div>
      </ng-template>
    </ng-container>
  </app-sidebar-layout>
</ng-container>
