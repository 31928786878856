import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslocoRootModule } from '../transloco-root.module';
import { SharedModule } from '@shared/shared.module';

import { AccountLayoutComponent } from './components/account-layout/account-layout.component';
import { AccountLayoutItemComponent } from './components/account-layout-item/account-layout-item.component';

import { PromoCodeComponent } from './components/promo-code/promo-code.component';
import { TravelAgentComponent } from './components/travel-agent/travel-agent.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    AccountLayoutComponent,
    AccountLayoutItemComponent,
    PromoCodeComponent,
    TravelAgentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoRootModule,

    MatButtonModule,

    SharedModule
  ],
  providers: [
  ],
  exports: [
    AccountLayoutComponent
  ]
})
export class AccountModule { }
