export const defaultAdultsCount = 2;
export const defaultStopsCount = 2;
export const totalStopsCount = 4;
export const maxCountAddonsToPurchase = 10;
export const maximumNightsToProcess = 100;
export const perPageFlights = 2;
export const usaCode = 'US'
export const topCountriesTwoLetterCodes: (string | undefined)[] = [usaCode, 'GB'];
export const defaultDaysSearchStart = 64;
export const defaultDaysSearchPeriod = 14;
export const noImageSrc = '/assets/images/no-image.svg';
export const tripProtectionCategory = 'INS';
export const isMembershipEnabled = false;
export const zipcodeCountries = [usaCode];
export const jamaicaAirportsCodes = ['OCJ', 'KIN', 'MBJ'];
