import { HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { ssrAllowed } from '@shared/consts/process-variables';

export const httpStatus = { Get: 'GET', Post: 'POST' };
const { Get, Post } = httpStatus;

export class HttpRequestTransferState<T> {
  readonly allowedMethods = [Get, Post];

  constructor(private httpRequest: HttpRequest<T>) { }

  get method() {
    return this.httpRequest.method;
  }

  get url() {
    let result = this.httpRequest.url;
    if (this.httpRequest.url.startsWith('http')) {
      result = this.httpRequest.url.replace(new URL(this.httpRequest.url).origin, '');
    }

    if (result.startsWith('/')) {
      result = result.replace('/', '');
    }

    return result;
  }

  get bodyString() {
    return JSON.stringify(this.httpRequest.body);
  }

  get transferStateKey() {
    switch (this.method) {
      case Get:
        return this.getValidKey(this.url);
      case Post:
        return this.getValidKey(this.url, this.bodyString)

      default:
        break;
    }

    throw new Error('Not defined transfer state key for this http method');
  }


  get isMethodAllowed() {
    return this.allowedMethods.includes(this.method);
  }

  get isInterceptable() {
    return this.isMethodAllowed && this.url.indexOf('/api') === -1 && !this.isSvg;
  }

  get isSvg() {
    return this.url.endsWith('.svg');
  }

  get isIconsAllowed() {
    return ssrAllowed.icons() && this.isSvg;
  }

  getValidKey(...keyParts: string[]) {
    return keyParts.join('').split(':').join('');
  }
}

export class HttpEventTransferState<T> {
  readonly allowedStatuses = [200, 202];

  constructor(private event: HttpEvent<T>) {
  }

  get body() {
    return this.httpResponse?.body;
  }

  get httpResponse(): HttpResponse<T> | undefined {
    if (this.event instanceof HttpResponse) {
      return this.event;
    }

    return undefined;
  }

  get isStatusAllowed() {
    return this.httpResponse && this.allowedStatuses.includes(this.httpResponse?.status);
  }
}
