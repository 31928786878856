import { EventEmitter, Injectable } from '@angular/core';
import { AddOnResponse, AddOnToPurchase } from '@shared/api/be-api.generated';
import { DataService } from '@shared/services/data.service';
import { AddOnToPurchaseViewModel, AddonType, IQueryStringAddOn } from '@shared/models/common';
import { QueryParamsService } from './query-params.service';
import { maxCountAddonsToPurchase, tripProtectionCategory } from '@shared/consts/common';
import { SearchService } from './search/search.service';
import { PackageService } from './package.service';

@Injectable()
export class AddOnService {
  dataChanged = new EventEmitter();
  tripProtectionCategory = tripProtectionCategory;

  toPurchase: AddOnToPurchase[] = [];
  toPurchaseViewModel: AddOnToPurchaseViewModel[] = [];
  categories: string[] = [];
  resortAddOns: AddOnResponse[] = [];

  currentAddOns: AddOnResponse[] = [];
  category?: string;
  showPurchased?: boolean;
  showAvailable?: boolean;

  tripProtectionAddon?: AddOnResponse;
  get isTripProtectionPurchased() {
    return this.toPurchase.some(item => item.addOnId === this.tripProtectionAddon?.addOnId);
  }

  get toPurchaseRecord() {
    const result: Record<string, number> = {};

    this.toPurchase
      .forEach((item) => item.addOnId && item.quantity && (result[item.addOnId] = item.quantity));

    return result;
  }

  get countPurchasedTripProtection() {
    return this.toPurchase.find(addOn => addOn.addOnId === this.tripProtectionAddon?.addOnId)?.quantity;
  }

  private get _allAddOns() {
    return this._data.values.addOns;
  }

  get allAddOnsCategories() {
    return this._data.values.addOnCategories;
  }

  constructor(
    private readonly _search: SearchService,
    private readonly _data: DataService,
    private readonly _queryParams: QueryParamsService,
    private readonly _package: PackageService
  ) {
    this._queryParams.routeChanged
      .subscribe(() => this.initialize());
    this._search.setWhenContextChanged(({ resort, fromDate, toDate }) => {
      if (resort && fromDate && toDate) {
        const availableAddOns = this._allAddOns.filter(addOn =>
          (resort.addOns || []).includes(addOn.addOnId || '')
          && (!addOn.availabilityPeriod ||
            ((addOn.availabilityPeriod.start || toDate) <= toDate
              && ((addOn.availabilityPeriod.finish || fromDate) >= fromDate)))
        );

        const unOrderedAddons = availableAddOns.filter(item => (item.orderNumber || -1) < 0);
        const orderedAddons = availableAddOns
          .filter(item => (item.orderNumber || -1) >= 0)
          .sort((item1, item2) => (item1.orderNumber || 0) > (item2.orderNumber || 0) ? 1 : -1);

        this.resortAddOns = [...orderedAddons, ...unOrderedAddons];
      }

      this.initialize();
    }),
      this._package.setWhenDataChanged(() => {
        if (this.isTripProtectionPurchased &&
          this._search.getTotalAdults() !== this.countPurchasedTripProtection) {
          this.changeTripProtection(true);
        }
      });
  }

  initialize() {
    this.resetFilters();
    this.setCategories();
    this.currentAddOns = this.getAddons();
    this._restorePurchasedFromQueryString();
    this._setAddOnsViewModel();
    this._setTripProtection();
    this.dataChanged.emit();
  }

  resetFilters() {
    this.category = undefined;
    this.showPurchased = undefined;
    this.showAvailable = undefined;
  }

  setAddOns() {
    this.currentAddOns = this.getAddons();
    this.dataChanged.emit();
  }

  setCategories() {
    this.categories = [];
    this.resortAddOns.forEach(addOn => addOn.category
      && addOn.category !== this.tripProtectionCategory
      && this.categories.indexOf(addOn.category) === -1
      && this.categories.push(addOn.category));
  }

  private _setTripProtection() {
    this.tripProtectionAddon = this.resortAddOns.find(addOn => addOn.category === this.tripProtectionCategory);
  }

  changeTripProtection(includeProtect: boolean) {
    if (this.tripProtectionAddon) {
      if (includeProtect) {
        const adultsCount = this._search.getTotalAdults();
        if (adultsCount > 0) {
          this.purchase(this.tripProtectionAddon, adultsCount);
        }
      } else {
        this.cancelPurchase(this.tripProtectionAddon);
      }
    }
  }

  purchase(item: AddOnResponse, quantity: number, ageGroupId: string | undefined = undefined) {
    const addOnId = item.addOnId;

    if (quantity === 0) {
      this.cancelPurchase(item, ageGroupId);
    } else {
      let addOn = this.toPurchase.find(addOn => addOn.addOnId === addOnId
        && (!ageGroupId || addOn.ageGroupId === ageGroupId));
      if (!addOn) {
        addOn = new AddOnToPurchase();
        this.toPurchase.push(addOn);
      }

      addOn.addOnId = addOnId;
      addOn.quantity = quantity;
      addOn.ageGroupId = ageGroupId;
    }

    this._update();
  }

  cancelPurchase({ addOnId }: AddOnResponse, ageGroupId: string | undefined = undefined) {
    this.toPurchase = this.toPurchase.filter(addOn => !(addOn.addOnId === addOnId
      && (!ageGroupId || addOn.ageGroupId === ageGroupId)));
    this._update();
  }

  private _update() {
    this._setAddOnsViewModel();
    this._updateQueryString();
    this.dataChanged.emit();
  }

  getToPurchase({ addOnId }: AddOnResponse) {
    return this.toPurchase.find(addOn => addOn.addOnId === addOnId);
  }

  getToPurchaseViewModels({ addOnId }: AddOnResponse) {
    return this.toPurchaseViewModel.filter(addOn => addOn.addOnId === addOnId);
  }

  getAddons() {
    return this.resortAddOns.filter(addOn =>
      (!this.tripProtectionAddon || addOn.addOnId !== this.tripProtectionAddon.addOnId)
      && (this.category === undefined || addOn.category === this.category)
      && (this.showPurchased === undefined || this.toPurchase.some(tp => tp.addOnId === addOn.addOnId) === this.showPurchased)
      && (this.showAvailable === undefined
        || !!addOn.pricingModel?.price === this.showAvailable
        || !!addOn.pricingModel?.prices?.length === this.showAvailable
      )) || [];
  }

  private _setAddOnsViewModel() {

    this.toPurchaseViewModel = this.toPurchase.map(item => {
      const { pricingModel, name, category } = this.resortAddOns.find(addOn => addOn.addOnId === item.addOnId) || {};
      const { type: addOnType } = pricingModel || {};

      const {
        price: ageGroupPricePerItem,
        agePeriod: {
          fromYear: from = 0,
          toYear: to = 0 } = {}
      } = pricingModel?.prices?.find(ageGroup => ageGroup.id === item.ageGroupId) || {};

      const pricePerItem = (addOnType === AddonType.PerAgeGroup ? ageGroupPricePerItem : pricingModel?.price) || 0;

      if (item.quantity && item.quantity > maxCountAddonsToPurchase) {
        item.quantity = maxCountAddonsToPurchase;
      }

      const categoryData = !!category ? this.allAddOnsCategories.find(item => item.code === category) : undefined;

      return new AddOnToPurchaseViewModel({
        ...item, addOnType, name, pricePerItem, from, to,
        categoryName: categoryData?.name,
        categoryCode: categoryData?.code,
      });
    });
  }

  setWhenDataChanged(onDataChanged: (data: AddOnService) => void, useWithFirstInit = true) {
    if (useWithFirstInit) {
      onDataChanged(this);
    }

    return this.dataChanged.subscribe(() => onDataChanged(this));
  }

  private _updateQueryString() {
    const addOns: IQueryStringAddOn[] = this.toPurchase.map(({
      addOnId, ageGroupId = undefined, quantity = 0 }) =>
      ({ addOnId, ageGroupId, quantity }));

    this._queryParams.patchQueryParams({ addOns });
  }

  private _restorePurchasedFromQueryString() {
    const { addOns = [] } = this._queryParams.value;

    const availableAddOns = addOns.filter(addOn => this.resortAddOns
      .some(({ addOnId, pricingModel: { type, prices } = {} }) =>
        addOnId === addOn.addOnId
        && (type !== AddonType.PerAgeGroup || prices?.some(item => item.id === addOn.ageGroupId))));

    this.toPurchase = availableAddOns.map(item => new AddOnToPurchase({ ...item }));
    this._updateQueryString();
  }
}
