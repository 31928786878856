<mat-toolbar class="main-header"
  [style.background-color]="resort?.resortId === 'acde1ce6-cf48-4569-af00-64848cd58d78' ? '#000000': ''"
  [class.with-rate-plan-type-switcher]="availableRatePlanType">
  <div class="main-container">
    <!-- *ngIf="isOnSearchPage || isOnConfirmationPage" -->
    <div class="logo-row">
      <div class="resort-logo visible-bigMobile flex">
        <ng-container
          [ngTemplateOutlet]="resortLogo">
        </ng-container>
      </div>
    </div>
    <div class="first-row">
      <div class="menu-button">
        <app-icon-button
          buttonClass="background-color-default menu-button"
          stroked="textPrimary"
          [transparent]="false"
          (click)="openProfileMenu()"
          name="menu">
        </app-icon-button>
      </div>
      <div class="selects-container flex-between">
        <div class="rate-plan-type-select three-dots">
          <ng-container
            [ngTemplateOutlet]="ratePlanTypeSelect">
          </ng-container>
        </div>
        <div class="resort-logo hidden-bigMobile">
          <ng-container
            [ngTemplateOutlet]="resortLogo">
          </ng-container>
        </div>
        <div class="hotel-select hidden-bigMobile three-dots">
          <ng-container
            [ngTemplateOutlet]="hotelSelect">
          </ng-container>
        </div>
      </div>
      <div class="guest-details">
        <app-icon-button
          *ngIf="isOnSearchPage"
          (click)="openGuestDetails()"
          name="users"
          alt="guests"
          type="raised"
          filled="default"
          buttonSize="small"
          stroked="textPrimary"
          [customButtonSize]="true"
          [transparent]="false"
          buttonClass="guest-detail-button">
          <span class="guest-count">{{guestsCount}}</span>
        </app-icon-button>
        <app-icon-button
          *ngIf="isOnBookingPage || isOnConfirmationPage"
          name="lock"
          alt="guests"
          type="raised"
          filled="textPrimary"
          buttonSize="default"
          buttonClass="guest-detail-button lock"
          [customButtonSize]="true"
          [transparent]="false">
        </app-icon-button>
      </div>
    </div>

    <div class="visible-bigMobile flex second-row">
      <div class="selects-container flex three-dots">
        <ng-container
          [ngTemplateOutlet]="hotelSelect">
        </ng-container>
      </div>
    </div>
  </div>
</mat-toolbar>

<ng-template #ratePlanTypeSwitcher>
  <div *ngIf="availableRatePlanType && isOnSearchPage"
    class="flex-between gap-half cursor-pointer"
    (click)="changeRatePlanType()">
    <app-icon
      *ngIf="currentRatePlanType"
      name="checked"
      size="default"
      [class.active]="isActiveRatePlanTypeSwitcher"
      [class.background-color-selected]="isActiveRatePlanTypeSwitcher"
      class="select-button select-icon">
    </app-icon>
    {{availableRatePlanType.name}}
  </div>
</ng-template>

<ng-template #ratePlanTypeSelect>
  <mat-form-field>
    <mat-select
      class="rate-plan-type-select"
      [disabled]="((availableRatePlanTypes?.length || 0) < 2) || !isOnSearchPage"
      [compareWith]="compareRatePlanType"
      [value]="currentRatePlanType"
      (selectionChange)="ratePlanTypeChanged($event)">
      <mat-option
        *ngFor="let ratePlanType of availableRatePlanTypes; trackBy: 'ratePlanTypeId' | createTrackBy"
        [value]="ratePlanType">
        {{ratePlanType.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #hotelSelect>
  <mat-form-field>
    <mat-select
      class="hotel-select"
      [disabled]="!isOnSearchPage"
      [compareWith]="compareResort"
      [value]="resort"
      (selectionChange)="resortChanged($event)">
      <mat-option
        *ngFor="let resort of resorts; trackBy: 'id' | createTrackBy"
        [value]="resort">
        {{resort.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #resortLogo>
  <img loading="lazy" [src]="resortLogoImageURL" *ngIf="resortLogoImageURL" alt="Logo" />
</ng-template>