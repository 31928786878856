import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AddOnPricingType as AddOnPricingResponseType, AddOnResponse, AirportTransfer } from '@shared/api/be-api.generated';
import { maxCountAddonsToPurchase } from '@shared/consts/common';
import { AddOnToPurchaseViewModel, AddonType, Size } from '@shared/models/common';

@Component({
  selector: 'app-airport-transfer-pricing',
  templateUrl: './airport-transfer-pricing.component.html',
  styleUrls: ['./airport-transfer-pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AirportTransferPricingComponent {
  @Input() currentAirportTransfer!: AirportTransfer;
  @Input() inboundAirportTransfer?: AirportTransfer;
  @Input() outboundAirportTransfer?: AirportTransfer;
  @Input() isSoldOut = false;
  @Input() isSelectedPackage = true;
}
