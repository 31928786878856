<ng-container *transloco="let t">
  <div class="footer">
    <div class="footer-top">
      <app-icon
        name="lock"
        alt="C-Breez"
        filled="textPrimary">
      </app-icon>
      &nbsp;
      <span [innerHtml]="t('footer.top')"></span>
    </div>
    <div class="footer-middle">
      <span [innerHtml]="t('footer.middle')"></span>
      <!-- <img src="/assets/images/logo.svg" alt="C-Breez"> -->
      <a href="https://www.c-breez.com/"
        aria-label="C-Breez"
        appTargetBlank>
        <app-icon
          size="custom"
          [height]="1"
          scope="images"
          name="logo"
          alt="C-Breez">
        </app-icon>
      </a>
    </div>
    <!-- <div class="footer-middle">
      &nbsp;
      <a href="https://www.c-breez.com/privacy-policy"
        class="link-underlined"
        [attr.aria-label]="t('footer.privacy')"
        appTargetBlank>
        {{t('footer.privacy')}}
      </a>
    </div> -->
    <div class="footer-bottom"
      [innerHtml]="t('footer.bottom', { value: currentYear })">
    </div>
  </div>
</ng-container>