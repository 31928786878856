import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { PackageService } from '@shared/services/package.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { Airport, AirportTransfer } from '@shared/api/be-api.generated';
import { SearchService } from '@shared/services/search/search.service';
import { ComponentBase } from '@shared/base/component.base';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { UnavailabilityReason } from '@shared/models/common';

@Component({
  selector: 'app-airport-transfer-select-options',
  templateUrl: './airport-transfer-select-options.component.html',
  styleUrls: ['./airport-transfer-select-options.component.scss']
})
export class AirportTransferSelectOptionsComponent extends ComponentBase implements AfterViewInit {
  @ViewChild('options') options!: CdkVirtualScrollViewport;

  transfers: AirportTransfer[] = [];
  sortedTransfers: AirportTransfer[] = [];

  inboundAirportTransfer?: AirportTransfer;
  outboundAirportTransfer?: AirportTransfer;
  arrivalAirport?: Airport;

  isActive = false;
  itemSize = 300;

  unavailabilityReason?: UnavailabilityReason;

  constructor(
    private readonly _package: PackageService,
    private readonly _search: SearchService,
    services: ComponentBaseService
  ) {
    super(services);

    this.pushSub(
      this._package.setWhenDataChanged(({
        airportTransfers,
        inboundAirportTransfer,
        outboundAirportTransfer,
        selectedPackage
      }) => {
        if (this.transfers !== airportTransfers) {
          this.transfers = airportTransfers;
          this.sortedTransfers = [
            ...this.transfers.filter(t => !t.price),
            ...this.transfers.filter(t => t.price).sort((t1, t2) =>
              (t1.price || 0) > (t2.price || 0) ? 1 : -1)]
        }

        const arrivalAirport = this._search.state.context.arrivalAirport;
        if (this.arrivalAirport !== arrivalAirport) {
          this.arrivalAirport = arrivalAirport;
        }

        if (this.inboundAirportTransfer?.id !== inboundAirportTransfer?.id) {
          this.inboundAirportTransfer = inboundAirportTransfer;
        }

        if (this.outboundAirportTransfer?.id !== outboundAirportTransfer?.id) {
          this.outboundAirportTransfer = outboundAirportTransfer;
        }

        this.isActive = !!selectedPackage && !!arrivalAirport;
        this.unavailabilityReason = this._package.unavailabilityReason;
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      setTimeout(() => {
        const options = this.options?.elementRef?.nativeElement as HTMLElement;
        const option = options?.querySelector('app-select-option');
        if (option) {
          this.itemSize = option.clientWidth;
        }
      });
    }
  }
}
