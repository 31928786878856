<ng-container *transloco="let t">
  <div class="sidebar-layout">
    <div class="sidebar-layout-header"
      *ngIf="scope && !header">
      <div class="sidebar-layout-header-text">
        <h2>{{t(headerText)}}</h2>
        <app-icon-button
          class="bs-right"
          stroked="iconInactive"
          buttonClass="close-button"
          (click)="close()"
          name="close">
        </app-icon-button>
      </div>
      <div class="sidebar-layout-subheader">
        <p>{{t(subheader)}}</p>
      </div>
    </div>
    <div class="sidebar-layout-header"
      *ngIf="header">
      <ng-template [ngTemplateOutlet]="header"></ng-template>
    </div>
    <div class="sidebar-layout-content">
      <ng-template [ngTemplateOutlet]="content"></ng-template>
    </div>
    <div class="sidebar-layout-footer"
      *ngIf="footer">
      <ng-template [ngTemplateOutlet]="footer"></ng-template>
    </div>
  </div>
</ng-container>
