import { Component, Input } from '@angular/core';
import { AddOnResponse } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { AddOnToPurchaseViewModel, CarouselImage, UnavailabilityReason, WithId } from '@shared/models/common';
import { AddOnService } from '@shared/services/add-on.service';
import { ComponentBaseService } from '@shared/services/component-base.service';

@Component({
  selector: 'app-addon-details',
  templateUrl: './addon-details.component.html',
  styleUrls: ['./addon-details.component.scss']
})
export class AddonDetailsComponent extends ComponentBase {
  @Input() currentAddOn!: AddOnResponse;
  @Input() images: WithId<CarouselImage>[] = [];
  @Input() isPerAgeGroupTypeAddon?: boolean;
  @Input() isSoldOut!: boolean;
  @Input() addOnsToPurchase!: AddOnToPurchaseViewModel[];
  @Input() isActive!: boolean;
  @Input() isSelectedPackage!: boolean;
  @Input() disabled!: boolean;
  @Input() countToPurchase!: number;
  @Input() unavailabilityReason?: UnavailabilityReason;

  constructor(
    private readonly _addon: AddOnService,
    services: ComponentBaseService
  ) {
    super(services);
  }

  updateCountToPurchase(newValue: number, ageGroupId?: string) {
    this.countToPurchase = newValue;
    this.purchaseAddOn(ageGroupId);
  }

  purchaseAddOn(ageGroupId?: string) {
    if (!this.isSoldOut && this.currentAddOn) {
      this._addon.purchase(this.currentAddOn, this.countToPurchase, ageGroupId);
    }
  }
}
