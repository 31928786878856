import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Airport, AirportTransfer, FlightDetails, ReservationRequest } from '@shared/api/be-api.generated';
import { createFormControl, createUntypedFormGroup, includes } from '@shared/common';
import { SelectOption } from '@shared/models/common';
import { PackageService } from '@shared/services/package.service';
import { AppCurrencyPipe } from 'src/app/pipes/app-currency.pipe';

export const journeyFields = [
  'departureAirport', 'departureTime',
  'arrivalAirport', 'arrivalTime',
  'flightNumber', 'airportTransfer'
] as const;

export type JourneyField = typeof journeyFields[number];

export const withoutTransferValue = 'without';

export class JourneyOptions {
  departure: SelectOption[] = [];
  arrival: SelectOption[] = [];
  transfer: SelectOption[] = [];
}

export class JourneyOptionsSource {
  options = new JourneyOptions();
  origins = new JourneyOptions();

  constructor(
    private readonly _package: PackageService,
    private readonly _appCurrencyPipe: AppCurrencyPipe,
    private readonly _transloco: TranslocoService) {
  }

  setTransfer(transfers: AirportTransfer[]) {
    this.origins.transfer = transfers.map(({ id, name }) => ({ label: name, value: id }));
    this.options.transfer = [...this.origins.transfer];
  }

  setArrival(airports?: Airport[], exceptValues?: string[]) {
    this.origins.arrival = this._getAiportsOptions(airports, exceptValues);
    this.options.arrival = [...this.origins.arrival];
  }

  setDeparture(airports?: Airport[], exceptValues?: string[]) {
    this.origins.departure = this._getAiportsOptions(airports, exceptValues);
    this.options.departure = [...this.origins.departure];
  }

  private _getAirportsOptions = (airports?: Airport[]) => airports?.
    map(({ code, displayText }) => ({ label: displayText, value: code })) || [];

  private _getAiportsOptions = (airports?: Airport[], exceptValues?: string[]) =>
    this._getAirportsOptions(airports?.filter(airport =>
      !exceptValues || !includes(airport.code, ...(exceptValues || []))));

  getAvailableAirportTransfers = (airportCode: string) =>
    (this._package.resort?.airportTransfers || [])
      .filter(t => !t.airportsCodes?.length
        || t.airportsCodes?.some(code => code === airportCode));

  getAvailableAirportTransfersOptions = (airportCode: string) =>
    this.getAvailableAirportTransfers(airportCode)
      .map(item => this._getTransferSelectOption(item)) || [];

  private _getTransferSelectOption = ({ name, price, id: value }: AirportTransfer) => ({
    label: [name, '-', price
      ? this._appCurrencyPipe.transform(price)
      : this._transloco.translate('complimentary').toUpperCase()
    ]
      .filter(value => value)
      .join(' '),
    value
  });

  getAirportTransfer = (airportCode: string, airportTransferId: string) =>
    this.getAvailableAirportTransfers(airportCode)
      .find(item => item.id === airportTransferId)

  setAiportTransfers(airportCode: string) {
    this.options.transfer = this.getAvailableAirportTransfers(airportCode)
      .map(item => this._getTransferSelectOption(item));
    if (!this.options.transfer.length) {
      this.options.transfer = [{
        label: this._transloco.translate('airportTransfer.without'),
        value: withoutTransferValue
      }];
    }
  }
}

export class JourneyFormGroups {
  inForm!: UntypedFormGroup;
  outForm!: UntypedFormGroup;

  get inCart() {
    return this.cartRequest?.existingFlights?.inboundFlight;
  }

  get outCart() {
    return this.cartRequest?.existingFlights?.outboundFlight;
  }

  constructor(
    public cartRequest?: ReservationRequest,
    public availableAirportsCodes?: string[],
  ) {
    this.inForm = this._build(true, this.inCart);
    this.outForm = this._build(false), this.outCart;
  }

  private _build = (inbound: boolean, details?: FlightDetails) =>
    createUntypedFormGroup(this._createJourneyControls(inbound, details));

  private _validateIsAvailableAirportCode = (control?: AbstractControl) =>
    !this.availableAirportsCodes?.some(code => code === control?.value)
      ? { notSelectedAirport: true }
      : null;

  private _createJourneyControls = (inbound: boolean, details?: FlightDetails)
    : Record<JourneyField, UntypedFormControl | undefined> => {
    if (inbound) {
      return {
        airportTransfer: createFormControl(details?.airportTransferId),
        arrivalAirport: createFormControl(details?.arrivalAirportCode,
          control => this._validateIsAvailableAirportCode(control)),
        arrivalTime: undefined,
        departureAirport: undefined,
        departureTime: undefined,
        flightNumber: createFormControl(details?.flightNumber)
      };
    } else {
      return {
        airportTransfer: createFormControl(details?.airportTransferId),
        arrivalAirport: undefined,
        arrivalTime: undefined,
        departureAirport: createFormControl(details?.departureAirportCode,
          control => this._validateIsAvailableAirportCode(control)),
        departureTime: undefined,
        flightNumber: undefined
      }
    }
  }

  // old version, kept for reference
  // private _createJourneyControls = (inbound: boolean, details?: FlightDetails) => ({
  //   airportTransfer: createFormControl(details?.airportTransferId),
  //   arrivalAirport: inbound ? createFormControl(details?.arrivalAirportCode, ) : undefined,
  //   arrivalTime: undefined,
  //   departureAirport: inbound ? undefined : createFormControl(details?.departureAirportCode),
  //   departureTime: undefined,
  //   flightNumber: inbound ? createFormControl(details?.flightNumber) : undefined
  // } as Record<JourneyField, UntypedFormControl | undefined>);
}
