import { Injectable, EventEmitter } from '@angular/core';

import { DateTime } from 'luxon';
import { catchError } from 'rxjs';

import { BookingEngineClient, BookingEngineHtmlContent } from '@shared/api/be-api.generated';
import { HtmlContentStringCode } from '@shared/models/common';
import { getHtmlContentCode } from '@shared/common';

import { DataService } from './data.service';
import { SearchService } from './search/search.service';

@Injectable({
  providedIn: 'root'
})
export class HtmlContentService {
  content: BookingEngineHtmlContent[] = [];
  settingsLoaded = new EventEmitter();
  settingsChanged = new EventEmitter();
  initialSettingsLoaded = new EventEmitter();

  isLoaded = false;
  isInitialSettingsLoaded = false;

  private _allContent: BookingEngineHtmlContent[] = [];

  constructor(
    private readonly _data: DataService,
    private readonly _search: SearchService,
    private readonly _apiClient: BookingEngineClient,
  ) {
    this._data.setWhenInitialized(({ settings }) => {
      if (settings.htmlContent) {
        this._updateAllContent(settings.htmlContent);
        this.isInitialSettingsLoaded = true;
        this.initialSettingsLoaded.emit();
      }
    });
  }

  setAvailableBySearchContent(isEmitChange = true) {
    const { resort, fromDate, toDate } = this._search.state.context;
    const resortId = resort?.resortId;
    if (resortId && fromDate && toDate) {
      this.setAvailable(resortId, fromDate, toDate, isEmitChange);
    }
  }

  setAvailable(resortId: string, fromDate: DateTime, toDate: DateTime, isEmitChange = true) {
    this.content = this._allContent.filter(({
      resortsIds = [], travelPeriod: { start, finish } = {}
    }) =>
      (!resortsIds.length || resortsIds.includes(resortId))
      && (start || toDate) <= toDate && (finish || fromDate) >= fromDate
    );

    if (isEmitChange) {
      this.settingsChanged.emit();
    }
  }

  load() {
    this._apiClient.getHtmlContent(this._data.tenant.id)
      .pipe(catchError(() => []))
      .subscribe(result => {
        this._updateAllContent(result);
        this.isLoaded = true;
        this.settingsLoaded.emit();
      });
  }

  get(
    action: (result?: string) => void,
    code: HtmlContentStringCode,
    position?: number,
    isNeedLoad = true
  ) {
    const executeAction = () => action(this.getFromDataset(code, position)?.content);
    if (this.isLoaded) {
      executeAction();
    } else if (isNeedLoad) {
      this.load();
    }

    return this.settingsChanged
      .subscribe(() => executeAction());
  }

  getMany(
    action: (result: string[]) => void,
    code: HtmlContentStringCode,
    isNeedLoad = true
  ) {
    const executeAction = () => action(this.getManyFromDataset(code)
      .map(item => item?.content || '')
      .filter(content => content));

    if (this.isLoaded) {
      executeAction();
    } else if (isNeedLoad) {
      this.load();
    }

    return this.settingsChanged
      .subscribe(() => executeAction());
  }

  //#region private
  getFromDataset(stringCode: HtmlContentStringCode, position?: number) {
    const code = getHtmlContentCode(stringCode);
    const value = this.content.find(item => item.code === code && (position === undefined || item.position === position));
    return value;
  }

  getManyFromDataset(stringCode: HtmlContentStringCode) {
    const code = getHtmlContentCode(stringCode);
    const value = this.content.filter(item => item.code === code);
    return value;
  }

  private _updateAllContent(htmlContent: BookingEngineHtmlContent[]) {
    htmlContent.forEach(({ code, position, content, resortsIds, travelPeriod, ratePlanTypeIds }) => {
      position = position === 0 || (position && position > 0)
        ? position // can be null in position field and it's bad
        : undefined;

      const contentItem = this._allContent
        .find(item => item.code === code && item.position === position);

      if (contentItem) {
        contentItem.content = content;
      } else {
        this._allContent.push(new BookingEngineHtmlContent({ code, position, content, resortsIds, travelPeriod, ratePlanTypeIds }));
      }
    });
  }
  //#endregion
}
