<ng-container *transloco="let t">
  <div class="failed-payment-part-alert flex"
    *ngIf="unpaid > 0">
    <div class="main-container"
      [class.no-padding]="noPaddingAround">
      <app-notification
        position="start"
        [backgroundColor]="backgroundColor"
        [stroked]="borderColor"
        [borderColor]="borderColor"
        borderWidth="thick"
        [iconName]="iconName"
        [isThreeDots]="false">
        <ng-template #content>
          <div [ngClass]="['alert-content', 'color-' + textColor]"
            [innerHTML]="t(['paymentSummary', 'failedPayment'] | join, { phone, unpaid: unpaid | appCurrency })"></div>
        </ng-template>
      </app-notification>
    </div>
  </div>
</ng-container>