import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ConfirmationService } from '@confirmation/services/confirmation.service';

@Component({
  selector: 'app-booking-email',
  templateUrl: './booking-email.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingEmailComponent {
  email = this._confirmation.values.bookingDetails?.email;

  constructor(
    private readonly _confirmation: ConfirmationService
  ) { }
}
