<ng-container *transloco="let t">
  <div class="cart-summary"
    *ngIf="isSelectedPackage">
    <app-two-columns>
      <ng-template #leftColumn>
        <app-cart-viewer></app-cart-viewer>
        <app-cart-total class="visible-tablet"></app-cart-total>
        <app-booking-button class="visible-tablet"></app-booking-button>
      </ng-template>
      <ng-template #rightColumn>
        <div class="flex-column gap">
          <app-cart-total></app-cart-total>
          <app-booking-button></app-booking-button>
        </div>
      </ng-template>
    </app-two-columns>
  </div>
</ng-container>