import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { DataService } from '@shared/services/data.service';
import { ConfirmationService } from '@confirmation/services/confirmation.service';
import { HtmlContentService } from '@shared/services/html-content.service';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-confirmation-layout',
  templateUrl: './confirmation-layout.component.html',
})
export class ConfirmationLayoutComponent extends ComponentBase {
  bookingId?: string;
  activeResortLogo?: string;
  activeResortCaption?: string;
  activeResortPhone?: string;
  safetyProtocols?: string;
  isInitialized = false;
  isBookingCancelled = false;

  constructor(
    data: DataService,
    confirmation: ConfirmationService,
    services: ComponentBaseService,
    route: ActivatedRoute,
    htmlContent: HtmlContentService,
    tracking: TrackingService
  ) {
    super(services);

    this.pushSub(
      route.paramMap.subscribe(value => {
        const bookingId = value.get('bookingId') || undefined;
        if (bookingId && bookingId !== confirmation.bookingId) {
          confirmation.bookingId = bookingId;
          confirmation.isInitialized = false;
          confirmation.initialize();
        }
      }),
      confirmation.setWhenInitialized(response => {
        if (response && response.bookingDetails) {
          const bookingDetails = response.bookingDetails;

          this.isBookingCancelled = bookingDetails.status === 'Cancelled';

          this.bookingId = bookingDetails.bookingNumber;
          const resort = data.values.resorts.find(resort =>
            resort.resortId === bookingDetails.accommodation?.resortId);

          this.activeResortLogo = resort?.logo?.mainUrl;
          this.activeResortCaption = resort?.caption;
          this.activeResortPhone = resort?.phone;

          if (resort?.resortId && response.bookingDetails.arrivalDate && response.bookingDetails.departureDate) {
            htmlContent.setAvailable(resort?.resortId, response.bookingDetails.arrivalDate, response.bookingDetails.departureDate);
          }

          tracking.purchase(response.bookingDetails)
        }

        this.isInitialized = true;
      }),
      htmlContent.get(content => this.safetyProtocols = content, 'safety-protocols')
    )
  }
}
