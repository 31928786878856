import { Component } from '@angular/core';
import { SearchService } from '@shared/services/search/search.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { ComponentBase } from '@shared/base/component.base';
import { NavigationService } from '@shared/services/navigation.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { DataService } from '@shared/services/data.service';
import { RatePlanType, Resort, Room } from '@shared/api/be-api.generated';
import { MatSelectChange } from '@angular/material/select';
import { ComponentsCommunicationService } from '@shared/services/components-communication.service';
import { TenantService } from '@shared/services/tenant.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends ComponentBase {
  resort?: Resort;
  guestsCount = 0;
  resorts: Resort[] = [];

  availableRatePlanTypes?: RatePlanType[];

  currentRatePlanType?: RatePlanType;
  availableRatePlanType?: RatePlanType;
  isActiveRatePlanTypeSwitcher = false;

  resortLogoImageURL?: string;

  constructor(
    private readonly _componentsCommunication: ComponentsCommunicationService,
    private readonly _sidebar: SidebarService,
    private readonly _navigation: NavigationService,
    private readonly _search: SearchService,
    tenant: TenantService,
    services: ComponentBaseService,
    data: DataService
  ) {
    super(services);
    super.pushSub(
      data.setWhenInitialized(({ resorts }) => {
        this.resorts = resorts;
        this.availableRatePlanTypes = data.values.ratePlanTypes;
        // if (data.flightRatePlanType) {
        //   this.availableRatePlanTypes.push(data.flightRatePlanType);
        // }

        // if (data.noFlightRatePlanType) {
        //   this.availableRatePlanTypes.push(data.noFlightRatePlanType);
        // }
      }),
      _search.setWhenContextChanged(({ resort, ratePlanType }) => {
        this.resort = resort;
        this.resortLogoImageURL =
          // tenant.id === 'test' && (resort?.name || '').toLocaleLowerCase().indexOf('couples') > -1 ? '/assets/images/couples-logo.svg' :
          this.resort?.logo?.thumbnailUrl;

        this.guestsCount = _search.getTotalGuests();
        this.currentRatePlanType = ratePlanType;
        this.availableRatePlanType = this.availableRatePlanTypes?.find(item =>
          item.ratePlanTypeId !== this.currentRatePlanType?.ratePlanTypeId);
        this.isActiveRatePlanTypeSwitcher = false;
      })
    );
  }

  openProfileMenu() {
    this._sidebar.openAccount();
  }

  openGuestDetails() {
    if (this.isOnSearchPage) {
      this._sidebar.openHotelFilter();
    }
  }

  clickResortName() {
    this.executeTest(() => this._navigation.openSearch());
  }

  compareResort(r1: Resort, r2: Resort): boolean {
    return r1.resortId === r2.resortId;
  }

  resortChanged({ value }: MatSelectChange) {
    const rooms = (this._search.state.context.rooms || [])
      .map(item => new Room({ ...item, children: [] }))
    this._search.applyHotelFilter(value, rooms);
  }

  compareRatePlanType(r1: RatePlanType, r2: RatePlanType): boolean {
    return r1.ratePlanTypeId === r2.ratePlanTypeId;
  }

  ratePlanTypeChanged({ value }: MatSelectChange) {
    this.currentRatePlanType = value as RatePlanType;
    this.changeRatePlanType();
  }

  changeRatePlanType() {
    if (this.isActiveRatePlanTypeSwitcher) {
      this.isActiveRatePlanTypeSwitcher = false;
      this._componentsCommunication.flightsEnabled.emit(false);
    } else if (this.availableRatePlanType) {
      if (this.availableRatePlanType.flightsIncluded) {
        this.isActiveRatePlanTypeSwitcher = true;
        this._componentsCommunication.flightsEnabled.emit(true);
      } else {
        this._search.setAirports(undefined, undefined);
      }
    }
  }
}
