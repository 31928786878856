import Logger from 'js-logger';
import { Params } from '@angular/router';
import { createChecker } from '@shared/common';
import { DateTime } from 'luxon';
import { convertToDateTime, FlightSearch, IPackageRequest, PackageRequest } from '@shared/api/be-api.generated';

export function toQueryParams<T extends object>(input: T, keyBuilder?: (key: string) => string): Params {
  let params: Params = {};
  Object.keys(input)
    .forEach(inputKey => {
      const paramsKey = keyBuilder ? keyBuilder(inputKey) : inputKey;
      const value = input[inputKey as keyof T];
      const checker = createChecker({ input: value, hasAnyValue: true });

      if (checker.isArray()) {
        // this is not a mistake, need again to check isArray for ts to be happy
        if (Array.isArray(value) && value.length) {
          params[paramsKey] = JSON.stringify(value);
        }
      }
      else if (checker.isValue()) {
        if (value instanceof DateTime) {
          params[paramsKey] = value.toISODate();
        }
        else if (checker.isObject()) {
          if (value instanceof PackageRequest) {
            params = { ...params, ...toQueryParams(value as IPackageRequest) };
          } else if (value instanceof FlightSearch) {
            params = { ...params, ...toQueryParams(value, (key) => `flightSearch[${key}]`) };
          } else {
            params[paramsKey] = JSON.stringify(value);
          }
        } else {
          params[paramsKey] = value !== undefined ? value + '' : value;
        }
      }
    });

  return params;
}

export const createQueryParamsParser = (params: Params) => {
  const getObject = <T>(key: string) => {
    if (!params[key]) {
      return undefined;
    } else if (typeof params[key] !== 'string') {
      try {
        return params[key] as T
      } catch {
        return undefined
      }
    }

    try {
      return JSON.parse(params[key]) as T;
    } catch {
      Logger.info(`Failed to parse query params for ${key}`);
      return undefined;
    }
  }

  const getDate = (key: string) => params[key] ? convertToDateTime(params[key]) : undefined;
  const getNumber = (key: string) => Math.trunc(params[key]) >= 0 ? Math.trunc(params[key]) : undefined;
  const getNumberType = <T extends number>(key: string) => {
    const result = getNumber(key);
    return !Number.isNaN(Number(result)) ? result as T : undefined;
  }
  const getUppercased = (key: string) => params[key]?.toUpperCase();
  const getString = (key: string) => params[key];
  const getBoolean = (key: string) => getObject<boolean>(key);
  const getArray = <T>(key: string) => params[key] ? getObject<T[]>(key) : undefined;
  const getRecord = <T extends string | number, R>(key: string) => params[key] ? getObject<Record<T, R>>(key) : undefined;


  return {
    getDate,
    getNumber,
    getNumberType,
    getUppercased,
    getString,
    getBoolean,
    getRecord,
    getArray,
    getObject
  };
}
