import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AddOnPricingType as AddOnPricingResponseType } from '@shared/api/be-api.generated';

@Pipe({ name: 'addOnType' })
export class AddOnTypePipe implements PipeTransform {
  constructor(
    private readonly _transloco: TranslocoService,
  ) {
  }

  transform(type?: AddOnPricingResponseType) {
    return type !== undefined ? this._transloco.translate('addOn.type.' + type) : '';
  }
}
