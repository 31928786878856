import { FilterGroupItem } from '@search/components/shared/filter-group/filter-group.component';
import { itemsWithId } from '@shared/common';

export const flightFilterScopes = ['byPrice', 'byStops', 'byDepartTime', 'byArrivalTime', 'byCabinType', 'byAirline'] as const;
export type FlightFilterScope = typeof flightFilterScopes[number];
export type FlightFilterGroup = {
  scope: FlightFilterScope;
  items: FilterGroupItem[];
  isCheckbox?: boolean;
  selectedItem?: FilterGroupItem;
  selectedItems?: FilterGroupItem[];
};

export type FlightFilterSelectValueDecider = Partial<Record<
  FlightFilterScope, ((item: FilterGroupItem) => boolean | undefined) | undefined
>>;

export const asFilterItemsFromSet = (items: readonly (number | string)[]): FilterGroupItem[] =>
  itemsWithId(items.map(item => ({ captionKey: item.toString(), value: item.toString() })));

export const asFilterItems = (length: number): FilterGroupItem[] =>
  itemsWithId(Array.from({ length }).map((_, index) => ({
    captionKey: index.toString(),
    value: index.toString()
  })));
