import Logger from 'js-logger';
import { EventEmitter, Injectable } from '@angular/core';

import { gtagAppendOptions, gtmAppendOptions, heapAppendOptions, mccAppendOptions } from '@shared/consts/scripts';

import { DataService } from './data.service';
import { HTMLTagService } from './html-tag.service';
import { SsrHelperService } from './ssr-helper.service';

import { PurchaseItem, stringifiers, TrackingDataInput, TrackingEvent } from '@shared/models/tracking';
import { PricingService } from './pricing.service';
import { PackageService } from './package.service';
import { SearchService } from './search/search.service';
import { AddOnService } from './add-on.service';
import { AddOnToPurchaseViewModel } from '@shared/models/common';
import { environment } from 'src/environments/environment';
import { Booking } from '@shared/api/be-api.generated';

declare const gtag: Function;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let dataLayer: any;

@Injectable()
export class TrackingService {
  isInitialized = false;
  initialazed = new EventEmitter<TrackingService>();

  private readonly _trackingInstaller = this._installGoogleTag;
  private readonly _trackService = this._trackWithGoogleTag;
  private _isPurchasedTrackRequired = false;

  private readonly _inputDataProcessor = (data?: TrackingDataInput) =>
    data ? typeof data === 'function' ? data(stringifiers) : data : undefined;

  currency: 'USD' = 'USD';

  lastRoomData?: TrackingDataInput;
  lastFlightData?: TrackingDataInput;
  lastInboundAirportTransferData?: TrackingDataInput;
  lastOutboundAirportTrasnferData?: TrackingDataInput;

  lastAddonsPurchaseItems: PurchaseItem[] = [];
  lastAddonsViewModels: AddOnToPurchaseViewModel[] = [];

  get roomPurchaseItem(): PurchaseItem | undefined {
    return this._pricing.roomTotal
      ? {
        price: this._pricing.roomTotal,
        coupon: this._search.state.context.promoCode,
        discount: this._pricing.saved,
        item_name: this._package.roomType?.caption || 'Room',
        item_brand: this._data.values.settings.tradingName,
        item_variant: this._package.ratePlanType?.name,
        item_category: this._package.resort?.caption,
        item_category2: this._package.specialOffer?.name,
        quantity: 1
      }
      : undefined;
  }

  get roomData(): TrackingDataInput | undefined {
    return this.roomPurchaseItem
      ? { currency: this.currency, value: this._pricing.roomTotal, items: [this.roomPurchaseItem] }
      : undefined;
  }

  get flightPurchaseItem(): PurchaseItem | undefined {
    return this._pricing.flightTotal
      ? {
        price: this._pricing.flightTotal,
        quantity: 1,
        item_name: this._package.marketingAirlineNames,
        item_brand: 'Flight',
        item_category: this._search.state.context.arrivalAirport?.displayText,
        item_category2: this._search.state.context.departureAirport?.displayText
      }
      : undefined;
  }

  get flightData(): TrackingDataInput | undefined {
    return this.flightPurchaseItem
      ? { currency: this.currency, value: this._pricing.flightTotal, items: [this.flightPurchaseItem] }
      : undefined;
  }

  get inboundAirportTransferPurchaseItem(): PurchaseItem | undefined {
    const { name: item_name, price = 0 } = this._package.inboundAirportTransfer || {};
    const quantity = this._search.getTotalGuests();

    return item_name ? { price, item_name, quantity } : undefined;
  }

  get inboundAirportTransferData(): TrackingDataInput | undefined {
    return this.inboundAirportTransferPurchaseItem
      ? { currency: this.currency, value: this._pricing.inboundAirportTransferTotal, items: [this.inboundAirportTransferPurchaseItem] }
      : undefined;
  }

  get outboundAirportTransferPurchaseItem(): PurchaseItem | undefined {
    const { name: item_name, price = 0 } = this._package.outboundAirportTransfer || {};
    const quantity = this._search.getTotalGuests();

    return item_name ? { price, item_name, quantity } : undefined;
  }

  get outboundAirportTransferData(): TrackingDataInput | undefined {
    return this.outboundAirportTransferPurchaseItem
      ? { currency: this.currency, value: this._pricing.outboundAirportTransferTotal, items: [this.outboundAirportTransferPurchaseItem] }
      : undefined;
  }

  get addOnsPurchaseItems(): PurchaseItem[] {
    return this._addOns.toPurchaseViewModel
      .map(item => ({
        price: item.pricePerItem,
        item_name: item.name,
        quantity: item.quantity,
        item_category: item.categoryName,
        item_category2: item.from || item.to ? `from ${item.from || 'birth'} to ${item.to || 'unlimited'}` : undefined
      } as PurchaseItem));
  }

  constructor(
    private readonly _pricing: PricingService,
    private readonly _package: PackageService,
    private readonly _search: SearchService,
    private readonly _addOns: AddOnService,
    private readonly _data: DataService,
    private readonly _ssrHelper: SsrHelperService,
    private readonly _htmlTag: HTMLTagService
  ) {
  }

  beginCheckout = () => this.track('begin_checkout', this._getGullTrackingDataInput());

  markPurchaseTrackingAsRequired() {
    this._isPurchasedTrackRequired = true;
  }

  unmarkPurchaseTrackingAsRequired() {
    this._isPurchasedTrackRequired = false;
  }

  purchase({
    bookingNumber,
    travelAgentDetails,
    departureDate,
    bookingDate,
    arrivalDate,
    party,
    flights,
    payment,
    accommodation,
    pricing,
    guestDetails,
    promoCode
  }: Booking) {

    if (this._isPurchasedTrackRequired) {
      this.unmarkPurchaseTrackingAsRequired();
    } else {
      return;
    }

    this.track('purchase', this._getGullTrackingDataInput(bookingNumber));

    const affiliation = travelAgentDetails?.travelAgencyName
      ? `Travel Agent - ${travelAgentDetails.travelAgencyName}`
      : `${this._data.values.settings.tradingName} Booking Engine`;

    const inboundFlights = flights?.flightsToBuy?.itinerary?.inboundJourney?.flights;
    const flight = inboundFlights?.find(() => true);
    const mainGuest = guestDetails?.find(() => true);

    if (arrivalDate && bookingDate && departureDate) {

      this.trackWithDataLayer({
        'event': 'bookingComplete',
        'booking': {
          'to_date': departureDate,
          'bookingDate': bookingDate,
          'bookingSection': 'Confirmation',
          'transaction_id': bookingNumber,
          'currency': 'USD',
          'from_date': arrivalDate,
          'no_adults': party?.totalAdults,
          'airlineName': flight?.operatingAirlineName,
          'cabinType': this._data.values.cabinTypes.find(item =>
            item.code === flight?.cabinType)?.name,
          'name': accommodation?.resortName,
          'numberOfAdvancedDays': arrivalDate.diff(bookingDate, 'days').days || 0,
          'quantity': departureDate.diff(arrivalDate, 'days').days || 0,
          'no_nts': departureDate.diff(arrivalDate, 'days').days || 0,
          'no_of_stops': inboundFlights?.length ? inboundFlights.length - 1 : undefined,
          'origin_airport': this._data.values.airports.find(item =>
            item.code === flight?.departureAirportCode)?.displayText,
          'pageCategory': 'Conversion',
          'promoCode': promoCode,
          'rateCode': this._data.values.ratePlanTypes?.find(item =>
            item.ratePlanTypeId === accommodation?.ratePlanTypeId)?.name,
          'value': payment?.totalPrice,
          'affilation': affiliation,
          'roomId': accommodation?.roomTypeCode,
          'specialOffer': pricing?.specialOffers?.find(() => true)?.name || 'No Special Offer',
          'main_first_name': mainGuest?.firstName,
          'main_last_name': mainGuest?.lastName,
          'main_email': mainGuest?.email
        }
      });
    }

  }

  updateRoomType() {
    if (JSON.stringify(this.lastRoomData) === JSON.stringify(this.roomData)) {
      return;
    }

    if (this.lastRoomData) {
      this.track('remove_from_cart', this.lastRoomData);
    }

    this.lastRoomData = this.roomData;
    if (this.lastRoomData) {
      this.track('add_to_cart', this.lastRoomData);
    }
  }

  updateFlight() {
    if (JSON.stringify(this.lastFlightData) === JSON.stringify(this.flightData)) {
      return;
    }

    if (this.lastFlightData) {
      this.track('remove_from_cart', this.lastFlightData);
    }

    this.lastFlightData = this.flightData;
    if (this.lastFlightData) {
      this.track('add_to_cart', this.lastFlightData);
    }
  }

  updateInboundAirportTransfer() {
    if (JSON.stringify(this.lastInboundAirportTransferData) === JSON.stringify(this.inboundAirportTransferData)) {
      return;
    }

    if (this.lastInboundAirportTransferData) {
      this.track('remove_from_cart', this.lastInboundAirportTransferData);
    }

    this.lastInboundAirportTransferData = this.inboundAirportTransferData;
    if (this.lastInboundAirportTransferData) {
      this.track('add_to_cart', this.lastInboundAirportTransferData);
    }
  }

  updateOutboundAirportTransfer() {
    if (JSON.stringify(this.lastOutboundAirportTrasnferData) === JSON.stringify(this.outboundAirportTransferData)) {
      return;
    }

    if (this.lastOutboundAirportTrasnferData) {
      this.track('remove_from_cart', this.lastOutboundAirportTrasnferData);
    }

    this.lastOutboundAirportTrasnferData = this.outboundAirportTransferData;
    if (this.lastOutboundAirportTrasnferData) {
      this.track('add_to_cart', this.lastOutboundAirportTrasnferData);
    }
  }

  updateAddons() {
    const stringifier = (x: PurchaseItem) => x.price + x.item_name + x.item_category2 + x.quantity;
    const nextItems = this.addOnsPurchaseItems.map(stringifier)
    const previousItems = this.lastAddonsPurchaseItems.map(stringifier);

    const removeIndexes = previousItems
      .map((item, index) => !nextItems.includes(item) ? index : undefined)
      .filter(index => index !== undefined);

    const toRemove = this.lastAddonsPurchaseItems.filter((_, index) => removeIndexes.includes(index));
    if (toRemove.length) {
      this.track('remove_from_cart', {
        currency: 'USD',
        value: toRemove.reduce((total, item) => total + (item.price * item.quantity), 0),
        items: toRemove
      });
    }

    const addIndexes = nextItems
      .map((item, index) => !previousItems.includes(item) ? index : undefined)
      .filter(index => index !== undefined);

    const toAdd = this.addOnsPurchaseItems.filter((_, index) => addIndexes.includes(index));
    if (toAdd.length) {
      this.track('add_to_cart', {
        currency: 'USD',
        value: toAdd.reduce((total, item) => total + (item.price * item.quantity), 0),
        items: toAdd
      });
    }

    this.lastAddonsPurchaseItems = this.addOnsPurchaseItems;
  }

  track(action: TrackingEvent, data?: TrackingDataInput) {
    if (!this._ssrHelper.isBrowser || !environment.trackingEnabled) {
      return;
    }

    Logger.info(action, data);
    const actualData = this._inputDataProcessor(data);

    if (this.isInitialized) {
      this._trackService(action, actualData);
    } else {
      return this.initialazed
        .subscribe(() => this._trackService(action, actualData));
    }

    return undefined;
  }

  trackWithDataLayer(data: Record<string, unknown>) {
    if (!this._ssrHelper.isBrowser || !environment.trackingEnabled) {
      return;
    }

    Logger.info(data);
    try {
      dataLayer?.push && dataLayer.push(data);
    } catch (error) {
      Logger.error('dataLayer is not defined');
    }
  }

  private _getGullTrackingDataInput(transaction_id?: string): TrackingDataInput {
    return {
      currency: this.currency,
      transaction_id,
      value: this._pricing.total,
      items: [
        this.roomPurchaseItem,
        this.flightPurchaseItem,
        this.inboundAirportTransferPurchaseItem,
        this.outboundAirportTransferPurchaseItem,
        ...this.addOnsPurchaseItems
      ]
        .filter(item => item)
        .map((item, index) => ({ ...item, index })) as PurchaseItem[]
    };
  }

  setup() {
    if (environment.trackingEnabled) {
      const settings = this._data.values.settings;
      this._installHeap();
      this._trackingInstaller(settings.googleAnalyticsTrackingCode);
      this._installGTM(settings.googleTagManagerCode);
      this._installMicrosoftClarity(settings.microsoftClarityCode);
    }
  }

  private _initialize() {
    this.isInitialized = true;
    this.initialazed.emit(this);

    return this.isInitialized;
  }

  //#region Google Tag
  private _trackWithGoogleTag(action: TrackingEvent, data?: Record<string, unknown>) {
    if (typeof gtag === 'function' && gtag) {
      gtag('event', action, data);
    } else {
      Logger.error('Tracking impossible, gtag is not defined');
    }
  }

  private _installGoogleTag(googleAnalyticsTrackingCode?: string) {
    const appendOptions = gtagAppendOptions(googleAnalyticsTrackingCode);
    if (appendOptions) {
      const { load, install } = appendOptions;
      this._htmlTag.appendAndLoad(load)
        .then(() => {
          this._htmlTag.append(install);
          this._initialize();
        });
    }
  }

  private _installGTM(googleTagManagerCode?: string) {
    const appendOptions = gtmAppendOptions(googleTagManagerCode);
    if (appendOptions) {
      const { iframe, noscript, script } = appendOptions;
      this._htmlTag.append(script)
      this._htmlTag.append(noscript);
      setTimeout(() => this._htmlTag.append(iframe));
    }
  }
  //#endregion

  private _installMicrosoftClarity(microsoftClarityCode?: string) {
    const appendOptions = mccAppendOptions(microsoftClarityCode);
    if (appendOptions) {
      this._htmlTag.append(appendOptions.script);
    }
  }

  private _installHeap(heapCode?: string) {
    const appendOptions = heapAppendOptions(heapCode);
    if (appendOptions) {
      this._htmlTag.append(appendOptions.script);
    }
  }
}
