<ng-container *transloco="let t">
  <ng-template #inboundJourneyFormData
    let-text="text"
    let-select="select"
    let-datetime="datetime"
    let-autocomplete="autocomplete"
    let-journeyOptions="journeyOptions">
    <ng-container *ngTemplateOutlet="autocomplete; context: { field: 'arrivalAirport', options: journeyOptions.arrival }"></ng-container>
    <ng-container *ngTemplateOutlet="text; context: { field: 'flightNumber' }"></ng-container>
    <ng-container *ngTemplateOutlet="select; context: { field: 'airportTransfer', options: journeyOptions.transfer }"></ng-container>
  </ng-template>

  <ng-template #outboundJourneyFormData
    let-text="text"
    let-select="select"
    let-datetime="datetime"
    let-autocomplete="autocomplete"
    let-journeyOptions="journeyOptions">
    <ng-container *ngTemplateOutlet="autocomplete; context: { field: 'departureAirport', options: journeyOptions.departure }"></ng-container>
    <ng-container *ngTemplateOutlet="select; context: { field: 'airportTransfer', options: journeyOptions.transfer }"></ng-container>
  </ng-template>

  <div class="flight-info flex-column"
    [class.gap]="!isSkipped"
    *ngIf="isVisible">
    <div class="flight-info-skip"
      (click)="skipFlightInfoToggle()">
      <app-icon
        name="checked"
        size="default"
        [class.active]="isSkipped"
        [class.background-color-background]="isSkipped"
        class="checkbox-icon">
      </app-icon>
      {{t('flightInfo.skip')}}
      <app-anchor type="flightInfoButton"></app-anchor>
    </div>
    <div class="flex-column gap collapsable"
      [class.collapsed]="isSkipped">
      <div class="flight-info-inbound">
        <div class="section">
          <div class="section-header">
            <div class="section-header-text">
              {{t('flightInfo.section.inbound.header')}}
            </div>
          </div>
        </div>
        <app-form
          #inboundFC
          [form]="inForm"
          [scope]="scope"
          [anchorType]="anchorType"
          [anchorNumber]="anchorNumbers.inbound"
          (selectChanged)="selectChange($event, true)">
          <ng-template #content
            let-text="text"
            let-select="select"
            let-datetime="datetime"
            let-autocomplete="autocomplete">
            <ng-container *ngTemplateOutlet="inboundJourneyFormData;
              context: { journeyOptions: inbound.options, text, autocomplete, datetime, select }">
            </ng-container>
          </ng-template>
        </app-form>
      </div>
      <div class="flight-info-outbound">
        <div class="section">
          <div class="section-header">
            <div class="section-header-text">
              {{t('flightInfo.section.return.header')}}
            </div>
          </div>
        </div>
        <app-form
          #outboundFC
          [form]="outForm"
          [scope]="scope"
          [anchorType]="anchorType"
          [anchorNumber]="anchorNumbers.outbound"
          (selectChanged)="selectChange($event, false)">
          <ng-template #content
            let-text="text"
            let-select="select"
            let-datetime="datetime"
            let-autocomplete="autocomplete">
            <ng-container *ngTemplateOutlet="outboundJourneyFormData;
              context: { journeyOptions: outbound.options, text, autocomplete, datetime, select }">
            </ng-container>
          </ng-template>
        </app-form>
      </div>
    </div>
  </div>
</ng-container>