<ng-container *transloco="let t">
  <div class="cart-total">
    <div class="block-header">
      <div class="block-header-text">
        {{t('cart.header.total.' + page)}}
      </div>
      <div class="block-header-navigation price-final"
        [@cartPriceChange]="isAnimating">
        {{total | appCurrency}}
        <b *ngIf="isCouplesTenant && isTripProtection">
          &nbsp;*
        </b>
      </div>
    </div>
    <div class="block-subheader">
      <div class="block-subheader-text color-formControl">
        ({{t('cart.subheader.tax')}})
      </div>
      <div class="block-subheader-navigation price-saved"
        [@cartPriceChange]="isAnimating"
        *ngIf="saved > 1">
        {{t('cart.subheader.saved', { value: saved | appCurrency })}}
      </div>
    </div>
    <div class="block-subheader">
      <div class="block-subheader-text">
        {{t('cart.subheader.total')}}
      </div>
    </div>
  </div>
</ng-container>