<ng-template #dot>&nbsp;&bull;&nbsp;</ng-template>
<ng-container *transloco="let t">
  <div class="flight-journey"
    [class.mobile-view]="isOnlyMobileView"
    [class.bordered]="!!type && !isDetailsMode">
    <div class="flight-journey-content"
      *ngIf="!isDetailsMode">
      <div class="flight-journey-departure">
        <div class="flight-journey-airline-icon">
          <img class="icon-size-airline"
            loading="lazy"
            [src]="marketingAirlineIconSrc"
            [alt]="marketingAirlineName" />
        </div>
        <div class="flight-journey-departure-container">
          <div class="flight-journey-departure-time">
            {{departureTime | flightTime}}
          </div>
          <div class="flight-journey-departure-airport">
            {{departureAirport | airport: 'cityFull'}}
          </div>
          <div class="flight-journey-departure-date">
            {{departureDate | appDate: 'type2'}}
          </div>
        </div>
        <div class="flight-journey-arrow">
          <app-icon
            name="arrow"
            filled="textPrimary">
          </app-icon>
        </div>
      </div>
      <div class="flight-journey-arrival">
        <div class="flight-journey-arrival-container">
          <div class="flight-journey-arrival-time">
            {{arrivalTime | flightTime}}
          </div>
          <div class="flight-journey-arrival-airport">
            {{arrivalAirport | airport: 'cityFull'}}
          </div>
          <div class="flight-journey-arrival-date">
            {{arrivalDate | appDate: 'type2'}}
          </div>
        </div>
      </div>
      <div class="flight-journey-data">
        <div class="flight-journey-data-container">
          <div class="flight-journey-data-stops-count">
            <span
              [class.long]="isAnyLongStop"
              [matTooltip]="isAnyLongStop ? t('longLayover', {value: longLayoverHours }) : ''"
              matTooltipPosition="above">
              <ng-container *ngIf="stopsCount === 0">
                {{t('flight.nonStop', { count: stopsCount })}}
              </ng-container>
              <ng-container *ngIf="stopsCount === 1">
                {{t('flight.stop', { count: stopsCount })}}
              </ng-container>
              <ng-container *ngIf="stopsCount > 1">
                {{t('flight.stops', { count: stopsCount })}}
              </ng-container>
            </span>
            <div class="spacer">
              <ng-container *ngTemplateOutlet="dot"></ng-container>
            </div>
          </div>
          <div class="flight-journey-data-total-time">
            {{totalFlightTime | flightDuration}}
            <div class="spacer">
              <ng-container *ngTemplateOutlet="dot"></ng-container>
            </div>
          </div>
          <div class="flight-journey-data-airline">{{airlines}}</div>
        </div>
      </div>
    </div>
    <div class="flight-journey-details"
      *ngIf="type">
      <mat-accordion>
        <mat-expansion-panel
          hideToggle
          [expanded]="isOpenedDetails">
          <div class="flight-journey-details-item"
            *ngFor="let flight of flights; let i = index; trackBy: 'id' | createTrackBy">
            <ng-container *ngIf="isAllowedDetails">
              <div class="flight-header"
                *ngIf="i === 0 && !isDetailsMode">
                {{t('flight.' + type)}}
                <ng-container *ngTemplateOutlet="dot"></ng-container>
                {{flight.departureTime | appDate: 'type2'}}
              </div>
              <div class="flight-container">
                <div class="flight-layover"
                  *ngIf="i !== 0">
                  <div class="flight-airplane-icon"></div>
                  <div class="flight-layover-text"
                    [class.long]="(flight.layover?.hours || 0) > longLayoverHours">
                    {{t('flight.layover', { value: flight.layover | flightDuration })}} {{flight.departureAirportCode | airport: 'cityShort'}}
                  </div>
                  <div class="flight-airplane-icon visible-tablet"></div>
                </div>
                <div class="flight-departure">
                  <div class="flight-airplane-icon">
                    <app-icon
                      name="departure"
                      filled="iconFlight">
                    </app-icon>
                  </div>
                  <div class="flight-departure-info">
                    <div class="flight-departure-info-header">
                      {{flight.departureTime | flightTime}}
                      &nbsp;-&nbsp;
                      {{flight.departureAirportCode | airport: 'cityShort'}}
                    </div>
                    <div class="flight-departure-info-subheader">
                      {{flight.departureAirportCode | airport: 'nameFull'}}
                      <ng-container *ngIf="flight.flightNumber">
                        - {{t('flightSummary.flightNumber')}}:<strong>&nbsp;{{flight.flightNumber}}</strong>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="flight-description">
                  <div class="flight-airplane-icon"></div>
                  <div>
                    <ng-container *ngIf="flight.flightTime | flightDuration">
                      {{t('flight.duration', { time: flight.flightTime | flightDuration })}}
                      <br>
                    </ng-container>
                    {{flight.marketingAirlineName}}
                    <br>
                    {{flight.cabinType | cabinType}}
                  </div>
                </div>
                <div class="flight-arrival">
                  <div class="flight-airplane-icon">
                    <app-icon
                      name="arrival"
                      filled="iconFlight">
                    </app-icon>
                  </div>
                  <div class="flight-arrival-info">
                    <div class="flight-arrival-info-header">
                      {{flight.arrivalTime | flightTime}}
                      &nbsp;-&nbsp;
                      {{flight.arrivalAirportCode | airport: 'cityShort'}}
                    </div>
                    <div class="flight-arrival-info-subheader">
                      {{flight.arrivalAirportCode | airport: 'nameFull'}}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</ng-container>