import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AddOnResponse } from '@shared/api/be-api.generated';
import { maxCountAddonsToPurchase } from '@shared/consts/common';
import { AddOnAgeGroupQuantityChange, AddOnToPurchaseViewModel, AgeGroupViewModel } from '@shared/models/common';

@Component({
  selector: 'app-addon-age-groups-pricing',
  templateUrl: './addon-age-groups-pricing.component.html',
  styleUrls: ['./addon-age-groups-pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddonAgeGroupsPricingComponent implements OnChanges {
  @Input() currentAddOn!: AddOnResponse;
  @Input() addOnsToPurchase!: AddOnToPurchaseViewModel[];
  @Input() isSoldOut = false;

  @Output() countToPurchaseChanged = new EventEmitter<AddOnAgeGroupQuantityChange>();

  ageGroups: AgeGroupViewModel[] = [];
  maxCountToPurchase = maxCountAddonsToPurchase;

  ngOnChanges(): void {
    const newAgeGroups = (this.currentAddOn.pricingModel?.prices || [])
      .map(item => {
        const toPurchase = this.addOnsToPurchase.find(addOn => addOn.ageGroupId === item.id);

        return new AgeGroupViewModel({
          ...item,
          from: item.agePeriod?.fromYear || 0,
          to: item.agePeriod?.toYear || 0,
          quantity: toPurchase?.quantity || 0
        });
      });

    this.ageGroups.length !== newAgeGroups.length
      ? this.ageGroups = newAgeGroups // initial setup
      : this.ageGroups.forEach((ageGroup, index) => // update
        ageGroup.quantity = newAgeGroups[index].quantity);
  }

  increase(item: AgeGroupViewModel) {
    if (item?.id && item.quantity < this.maxCountToPurchase) {
      this.countToPurchaseChanged.emit({
        ageGroupId: item.id,
        quantity: item.quantity + 1
      });
    }
  }

  decrease(item: AgeGroupViewModel) {
    if (item?.id && item.quantity > 0) {
      this.countToPurchaseChanged.emit({
        ageGroupId: item.id,
        quantity: item.quantity - 1
      });
    }
  }
}
