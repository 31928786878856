import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatcher, UrlSegment } from '@angular/router';
import { LayoutComponent } from '@components/layout/layout.component';
import { NotFoundComponent } from '@shared/components/layout/not-found/not-found.component';
import { CanActivateTenantGuard } from '@shared/guards/can-activate-tenant.guard';
import { Page, pages } from '@shared/models/common';
import { environment } from 'src/environments/environment';
import { BookingLayoutComponent } from './booking/components/booking-layout/booking-layout.component';
import { ConfirmationLayoutComponent } from './confirmation/components/confirmation-layout/confirmation-layout.component';
import { SearchLayoutComponent } from './search/components/layout/search-layout/search-layout.component';

const matcher: UrlMatcher = (url: UrlSegment[]) =>
  (url?.length
    && environment?.tenants?.includes(url[0].path)
    && (url.length === 1 // if it's only /tenant it will be redirected to search
      || (url.length === 2 && [pages.results, pages.booking, pages['bypass-results']].includes(url[1].path as Page)
        || (url.length === 3 && [pages.confirmation].includes(url[1].path as Page)))))
    ? ({
      consumed: url.length === 1 ? url : url.slice(0, 1),
      posParams: { tenantId: new UrlSegment(url[0].path, {}) }
    })
    : null;

const routes: Routes = [
  {
    matcher,
    canActivate: [CanActivateTenantGuard],
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: pages.results,
        pathMatch: 'full'
      },
      {
        path: pages.results,
        component: SearchLayoutComponent,
        loadChildren: () => import('./search/search.module')
          .then(result => result.SearchModule)
      },
      {
        path: pages['bypass-results'],
        component: SearchLayoutComponent,
        loadChildren: () => import('./search/search.module')
          .then(result => result.SearchModule)
      },
      {
        path: pages.booking,
        component: BookingLayoutComponent,
        loadChildren: () => import('./booking/booking.module')
          .then(result => result.BookingModule)
      },
      {
        path: `${pages.confirmation}/:bookingId`,
        component: ConfirmationLayoutComponent,
        loadChildren: () => import('./confirmation/confirmation.module')
          .then(result => result.ConfirmationModule)
      }
    ]
  },
  {
    path: '**',
    canActivate: [CanActivateTenantGuard],
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
