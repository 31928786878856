import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { timeFormat } from '../formats/date-time';

@Pipe({ name: 'flightTime' })
export class FlightTimePipe implements PipeTransform {
  transform(value?: DateTime) {
    return value?.toFormat(timeFormat.default)?.toLocaleLowerCase() || '';
  }
}
