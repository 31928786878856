<ng-container *transloco="let t">
  <div class="confirmation-section">
    <div class="wraper">
      <div class="section-header"
        [class.margin]="hasHeaderMargin">
        <div class="section-header-text"
          *ngIf="!header">
          {{t([scope, 'section', 'header'] | join)}}
        </div>
        <ng-container *ngIf="header">
          <ng-container *ngTemplateOutlet="header; context: { scope }"></ng-container>
        </ng-container>
      </div>
      <div class="confirmation-section-content flex-column">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-container>
