import { AgeGroup, AgePeriod, AirportTransfer, GuestDetails } from '@shared/api/be-api.generated';
import { getCountChildrenInAgePeriod } from '@shared/common';
import { TranslocoInput } from '@shared/models/common';

export interface IChidrenErroMessageInput {
  childDetails: Record<number, GuestDetails>;
  ageGroups: AgeGroup[];
  children: number[];
  yearText: string;
  childrenInGroupText: string;
}

export const getChildrenErrorMessage = ({
  childDetails,
  ageGroups,
  children,
  yearText,
  childrenInGroupText
}: Partial<IChidrenErroMessageInput>): TranslocoInput => {

  childDetails = childDetails || {};
  children = children || [];

  const isAnyChild = !!Object.keys(childDetails).length
  const allowedAgePeriods = ageGroups?.map(ageGroup => ageGroup.agePeriod) || [];
  const enteredAges = Object.values(childDetails).map(child => child.age || 0);

  const createAgePeriodKey = (period?: AgePeriod) => [
    [period?.fromYear || 0, yearText].join(' '),
    [period?.toYear || '∞', yearText].join(' '),
  ].join(' - ');

  const enteredState = allowedAgePeriods
    .map(agePeriod => ({
      [createAgePeriodKey(agePeriod)]: getCountChildrenInAgePeriod(children, agePeriod)
    }))
    .reduce((state1, state2) => ({ ...state1, ...state2 }), {});

  const ageRanges = Object.keys(enteredState)
    .filter(key => enteredState[key])
    .map(key => [
      [' •', key].join(' '),
      [enteredState[key], childrenInGroupText].join(' ')]
      .join(' : '))
    .join(';<br> ');

  enteredAges.forEach(age =>
    allowedAgePeriods.forEach(period => {
      const isValidFrom = period?.fromYear === undefined || period.fromYear <= age;
      const isValidTo = period?.toYear === undefined || period.toYear >= age;

      if (isValidFrom && isValidTo) {
        const key = createAgePeriodKey(period);
        enteredState[key] = enteredState[key] - 1;
      }
    }));

  // this is checking children ages
  // if user selected 2 children in first group and 1 child in second group
  // then should all ages should be entered
  // if all 0 then for all age groups children are entered

  return {
    key: isAnyChild && !!Object.values(enteredState).find(item => item !== 0)
      ? 'error.incorrectChildAge'
      : undefined,
    params: {
      enteredAges: enteredAges.length
        ? enteredAges
          .map(age => [' •', age, yearText]
            .join(' '))
          .join(';<br> ')
        : 'None',
      ageRanges
    }
  }
}

export interface ITransferErrorMessage {
  airportTransfers: AirportTransfer[];
  isFlightSelected: boolean;
  isAirportTransferSelected: boolean;
}

export const getAirportTransferErrorMessage = ({
  airportTransfers,
  isAirportTransferSelected,
  isFlightSelected
}: ITransferErrorMessage): TranslocoInput => {
  return {
    key: isFlightSelected && !isAirportTransferSelected && !!airportTransfers?.length
      ? 'airport.transfer.error'
      : undefined
  };
}

export const getPricingErrorMessage = (total: number): TranslocoInput => {
  return {
    key: total <= 0 ? 'error.priceLessZero' : undefined,
  };
}
