<ng-container *transloco="let t">
  <app-anchor
    *ngIf="addOn"
    type="protectVacationError">
  </app-anchor>
  <div class="protect-vacation flex-column"
    [class.gap]="!toggler.isHidden"
    *ngIf="addOn">
    <div class="section-header">
      <div class="section-header-text">
        {{t('protectVacation.section.header')}}
      </div>
      <app-info-label
        [text]="t('protectVacation.encouraged')"
        type="successInfoLabel">
      </app-info-label>
      <app-section-toggler #toggler [hidden]="true"></app-section-toggler>
    </div>
    <div class="protect-vacation-data collapsable"
      [class.collapsed]="toggler.isHidden">
      <div class="protect-vacation-decider">
        <div class="label collapsable"
          [class.collapsed]="isShort.label"
          *ngIf="!isTouched || isYesSelected !== undefined; else error">
          <ng-container *ngIf="topLinks.length">
            <a *ngFor="let link of topLinks"
              class="color-selected"
              [href]="link"
              target="_blank">
              {{link}}
            </a>
            <br>
            <br>
          </ng-container>
          {{t('protectVacation.select.question')}}
          <b class="color-required">&nbsp;*</b>
        </div>
        <ng-template #error>
          <div class="label collapsable"
            [class.collapsed]="isShort.label">
            <mat-error class="error-message bold">
              <span [innerHtml]="t('protectVacation.select.required')"></span>
            </mat-error>
          </div>
        </ng-template>
        <div class="value"
          [class.gap]="!isShort.component">
          <div class="option-yes background-color-successBackground"
            [class.cursor-pointer]="!isYesSelected">
            <div class="option-yes-content">
              <div class="flex-between">
                <div class="text">
                  <app-icon
                    (click)="select(true)"
                    name="checked"
                    size="default"
                    [class.active]="isYesSelected"
                    [class.cursor-pointer]="!isShort.component"
                    [class.background-color-successBackground]="isYesSelected"
                    class="checkbox-icon">
                  </app-icon>
                  <span (click)="select(true)"
                    class="text-label"
                    [class.cursor-pointer]="!isShort.component">
                    {{t('protectVacation.select.yesOption')}}
                  </span>
                  <span [hidden]="!isShort.price"
                    class="link-underlined"
                    (click)="closeShortForm()">
                    {{t('change')}}
                  </span>
                </div>
                <div class="price"
                  *ngIf="addOn.pricingModel && !isShort.price">
                  <div class="price-final"
                    *ngIf="(addOn.pricingModel.price || 0) > 0">
                    {{addOn.pricingModel.price | appCurrency}}
                  </div>
                  <div class="price-type">
                    {{addOn.pricingModel.type | addOnType}}
                  </div>
                </div>
              </div>
              <div class="block-description protect-vacation-description"
                appTargetBlank
                [class.collapsed]="isShort.component"
                [class.color-successBackground]="isShort.component"
                [innerHtml]="addOn.description | safe: 'html'">
              </div>
            </div>
          </div>
          <div class="collapsable"
            [class.collapsed]="isShort.component">
            <div class="option-no background-color-background"
              [class.cursor-pointer]="isYesSelected !== false"
              (click)="select(false)">
              <div class="text">
                <app-icon
                  name="checked"
                  size="default"
                  [class.active]="isYesSelected === false"
                  [class.background-color-background]="isYesSelected === false"
                  class="checkbox-icon">
                </app-icon>
                {{t('protectVacation.select.noOption')}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>