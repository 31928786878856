import { Directive, HostListener, OnDestroy } from '@angular/core';
import { scrollToId } from '@shared/common';
import { AnchorType, pages } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from 'src/environments/environment';

@Directive()
export abstract class ComponentBase implements OnDestroy {
  page?: string;
  isOnSearchPage!: boolean;
  isOnBookingPage!: boolean;
  isOnConfirmationPage!: boolean;
  onDestroyHandler?: () => void;

  protected subscriptions: Subscription[] = [];

  get isBrowser() {
    return this.services.ssrHelper.isBrowser;
  }

  constructor(
    protected readonly services: ComponentBaseService
  ) {
    this._setPage();
  }

  @HostListener('window:popstate', ['$event'])
  protected _onPopState() {
    this._setPage();
  }

  private _setPage() {
    this.page = this.services.getCurrentPage();
    const { booking, confirmation, results } = pages;
    this.isOnSearchPage = this.services.isPage(results);
    this.isOnBookingPage = this.services.isPage(booking)
    this.isOnConfirmationPage = this.services.isPage(confirmation);
  }

  protected pushSub(...subs: (Subscription | undefined)[]) {
    this.subscriptions = [...this.subscriptions, ...subs.filter(sub => sub !== undefined) as Subscription[]];
  }

  protected getAnchorId(postfix: AnchorType, ...index: (number | string | undefined)[]) {
    return ['anchor', postfix, ...index]
      .filter(item => item !== null && item !== undefined && item !== '')
      .join('-');
  }

  protected scrollTo(postfix: AnchorType, ...index: (number | string | undefined)[]) {
    scrollToId({ anchorId: this.getAnchorId(postfix, ...index) });
  }

  protected scrollToWithDelay(delayMs: number, postfix: AnchorType, ...index: (number | string | undefined)[]) {
    setTimeout(() => this.scrollTo(postfix, ...index), delayMs);
  }

  protected executeTest(testAction: () => void, timeout = 2000) {
    if (environment.isTestAllowed && this.isBrowser && this.services.isTest) {
      return setTimeout(() => testAction(), timeout);
    }

    return undefined;
  }

  ngOnDestroy(): void {
    if (this.subscriptions?.length) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    if (this.onDestroyHandler) {
      this.onDestroyHandler();
    }
  }
}
