import { Component, ChangeDetectionStrategy, Input, TemplateRef, ContentChild } from '@angular/core';

@Component({
  selector: 'app-confirmation-section',
  templateUrl: './confirmation-section.component.html',
  styleUrls: ['./confirmation-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationSectionComponent {
  @Input() scope!: string;
  @Input() hasHeaderMargin = true;
  @ContentChild('header') header?: TemplateRef<unknown>;
}
