import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-notification-travel',
  templateUrl: './notification-travel.component.html',
  styleUrls: ['./notification-travel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationTravelComponent {
  @Input() safetyProtocols?: string;
}
