<div class="page-container"
  *ngIf="isInitialized">
  <div class="page-content page-narrow"
    [class.with-rate-plan-type-switcher]="header.availableRatePlanType">
    <app-header #header class="page-default"></app-header>
    <div class="under-header"></div>
    <!-- <div class="page-section text-center"
      *ngIf="activeResortLogo">
      <img [src]="activeResortLogo" [alt]="activeResortCaption" class="resort-logo">
    </div> -->

    <app-notification-booking
      [bookingId]="bookingId"
      [isBookingCancelled]="isBookingCancelled"
      [activeResortPhone]="activeResortPhone"
      class="page-section">
    </app-notification-booking>

    <ng-container *ngIf="bookingId">
      <app-notification-passenger
        *ngIf="!isBookingCancelled"
        class="page-section hide-if-empty">
      </app-notification-passenger>
      <app-notification-failed-payment-part
        class="page-section hide-if-empty"
        iconName="warning"
        backgroundColor="warningBackground"
        textColor="warningText"
        borderColor="warningBorder">
      </app-notification-failed-payment-part>
      <app-notification-travel
        class="page-section"
        [safetyProtocols]="safetyProtocols">
      </app-notification-travel>
      <app-booking-email class="page-section"></app-booking-email>
      <app-booking-summary></app-booking-summary>
      <app-room-summary></app-room-summary>
      <app-flight-summary class="hide-if-empty"></app-flight-summary>
      <app-addon-summary class="hide-if-empty"></app-addon-summary>
      <app-protect-vacation-summary class="hide-if-empty"></app-protect-vacation-summary>
      <app-payment-summary></app-payment-summary>
      <app-agreement-conditions></app-agreement-conditions>
      <app-need-help></app-need-help>
    </ng-container>
  </div>
  <app-footer></app-footer>
</div>
<app-loading-screen
  *ngIf="!isInitialized"
  section="confirmation">
</app-loading-screen>