import { Injectable } from '@angular/core';
import { HTMLTagService } from '@shared/services/html-tag.service';
import { StateMachineBootstrapperService } from '@shared/services/state/state-machine-bootstrapper.service';
import themeSM from './theme.sm';
import { join } from 'path';
import { existsSync, readFileSync } from 'fs';
import { assign } from 'xstate';
import { SsrHelperService } from '@shared/services/ssr-helper.service';
import { HtmlAppendOptions } from '@utils/state-transfer';
import { getClientRootFolder } from '@utils/ssr-helper';

const defaultThemeFile = 'default.css';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private readonly _themeMachine = this._smFactory.bootstrapMachine(themeSM.withConfig({
    actions: {
      setServerTheme: assign((_, e) => {
        const themeFile = e.type === 'CUSTOM_THEME_READY' ? e.themeCss : defaultThemeFile;
        this.appendStyle(this.getThemeLocationOnServer(themeFile));
        return { selectedTheme: themeFile };
      }),
      tryLoadServerTheme: (_, e) => {
        const themeFileExists = existsSync(this.getThemeLocationOnServer(e.themeCss));
        this._themeMachine.send(themeFileExists
          ? { type: 'CUSTOM_THEME_READY', themeCss: e.themeCss }
          : { type: 'THEME_NOT_FOUND' });
      },
      tryLoadBrowserTheme: async (_, e) => {
        try {
          await this._htmlTag.appendAndLoad(this.getThemeAppendOptions(e.themeCss))
          this._themeMachine.send({ type: 'CUSTOM_THEME_READY', themeCss: e.themeCss });
        } catch {
          await this._htmlTag.appendAndLoad(this.getThemeAppendOptions(defaultThemeFile));
          this._themeMachine.send({ type: 'THEME_NOT_FOUND' });
        }
      }
    }
  }));

  constructor(
    private readonly _htmlTag: HTMLTagService,
    private readonly _smFactory: StateMachineBootstrapperService,
    private readonly _ssrHelper: SsrHelperService,
  ) {
  }

  setThemeForTenant(tenantId: string) {
    if (this._themeMachine.state.done) {
      return;
    }

    const themeFile = `${tenantId}.css`;
    this._themeMachine.send(this._ssrHelper.isServer
      ? { type: 'APPLY_SERVER_THEME', themeCss: themeFile }
      : { type: 'APPLY_BROWSER_THEME', themeCss: themeFile });
  }

  private getThemeLocationOnServer(themeFile: string) {
    return join(process.cwd(), getClientRootFolder(), themeFile);
  }

  private appendStyle(styleFile: string) {
    const cssContent = readFileSync(styleFile, 'utf8');
    this._htmlTag.append({
      element: 'style',
      allowInSSR: true,
      cssContent
    });
  }

  private getThemeAppendOptions(themeFile: string): HtmlAppendOptions {
    return {
      attributes: {
        rel: 'stylesheet',
        href: themeFile,
      },
      element: 'link',
      parentQuery: 'head',
      allowInSSR: true
    };
  }
}
