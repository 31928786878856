<div class="fixed-buttons">
  <button mat-raised-button
    class="text-style-selected"
    *ngFor="let section of sections"
    (click)="scrollToSection(section)">
    {{section}}
  </button>

  <app-icon-button
    *ngIf="isSelectedPackage"
    type="raised"
    name="cart"
    alt="cart.open"
    buttonSize="default"
    iconSize="small"
    [transparent]="false"
    buttonClass="select-button active"
    (click)="openCart()"
    stroked="default">
  </app-icon-button>
</div>