import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslocoRootModule } from '../transloco-root.module';
import { SharedModule } from '@shared/shared.module';

import { OverlayModule } from '@angular/cdk/overlay';
import { MatSidenavModule } from '@angular/material/sidenav';

import { AppComponent } from './app/app.component';
import { LayoutComponent } from './layout/layout.component';
import { AccountModule } from '../account/account.module';

@NgModule({
  declarations: [
    LayoutComponent,
    AppComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    TranslocoRootModule,

    OverlayModule,
    MatSidenavModule,

    AccountModule
  ]
})
export class ComponentsModule { }
