import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { DateTime } from 'luxon';
import { DateRange, MatDateRangeInput, MatDateRangePicker } from '@angular/material/datepicker';

import { includes } from '@shared/common';
import { Airport, RatePlanType } from '@shared/api/be-api.generated';
import { SearchService } from '@shared/services/search/search.service';
import { DataService } from '@shared/services/data.service';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { ComponentsCommunicationService } from '@shared/services/components-communication.service';
import { airportsVisibilityChange } from '@shared/consts/animations';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  animations: [airportsVisibilityChange]
})
export class SearchBarComponent extends ComponentBase {
  dateRange!: UntypedFormGroup;
  departureAirports: Airport[] = [];
  arrivalAirports: Airport[] = [];
  departureAirport?: Airport;
  arrivalAirport?: Airport;

  initFrom?: DateTime;
  initTo?: DateTime;
  ratePlanType?: RatePlanType;

  isVisibleAirports = false;
  isAnimateDatePicker = false;
  resortId?: string;

  constructor(
    private readonly _search: SearchService,
    private readonly _data: DataService,
    componentsCommunication: ComponentsCommunicationService,
    services: ComponentBaseService
  ) {
    super(services);
    super.pushSub(
      this._search.setWhenContextChanged(({ fromDate, toDate, departureAirport, arrivalAirport, resort, ratePlanType }) => {
        this.initFrom = fromDate;
        this.initTo = toDate;
        this.dateRange = new UntypedFormGroup({
          start: new UntypedFormControl(fromDate),
          end: new UntypedFormControl(toDate)
        });
        this.arrivalAirports = resort?.airports || [];
        this.departureAirport = departureAirport;
        this.arrivalAirport = arrivalAirport;

        if (this._data.flightRatePlanType) {
          const arriavalAirportsCodes = this.arrivalAirports.map(airport => airport.code);
          this.departureAirports = this._data.values.airports
            .filter(airport => !includes(airport.code, ...arriavalAirportsCodes));
        }

        this.ratePlanType = ratePlanType;
        this.isVisibleAirports = false;
        this.resortId = resort?.resortId;
      }),
      componentsCommunication.flightsEnabled
        .subscribe(isEnabled => {
          this.isVisibleAirports = isEnabled
          this.isAnimateDatePicker = true;
          setTimeout(() => this.isAnimateDatePicker = false, 500);
        })
    );
  }

  onAirportSelect(airport?: Airport, isDepartureAirport?: boolean) {
    if (airport) {
      const departureAirport = (isDepartureAirport ? airport : this.departureAirport);
      const arrivalAirport = (isDepartureAirport ? this.arrivalAirport : airport)
        || this.arrivalAirports?.find(() => true);

      this._search.setAirports(departureAirport, arrivalAirport);
    } else {
      this._search.setAirports();
    }
  }

  replaceAirports() {
    this._search.swapAirports();
  }

  closedDateRangePicker(picker: MatDateRangePicker<DateTime>) {
    const { value } = picker.datepickerInput as MatDateRangeInput<DateTime>;
    const { start, end } = value || new DateRange(null, null);
    if (start && end) {
      if (!this.initFrom || !this.initTo || start !== this.initFrom || end !== this.initTo) {
        this._search.setDateRange(start, end);
      }
    } else {
      picker.open();
    }
  }
}
