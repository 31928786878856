<ng-container *transloco="let t">
  <div class="flight-details"
    [class.border-selected]="isSelected"
    [class.border]="!isSelected">
    <app-anchor [type]="anchorType" [index]="flightId"></app-anchor>
    <div class="flight-details-header">
      <div class="airline">
        {{airlines}}
      </div>
      <div class="cabin-type">
        {{cabinTypes}}
      </div>
    </div>
    <div class="flight-details-content">
      <app-flight-journey
        type="departure"
        [journey]="inboundJourney"
        [isOpenedDetails]="isOpenedDetails">
      </app-flight-journey>
      <app-flight-journey
        type="returning"
        [journey]="outboundJourney"
        [isOpenedDetails]="isOpenedDetails">
      </app-flight-journey>
    </div>
    <div class="flight-details-footer link-underlined"
      (click)="toggleDetails()">
      {{t('flight.' + (isOpenedDetails ? 'hide' : 'review'))}}
    </div>
    <ng-content></ng-content>
  </div>
</ng-container>
