// TODO: Localize when we really go this way
import { DateTime, Duration } from 'luxon';

export const dateTimeFormat = {
  full: 'EEEE, dd, MMMM, yyyy, hh:mm a'
};

export const dateFormat = {
  type1: 'yyyy-MM-dd',
  type2: 'EEE MMM dd',
  type3: 'EEE, dd, MMM, yyyy',
  type4: 'dd MMMM yyyy',
  type5: 'MMMM dd, yyyy'
};

export type DateFormatOption = keyof typeof dateFormat;

export const timeFormat = {
  default: 'hh:mm a'
};

export const luxonCalendarSettings = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: dateFormat.type3,
    monthYearLabel: 'yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'yyyy'
  }
};

export const dateTimeStringify = {
  toISO: (date: DateTime, time: Date) =>
    `${date.toISODate()}T${time.toTimeString().split(':').slice(0, -1).join(':')}`
}

export const formatDateTime = (datetime: DateTime | undefined, format: string) =>
  datetime?.toFormat(format);

export const durationStringify = (duration?: Duration) =>
  duration?.toHuman({ unitDisplay: 'short' })
    .split(', ')
    .filter(x => !x.startsWith('0'))
    .join(' ')
  || '';
