import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { AddOnResponse } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { AddOnService } from '@shared/services/add-on.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { PackageService } from '@shared/services/package.service';
import { SearchService } from '@shared/services/search/search.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';

@Component({
  selector: 'app-addon-select',
  templateUrl: './addon-select.component.html',
  styleUrls: ['./addon-select.component.scss']
})
export class AddonSelectComponent extends ComponentBase implements AfterViewInit {
  @ViewChild('options') options!: CdkVirtualScrollViewport;
  @ViewChild('addOnFilter') addOnFilter!: TemplateRef<unknown>;

  addOns: AddOnResponse[] = [];
  isHidden = false;
  itemSize = 300;
  minBufferPx!: number;
  maxBufferPx!: number;
  purchasedCount = 0;

  orderNumber!: string;

  totalResortAddOns!: number;

  constructor(
    private readonly _addon: AddOnService,
    private readonly _sidebar: SidebarService,
    _package: PackageService,
    search: SearchService,
    services: ComponentBaseService
  ) {
    super(services);
    this._setBufferSize();

    this.pushSub(
      this._addon.setWhenDataChanged(({ currentAddOns, toPurchase, resortAddOns }) => {
        this.addOns = currentAddOns;
        this.purchasedCount = toPurchase.length;
        this.totalResortAddOns = resortAddOns.length;
      }),
      _package.setWhenDataChanged(() =>
        this.orderNumber = search.isFlightSearchAllowed ?
          !_package.airportTransfers.length
            ? '3'
            : '4'
          : '2')
    );
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      setTimeout(() => {
        const options = this.options?.elementRef?.nativeElement as HTMLElement;
        const option = options?.querySelector('app-select-option');
        if (option) {
          this.itemSize = option.clientWidth;
          this._setBufferSize();
        }
      });
    }
  }

  openFilter() {
    this._sidebar.open({
      template: this.addOnFilter,
      position: 'end',
      width: 'default'
    });
  }

  toggleIsHidden() {
    this.isHidden = !this.isHidden;
  }

  openPurchased() {
    this._addon.showPurchased = true;
    this._addon.setAddOns();
    this.toggleIsHidden();
  }

  private _setBufferSize() {
    this.minBufferPx = this.itemSize * 3;
    this.maxBufferPx = this.itemSize * 4;
  }
}
