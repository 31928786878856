<ng-container *transloco="let t">
  <div class="pricing-details">
    <app-package-pricing
      *ngIf="currentRoomType && ratePlanType"
      [currentRoomType]="currentRoomType"
      [priceFormat]="priceFormat"
      [currentPackage]="currentPackage"
      [isActive]="isActive"
      [ratePlanType]="ratePlanType"
      [periodNights]="periodNights"
      [roomsCount]="roomsCount"
      [guestsCount]="guestsCount"
      [size]="size"
      [showWhenUnavailable]="showWhenUnavailable">
    </app-package-pricing>
    <app-addon-pricing
      *ngIf="currentAddOn"
      [unavailabilityReason]="unavailabilityReason"
      [currentAddOn]="currentAddOn"
      [addOnsToPurchase]="addOnsToPurchase"
      [isSelectedPackage]="isSelectedPackage"
      [isSoldOut]="isSoldOut"
      (countToPurchaseChanged)="updateCountToPurchase($event)"
      (ageGroupsSidebarChanged)="showDetails()">
    </app-addon-pricing>
    <app-airport-transfer-pricing
      *ngIf="currentAirportTransfer"
      [currentAirportTransfer]="currentAirportTransfer"
      [inboundAirportTransfer]="inboundAirportTransfer"
      [outboundAirportTransfer]="outboundAirportTransfer"
      [isSelectedPackage]="isSelectedPackage"
      [isSoldOut]="isSoldOut">
    </app-airport-transfer-pricing>
    <div class="pricing-details-button">
      <app-select-button
        [showTextClass]="showTextClass"
        [text]="text"
        [size]="size"
        [isActive]="isActive"
        [isBlockedWhenActive]="!!currentPackage"
        [disabled]="disabled"
        (click)="onSelectClick()">
      </app-select-button>
    </div>
  </div>
</ng-container>