import { Component, ChangeDetectionStrategy, OnChanges, Input } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { AnchorType } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';

@Component({
  selector: 'app-anchor',
  templateUrl: './anchor.component.html',
  styleUrls: ['./anchor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnchorComponent extends ComponentBase implements OnChanges {
  @Input() type!: AnchorType;
  @Input() index?: (number | string)[] | number | string;

  anchorId!: string;

  constructor(
    services: ComponentBaseService
  ) {
    super(services);
  }

  ngOnChanges(): void {
    this.anchorId = Array.isArray(this.index)
      ? this.getAnchorId(this.type, ...this.index)
      : this.getAnchorId(this.type, this.index);

  }
}
