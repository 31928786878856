<ng-container *transloco="let t">
  <div class="protect-vacation-summary flex"
    *ngIf="addOn">
    <div class="content-container">
      <app-confirmation-section
        [hasHeaderMargin]="false"
        #section
        scope="protectVacationSummary">
        <app-confirmation-section-record
          #record
          labelWidth="30%"
          [isDivider]="{ top: true }"
          position="center">
          <div [ngClass]="[record.htmlClasses.label, 'custom-html-content']"
            [style.min-width]="record.labelWidth"
            [innerHtml]="t([section.scope, 'adults'] | join, { value: addOn.quantity})">
          </div>
          <div [ngClass]="[record.htmlClasses.value, 'custom-html-content']"
            appTargetBlank
            [innerHtml]="addOn.description | safe: 'html'">
          </div>
        </app-confirmation-section-record>
      </app-confirmation-section>
    </div>
  </div>
</ng-container>