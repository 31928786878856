<ng-container *transloco="let t">
  <div class="travel-agent">
    <div class="travel-agent-header">
      {{t('travelAgent.caption')}}
    </div>
    <div class="travel-agent-content">
      <app-form
        #form
        (nextChanged)="tryApply($event)"
        [form]="travelAgentForm"
        scope="travelAgent">
        <ng-template #content let-text="text">
          <div [ngClass]="'travel-agency' + field"
            *ngFor="let field of form.fields">
            <ng-container *ngTemplateOutlet="text; context: { field }"></ng-container>
          </div>
        </ng-template>
      </app-form>
    </div>
    <div class="travel-agent-footer">
      <button mat-raised-button
        (click)="clearForm()">
        {{t('clearForm')}}
      </button>
      <button mat-raised-button
        color="primary"
        (click)="apply()">
        {{t('submit')}}
      </button>
    </div>
  </div>
</ng-container>
