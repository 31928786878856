import { filter, from, ReplaySubject, shareReplay } from 'rxjs';
import { EventObject, Interpreter, State, StateSchema, Typestate } from 'xstate';

export class StateMachineContainer<TContext, TStateSchema extends StateSchema<TContext>, TEvent extends EventObject, TTypestate extends Typestate<TContext>, TResolvedTypesMeta> {
  private _subject = new ReplaySubject<State<TContext, TEvent, TStateSchema, TTypestate, TResolvedTypesMeta>>(1);
  readonly state$ = this._subject.pipe(shareReplay(1));

  constructor(
    private readonly _interpreter: Interpreter<TContext, TStateSchema, TEvent, TTypestate, TResolvedTypesMeta>,
    isRestored: boolean
  ) {
    from(this._interpreter)
      .pipe(filter(state => state.changed || false))
      .subscribe(x => this._subject.next(x));
    if (isRestored) {
      this._subject.next(this._interpreter.state);
    }
  }

  get state() {
    return this._interpreter.state;
  }

  send(...eventOrEvents: TEvent[]) {
    return this._interpreter.send(eventOrEvents);
  }
}
