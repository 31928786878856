import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';
import { AddOnResponse, AirportTransfer, Package, RatePlanType, RoomType } from '@shared/api/be-api.generated';
import { AnimationComponentBase } from '@shared/base/animation-component.base';
import { createCarouselImages } from '@shared/common';
import { airportTransferChange } from '@shared/consts/animations';
import { AddOnToPurchaseViewModel, AddonType, CarouselImage, PriceFormat, UnavailabilityReason, WithId } from '@shared/models/common';
import { AddOnService } from '@shared/services/add-on.service';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { PackageService } from '@shared/services/package.service';
import { TenantService } from '@shared/services/tenant.service';
import { FormatPricePipe } from 'src/app/pipes/format-price.pipe';

@Component({
  selector: 'app-pricing-details',
  templateUrl: './pricing-details.component.html',
  styleUrls: ['./pricing-details.component.scss'],
  animations: [airportTransferChange]
})
export class PricingDetailsComponent extends AnimationComponentBase implements OnChanges {
  //#region  common
  @Input() size: 'big' | 'default' = 'default';
  @Input() showTextClass = '';
  @Input() priceFormat?: PriceFormat;
  @Output() selectClick = new EventEmitter();
  @Output() openDetails = new EventEmitter();

  type!: 'package' | 'addOn' | 'airportTransfer' | 'undefined';
  disabled!: boolean;
  text!: string;
  isActive = false;
  countToPurchase = 1;
  isSoldOut = false;
  images: WithId<CarouselImage>[] = [];
  //#endregion

  //#region package
  @Input() currentPackage?: WithId<Package>;
  @Input() currentRoomType?: RoomType;
  @Input() showWhenUnavailable = false;

  ratePlanType?: RatePlanType;

  periodNights!: number;
  guestsCount!: number;
  roomsCount!: number;

  isSelectedPackage = true;
  //#endregion

  //#region addOn
  @Input() currentAddOn?: AddOnResponse;
  addOnsToPurchase: AddOnToPurchaseViewModel[] = [];
  isPerAgeGroupTypeAddon?: boolean;
  //#endregion

  //#region airportTransfer
  @ViewChild('airportTransfer') airportTransfer!: TemplateRef<unknown>;
  @Input() currentAirportTransfer?: AirportTransfer;
  inboundAirportTransfer?: AirportTransfer;
  outboundAirportTransfer?: AirportTransfer;
  //#endregion

  unavailabilityReason?: UnavailabilityReason = this._package.unavailabilityReason;

  constructor(
    private readonly _tenant: TenantService,
    private readonly _addon: AddOnService,
    private readonly _package: PackageService,
    private readonly _formatPrice: FormatPricePipe,
    services: ComponentBaseService
  ) {
    super(services);
    super.pushSub(
      _package.setWhenDataChanged(() => {
        this._setupPackages();
        const isPackageSelectedChanged = this.isSelectedPackage !== !!this._package.selectedPackage;

        if (isPackageSelectedChanged) {
          this._setupCommon();

          if (this.currentAddOn) {
            this._setupAddOns();
          }

          if (this.currentAirportTransfer) {
            this._setupAirportTransfers();
          }
        }
        else if (this.inboundAirportTransfer?.id !== _package.inboundAirportTransfer?.id
          || this.outboundAirportTransfer?.id !== _package.outboundAirportTransfer?.id) {
          this._setupCommon();
          this._setupAirportTransfers();
        }

      }, false),
      _addon.setWhenDataChanged(() => this._setupAddOns())
    );
  }

  ngOnChanges(): void {
    this._setupCommon();

    switch (this.type) {
      case 'package': this._setupPackages(); break;
      case 'addOn': this._setupAddOns(); break;
      case 'airportTransfer': this._setupAirportTransfers(); break;
    }
  }

  //#region setup
  private _setupCommon() {
    this.type = this.currentRoomType ?
      'package'
      : this.currentAddOn
        ? 'addOn'
        : this.currentAirportTransfer
          ? 'airportTransfer'
          : 'undefined';

    const isSelectedPackage = !!this._package.selectedPackage;
    if (isSelectedPackage !== this.isSelectedPackage) {
      this.isSelectedPackage = isSelectedPackage;
    }

    const reason = this._package.unavailabilityReason;
    if (reason?.code !== this.unavailabilityReason?.code || reason?.value !== this.unavailabilityReason?.value) {
      this.unavailabilityReason = reason;
    }
  }

  private _setupPackages() {
    if (!this.currentRoomType) {
      return;
    }

    const ratePlanType = this._package.ratePlanType;
    if (ratePlanType?.ratePlanTypeId !== this.ratePlanType?.ratePlanTypeId) {
      this.ratePlanType = ratePlanType;
    }

    const periodNights = this._package.periodNights;
    if (periodNights !== this.periodNights) {
      this.periodNights = periodNights;
    }

    const guestsCount = this._package.guestsCount;
    if (guestsCount !== this.guestsCount) {
      this.guestsCount = guestsCount;
    }

    const roomsCount = this._package.roomsCount;
    if (roomsCount !== this.roomsCount) {
      this.roomsCount = roomsCount;
    }

    const currentPackage = this.currentPackage;
    const isActive = this._package.isActiveRoomType(this.currentRoomType, currentPackage);

    if (isActive !== this.isActive) {
      this.isActive = isActive;
    }

    const disabled = !currentPackage
    if (disabled !== this.disabled) {
      this.disabled = !currentPackage;
    }


    const text = this._formatPrice.transform(
      currentPackage?.finalPrice,
      this.periodNights,
      this._package.guestsCount,
      this._package.roomsCount,
      this._tenant.buttonPriceFormat,
      'full'
    );

    if (text !== this.text) {
      this.text = text;
    }
  }

  private _setupAddOns() {
    if (!this.currentAddOn) {
      return;
    }

    const addOns = this._addon.getToPurchaseViewModels(this.currentAddOn);
    const isAnyChanged = (addOns?.length || 0) !== this.addOnsToPurchase.length
      || JSON.stringify(addOns) !== JSON.stringify(this.addOnsToPurchase);

    if (isAnyChanged) {
      this.addOnsToPurchase = addOns;
    }

    const countToPurchase = this.addOnsToPurchase.map(item => item.quantity || 0)
      .reduce((a1, a2) => a1 + a2, 0)
      || this.countToPurchase;
    if (countToPurchase !== this.countToPurchase) {
      this.countToPurchase = countToPurchase;
    }

    const { price, prices } = this.currentAddOn?.pricingModel || {};
    const isSoldOut = !this.isSelectedPackage || (price === undefined && !prices?.length);
    if (isSoldOut !== this.isSoldOut) {
      this.isSoldOut = isSoldOut;
    }

    const images = createCarouselImages(this.currentAddOn);
    if (this.images !== images) {
      this.images = images;
    }

    const isActive = !!this.addOnsToPurchase.length && this.isSelectedPackage;
    if (isActive !== this.isActive) {
      this.isActive = isActive;
    }

    const disabled = this.isSoldOut;
    if (disabled !== this.disabled) {
      this.disabled = disabled;
    }

    if (this.text !== '') {
      this.text = '';
    }

    const isPerAgeGroupTypeAddon = this.currentAddOn?.pricingModel?.type === AddonType.PerAgeGroup;
    if (this.isPerAgeGroupTypeAddon !== isPerAgeGroupTypeAddon) {
      this.isPerAgeGroupTypeAddon = isPerAgeGroupTypeAddon;
    }
  }

  private _setupAirportTransfers() {
    if (!this.currentAirportTransfer) {
      return;
    }

    const isInboundAirportTransferChanged = this.inboundAirportTransfer?.id !== this._package.inboundAirportTransfer?.id;
    if (isInboundAirportTransferChanged) {
      this.inboundAirportTransfer = this._package.inboundAirportTransfer;
    }

    const isOutboundAirportTransferChanged = this.outboundAirportTransfer?.id !== this._package.outboundAirportTransfer?.id;
    if (isOutboundAirportTransferChanged) {
      this.outboundAirportTransfer = this._package.outboundAirportTransfer;
    }

    const isActive = this.inboundAirportTransfer?.id === this.currentAirportTransfer?.id
      || this.outboundAirportTransfer?.id === this.currentAirportTransfer?.id;
    if (this.isActive !== isActive) {
      this.isActive = isActive;
    }

    const isSoldOut = !this.isSelectedPackage;
    if (isSoldOut !== this.isSoldOut) {
      this.isSoldOut = isSoldOut;
    }

    const disabled = this.isSoldOut;
    if (disabled !== this.disabled) {
      this.disabled = disabled;
    }
  }
  //#endregion

  //#region select
  onSelectClick() {
    if (!this.disabled) {
      switch (this.type) {
        case 'package': this.selectPackage(); break;
        case 'addOn': this.selectAddOn(); break;
        case 'airportTransfer': this.showDetails(); break;
      }

      this.selectClick.emit();
    }
  }

  showDetails() {
    this.openDetails.emit();
  }

  selectPackage() {
    if (this.currentRoomType && this.currentPackage) {
      if (this.currentRoomType.roomTypeId === this._package.roomType?.roomTypeId) {
        this._package.selectPackage(this.currentPackage);
      } else {
        this._package.selectRoomType(this.currentRoomType);
      }
    }
  }

  selectAddOn() {
    if (this.addOnsToPurchase.length && this.currentAddOn) {
      this._addon.cancelPurchase(this.currentAddOn);
      this.countToPurchase = 1;
    } else if (this.isPerAgeGroupTypeAddon) {
      this.showDetails();
    } else {
      this.purchaseAddOn();
    }
  }

  purchaseAddOn() {
    if (!this.isSoldOut && this.currentAddOn) {
      this._addon.purchase(this.currentAddOn, this.countToPurchase, undefined);
    }
  }

  updateCountToPurchase(newValue: number) {
    this.countToPurchase = newValue;
    this.purchaseAddOn();
  }
  //#endregion
}
