import { IAirport } from '@shared/api/be-api.generated';

export class AirportSettings {
  isActive = false;

  constructor(
    public current?: IAirport | undefined
  ) { }

  activate() {
    this.isActive = true;
  }

  inactivate() {
    this.isActive = false;
  }

  clear() {
    this.current = { ...this.current, displayText: '' };
  }
}
