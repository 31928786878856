import { EventEmitter, Injectable } from '@angular/core';
import { createCarouselImages, itemsWithId, unique, withId } from '@shared/common';
import { CarouselImage, PackagesSortOptions, PackagesSortType, pages, SearchQueryParams, UnavailabilityReason, UnavailabilityReasonCode, WithId } from '@shared/models/common';
import { SearchService } from './search/search.service';

import { AirportTransfer, RatePlanType, Resort, RoomType, RoomTypeUnavailabilityReason, SpecialOffer } from '@shared/api/be-api.generated';
import { FlightResponse, Package, RoomTypePackages, SearchContext } from '@shared/api/be-api.generated';
import { SearchEventsType } from './search/search-context.types';
import { QueryParamsService } from './query-params.service';
import { perPageFlights } from '@shared/consts/common';
import { DataService } from './data.service';
import { ComponentBaseService } from './component-base.service';

// order is important
const allRoomBuildTypes = ['roomType', 'images', 'specialOffers', 'packages', 'selectPackage', 'flight', 'roomOptions', 'airportTransfers', 'airportTransfer'] as const;
type RoomBuildType = typeof allRoomBuildTypes[number];

@Injectable({ providedIn: 'root' })
export class PackageService {
  //#region Props
  readonly defaultFlightId = '0';
  flights?: FlightResponse[];
  flight?: FlightResponse;

  ratePlanType?: RatePlanType;
  resort?: Resort;
  roomType?: RoomType;
  roomTypes: RoomType[] = [];
  roomTypesPackages?: RoomTypePackages[];
  roomTypePackages?: RoomTypePackages;
  specialOffers?: SpecialOffer[];
  specialOffer?: SpecialOffer;
  packages?: WithId<Package>[];
  selectedPackage?: WithId<Package>;

  images: WithId<CarouselImage>[] = [];
  periodNights = 0;
  guestsCount = 0;
  roomsCount = 0;

  roomTypesPackagesOptions: WithId<{ roomType: RoomType, package?: WithId<Package> }>[] = [];

  totalFlights = 0;
  recommendedPackage?: WithId<Package>;
  lastEventType?: SearchEventsType;

  inboundAirportTransfer?: AirportTransfer;
  outboundAirportTransfer?: AirportTransfer;
  airportTransfers: AirportTransfer[] = [];

  dataChanged = new EventEmitter();

  isFlightSelected!: boolean;
  isAirportTransferSelected!: boolean;

  isVisibleJamaicaPassengerNotification = true;

  unavailabilityReason?: UnavailabilityReason;
  //#endregion

  //#region Sort
  sortType?: PackagesSortType;
  sortOptions: PackagesSortOptions = {
    'asc': ({ finalPrice: p1 = 0, id: id1 }, { finalPrice: p2 = 0, id: id2 }) =>
      p1 === p2
        ? +id1 > +id2 ? 1 : -1
        : p1 > p2
          ? 1 : -1,
    'desc': ({ finalPrice: p1 = 0, id: id1 }, { finalPrice: p2 = 0, id: id2 }) =>
      p1 === p2
        ? +id1 > +id2 ? -1 : 1
        : p1 > p2
          ? -1 : 1
  };
  //#endregion

  isInitialized = false;
  initialized = new EventEmitter<boolean>(this.isInitialized);

  get marketingAirlineNames() {
    const inboundFlights = this.flight?.itinerary?.inboundJourney?.flights || [];
    const outboundFlights = this.flight?.itinerary?.inboundJourney?.flights || [];
    return unique(
      [...inboundFlights, ...outboundFlights]
        .map(({ marketingAirlineName }) => marketingAirlineName)
        .filter(marketingAirlineName => marketingAirlineName))
      .join(', ');
  }

  constructor(
    private readonly _data: DataService,
    private readonly _search: SearchService,
    private readonly _queryParams: QueryParamsService,
    private readonly _services: ComponentBaseService
  ) {
    this._search.context$
      .subscribe(context => this._processSearchResult(context));
  }

  //#region Init
  get isLastEventLoadMore() {
    return this.lastEventType === 'APPEND_SEARCH_RESULT';
  }

  get isLastPackagesPrices() {
    return this.lastEventType === 'SET_PACKAGES_PRICES_RESULT';
  }

  get queryParams() {
    return this._queryParams.value;
  }

  private _processSearchResult(context: SearchContext) {
    const eventType = this._search.state.event.type;
    const historyEventType = this._search.state.history?.event.type;

    this.lastEventType = this.resort === undefined
      ? 'SET_SEARCH_RESULT' // it's restoring process and first event should be set_search_result
      : !eventType.startsWith('done.')
        ? eventType
        : historyEventType;

    this.totalFlights = context?.totalFlights || 0;
    this.sortType = this.queryParams.sortType;

    switch (this.lastEventType) {
      case 'APPEND_SEARCH_RESULT':
        this._processLoadMore(context);
        if (!this._search.initialFlightId) {
          this._buildViewModels('roomType', 'specialOffers', 'packages', 'roomOptions');
        }

        this._restoreLoadPrices();// restore load more/prices #3
        break;

      case 'SET_PACKAGES_PRICES_RESULT':
        this._processResponse(context);
        this._buildViewModels();
        this._setIsInitialized();
        break;

      default:
        this.selectedPackage = undefined;
        this._processRequestData(context);
        this._processResponse(context);
        this._buildViewModels();

        this._restoreLoadMore(); // restore load more/prices #2

        break;
    }
  }
  //#endregion

  //#region Process
  private _processRequestData({ ratePlanType, resort, fromDate, toDate, rooms, roomTypesPackages = [] }: SearchContext) {
    this.resort = resort;
    this.ratePlanType = ratePlanType;

    const roomTypesWithPackages = roomTypesPackages
      .filter(item => item.specialOffers?.length || item.packages?.length);

    const roomTypeWithPackages = roomTypesWithPackages.find(({ roomTypeId }) => roomTypeId === this.queryParams.roomTypeId)
      || roomTypesWithPackages.find(() => true);

    this.roomType = resort?.roomTypes?.find(({ roomTypeId }) => roomTypeId === (roomTypeWithPackages?.roomTypeId || this.roomType?.roomTypeId))
      || resort?.roomTypes?.find(() => true);

    this.periodNights = fromDate && toDate ? Math.round(toDate.diff(fromDate, ['days']).days) : 0;
    this.guestsCount = this._search.getTotalGuests();
    this.roomsCount = rooms?.length || 1;
  }

  private _processResponse({ flights, roomTypesPackages }: SearchContext) {
    this.flights = flights || [];
    this._setRoomTypesOrder(roomTypesPackages);
  }

  private _setRoomTypesOrder(roomTypesPackages?: RoomTypePackages[]) {
    const getFirstPackagePrice = (roomType: RoomType) => {
      const { packages, specialOffers } = (roomTypesPackages || [])
        .find(({ roomTypeId }) => roomType.roomTypeId === roomTypeId) || {};

      return packages?.find(() => true)?.finalPrice || specialOffers?.find(() => true)?.packages?.find(() => true)?.finalPrice;
    }

    let hasPackages: RoomType[] = [];
    const unavailable: RoomType[] = [];

    (this.resort?.roomTypes || [])
      .forEach(roomType => getFirstPackagePrice(roomType)
        ? hasPackages.push(roomType)
        : unavailable.push(roomType));

    hasPackages = hasPackages
      .sort((r1, r2) => (getFirstPackagePrice(r1) || 0) - (getFirstPackagePrice(r2) || 0))

    const hasPackagesWithOrder: RoomType[] = hasPackages
      .filter(roomType => roomType.orderNumber)
      .sort((r1, r2) => (r1.orderNumber || 0) > (r2.orderNumber || 0) ? 1 : -1);

    const hasPackagesWithoutOrder: RoomType[] = hasPackages
      .filter(roomType => !roomType.orderNumber)

    const unavailableWithOrder: RoomType[] = unavailable
      .filter(roomType => roomType.orderNumber)
      .sort((r1, r2) => (r1.orderNumber || 0) > (r2.orderNumber || 0) ? 1 : -1);

    const unavailableWithoutOrder: RoomType[] = unavailable
      .filter(roomType => !roomType.orderNumber)


    this.roomTypes = [...hasPackagesWithOrder, ...hasPackagesWithoutOrder, ...unavailableWithOrder, ...unavailableWithoutOrder];

    this.roomTypesPackages = [];
    if (roomTypesPackages) {
      this.roomTypes.forEach(({ roomTypeId }) => {
        const data = roomTypesPackages.find(item => item.roomTypeId === roomTypeId);
        if (data) {
          this.roomTypesPackages?.push(data);
        }
      });
    }
  }

  private _processLoadMore({ flights, roomTypesPackages }: SearchContext) {
    this.flights = flights || this.flights;

    roomTypesPackages?.forEach(rtp => {
      const roomTypePackages = this.roomTypesPackages?.find(item => item.roomTypeId === rtp.roomTypeId);
      if (roomTypePackages) {
        roomTypePackages.specialOffers = rtp.specialOffers;
        roomTypePackages.packages = rtp.packages;
      }
    });
  }
  //#endregion

  //#region Build
  private _buildViewModels(...buildTypes: RoomBuildType[]) {
    const qpUpdate: Partial<SearchQueryParams> = {};
    const queryParamsFlightId = this.queryParams.flightId || this.defaultFlightId;
    const arrivalAirportCode = this._search.state.context.arrivalAirport?.code;
    let { inboundAirportTransferId, outboundAirportTransferId } = this.queryParams;

    (buildTypes.length ? buildTypes : allRoomBuildTypes).forEach(buildType => {
      switch (buildType) {
        case 'roomType':
          this.roomTypePackages = this.roomTypesPackages?.find(({ roomTypeId }) => roomTypeId == this.roomType?.roomTypeId);
          this.unavailabilityReason = this.toUnavailabilityReason(this.roomTypePackages?.unavailabilityReason)
          break;
        case 'images':
          this.images = createCarouselImages(this.roomType);
          break;
        case 'specialOffers':
          this.specialOffers = this.roomTypePackages?.specialOffers;

          let specialOfferId = this.queryParams.specialOfferId || this.specialOffer?.specialOfferId;
          this.specialOffer = this.specialOffers?.find(so => so.specialOfferId === specialOfferId)
            || this.specialOffers?.find(() => true);

          specialOfferId = this.specialOffer?.specialOfferId;
          if (specialOfferId && specialOfferId !== this.queryParams.specialOfferId) {
            qpUpdate.specialOfferId = this.specialOffer?.specialOfferId;
          }
          break;
        case 'packages':
          this.packages = itemsWithId(this.specialOffer?.packages || this.roomTypePackages?.packages);
          this.packages.forEach(item => item.flight = this.getFlight(item));
          this.recommendedPackage = this._getRecommendedPackage();
          this._sortPackages();
          break;
        case 'selectPackage':
          this.selectedPackage = this.packages?.find(p => p.flightId === queryParamsFlightId)
            || this.packages?.find(() => true);
          break;
        case 'flight':
          this.flight = this.flights?.find(flight => flight.id === this.selectedPackage?.flightId);
          this.isFlightSelected = !!this.flight;

          if ((this.selectedPackage?.flightId || this.defaultFlightId) !== queryParamsFlightId) {
            qpUpdate.flightId = this.selectedPackage?.flightId;
          }
          break;
        case 'roomOptions':
          this.roomTypesPackagesOptions = this._getRoomTypesPackagesOptions();
          break;
        case 'airportTransfers':
          this.airportTransfers = arrivalAirportCode
            ? this.resort?.airportTransfers?.filter(item =>
              !item.airportsCodes?.length
              || item.airportsCodes?.includes(arrivalAirportCode))
            || []
            : [];

          // pre-select free transfer on change resorts
          this.inboundAirportTransfer = undefined;
          this.outboundAirportTransfer = undefined;

          const freeTransfer = this._services.isPage(pages.results)
            ? this.airportTransfers.find(item => !item.price)
            : undefined;
          qpUpdate.inboundAirportTransferId = this.getAirportTransferById(inboundAirportTransferId, arrivalAirportCode)?.id
            || freeTransfer?.id;
          qpUpdate.outboundAirportTransferId = this.getAirportTransferById(outboundAirportTransferId, arrivalAirportCode)?.id
            || freeTransfer?.id;
          inboundAirportTransferId = qpUpdate.inboundAirportTransferId;
          outboundAirportTransferId = qpUpdate.outboundAirportTransferId;
          break;
        case 'airportTransfer':
          this.inboundAirportTransfer = this.getAirportTransferById(inboundAirportTransferId, arrivalAirportCode);
          if (!this.inboundAirportTransfer) {
            qpUpdate.inboundAirportTransferId = undefined;
          }
          this.outboundAirportTransfer = this.getAirportTransferById(outboundAirportTransferId, arrivalAirportCode);
          if (!this.outboundAirportTransfer) {
            qpUpdate.outboundAirportTransferId = undefined;
          }

          this.isAirportTransferSelected = !!this.inboundAirportTransfer || !!this.outboundAirportTransfer;
          break;
      }
    });

    if (Object.keys(qpUpdate).length) {
      this._queryParams.patchQueryParams(qpUpdate);
    }

    this.dataChanged.emit();
  }
  //#endregion

  //#region Select
  selectRoomType(roomType: RoomType) {
    this.roomType = roomType;
    this._buildViewModels();
    this._queryParams.patchQueryParams({ roomTypeId: this.roomType.roomTypeId });
  }

  selectSpecialOffer(specialOffer: SpecialOffer) {
    this.specialOffer = specialOffer;
    this._buildViewModels('packages', 'selectPackage', 'flight', 'roomOptions');
    this._queryParams.patchQueryParams({ specialOfferId: this.specialOffer.specialOfferId });
  }

  selectPackage(value?: WithId<Package>) {
    this.selectedPackage = value;
    this._buildViewModels('flight', 'roomOptions');

    if (this.isLastEventLoadMore && this.isFlightOutOfFirstPage()) {
      this._search.loadPackagesPrices(this.roomType?.roomTypeId);
    }
  }

  selectInboundAirportTransfer(value?: AirportTransfer) {
    this._queryParams.patchQueryParams({ inboundAirportTransferId: value?.id });
    this._buildViewModels('airportTransfer');
  }

  selectOutboundAirportTransfer(value?: AirportTransfer) {
    this._queryParams.patchQueryParams({ outboundAirportTransferId: value?.id });
    this._buildViewModels('airportTransfer');
  }

  // will not save data in query string
  useInboundAirportTransfer(value?: AirportTransfer) {
    this.inboundAirportTransfer = value;
    this.dataChanged.emit();
  }

  // will not save data in query string
  useOutboundAirportTransfer(value?: AirportTransfer) {
    this.outboundAirportTransfer = value;
    this.dataChanged.emit();
  }
  //#endregion

  //#region Get
  getUnavailabilityReason(roomTypeId?: string) {
    return roomTypeId
      ? this.toUnavailabilityReason(this.roomTypesPackages?.find(item => item.roomTypeId === roomTypeId)?.unavailabilityReason)
      : this.unavailabilityReason
  }

  toUnavailabilityReason(data?: RoomTypeUnavailabilityReason): UnavailabilityReason | undefined {
    return data ? { code: data.code as UnavailabilityReasonCode, value: data.minimumStay } : undefined;
  }

  getAirportTransferById(id?: string, arrivalAirportCode?: string) {
    return id && arrivalAirportCode
      ? this.airportTransfers.find(item => item.id === id
        && (!item.airportsCodes?.length
          || item.airportsCodes?.includes(arrivalAirportCode)))
      : undefined;
  }

  isFlightOutOfFirstPage() {
    const index = this.flights?.findIndex(({ id }) => this.flight?.id === id);
    return index !== undefined && index > perPageFlights;
  }

  isActiveRoomType = (roomType?: RoomType, currentPackage?: WithId<Package>) =>
    !!this.roomType?.roomTypeId && this.roomType?.roomTypeId === roomType?.roomTypeId
    && !!this.selectedPackage?.id
    && (!currentPackage || this.selectedPackage?.id === currentPackage?.id);

  isActiveFlight = (flight?: FlightResponse) => !!flight && this.flight?.id === flight.id;
  getFlight = (idOrPackage: string | Package) => this._getFlightById(typeof idOrPackage === 'string' ? idOrPackage : idOrPackage.flightId);
  private _getFlightById = (flightId?: string) => flightId ? this.flights?.find(item => item.id === flightId) : undefined;

  private _getRoomTypesPackagesOptions = () => itemsWithId(
    this.roomTypes.map(roomType => {
      let bestOrSelectedPackage: Package | WithId<Package> | undefined;

      if (roomType.roomTypeId === this.roomType?.roomTypeId) {
        bestOrSelectedPackage = this.selectedPackage;
      } else {
        const roomTypePackage = this.roomTypesPackages?.find(item => item.roomTypeId === roomType.roomTypeId);
        const specialOffer = roomTypePackage?.specialOffers?.find(so => this.specialOffer?.specialOfferId === so.specialOfferId)
          || roomTypePackage?.specialOffers?.find(() => true)
        const allPackages = specialOffer?.packages || roomTypePackage?.packages || [];
        bestOrSelectedPackage = allPackages.find(p => p.flightId === this.flight?.id) || allPackages.find(() => true);
      }

      return {
        roomType,
        package: bestOrSelectedPackage ? withId(bestOrSelectedPackage, 0) : bestOrSelectedPackage,
      };
    }));

  get availableResorts(): Resort[] {
    return this._search.isFlightSearchAllowed
      ? this._data.values.resorts.filter(({ airports }) => airports?.length)
      : this._data.values.resorts;
  }

  private _getRecommendedFlightId() {
    const allStopovers = this.flights?.map(({ itinerary: { inboundJourney, outboundJourney } = {} }) =>
      ((inboundJourney?.stopovers?.length || 0) + (outboundJourney?.stopovers?.length || 0))) || [-1];

    const index = allStopovers.findIndex(value => value === Math.min(...allStopovers));

    return index > -1 && this.flights ? this.flights[index].id : undefined;
  }

  private _getRecommendedPackage() {
    const recommendedFlightId = this._getRecommendedFlightId();
    return this.packages?.find(p => p.flightId === recommendedFlightId);
  }
  //#endregion

  //#region Sorting
  private _sortPackages(sortType?: PackagesSortType) {
    if (sortType) {
      const sortCompareFunc = this.sortOptions[sortType];
      this.packages = sortCompareFunc ? this.packages?.sort(sortCompareFunc) : this.packages;
    }

    if (this._queryParams.value.sortType !== sortType) {
      this._queryParams.patchQueryParams({ sortType });
    }
  }

  sortPackagesByPrice(sortType?: PackagesSortType) {
    this.sortType = sortType;

    if (sortType) {
      this._sortPackages(sortType);
      this.dataChanged.emit();
    } else {
      this._buildViewModels('flight', 'roomOptions');
    }
  }
  //#endregion

  //#region Restore
  private _restoreLoadMore() {
    if (this._search.initialFlightId
      && this._search.initialFlightId !== this.flight?.id
      && this.flights?.length
      && this.roomType
    ) {
      this._search.loadMore(this.roomType);
    } else {
      this._setIsInitialized();
    }
  }

  private _restoreLoadPrices() {
    if (this._search.initialFlightId) {
      this._queryParams.patchQueryParams({ flightId: this._search.initialFlightId });
      this._buildViewModels('roomType', 'specialOffers', 'packages', 'selectPackage', 'flight', 'roomOptions');

      if (this.isFlightOutOfFirstPage()) {
        this._search.loadPackagesPrices(this.roomType?.roomTypeId);
      }

      this._search.initialFlightId = undefined;
    } else {
      this._setIsInitialized();
    }
  }

  private _setIsInitialized() {
    if (!this.isInitialized) {
      this.isInitialized = true;
      this.initialized.emit(this.isInitialized);
    }
  }
  //#endregion

  setWhenDataChanged(onDataChanged: (data: PackageService) => void, useWithFirstInit = true) {
    if (this.isInitialized && useWithFirstInit) {
      onDataChanged(this);
    }

    return this.dataChanged.subscribe(() => onDataChanged(this))
  }
}
