<ng-container *transloco="let t">
  <div class="select-option box-shadow-default"
    [class.border-selected]="isActive"
    [class.border]="!isActive">
    <div class="select-option-photos">
      <app-gallery
        [imagePopup]="isRoomOption"
        [images]="images">
      </app-gallery>
    </div>
    <div class="select-option-description">
      <div class="select-option-description-header">
        <div class="select-option-description-header-text"
          [class.color-border]="isDisabled">
          {{caption}}
        </div>
        <div class="select-option-description-header-icon bs-right">
          <app-icon-button
            (click)="openDetails()"
            alt="{{optionType}}.info"
            name="info"
            filled="selected">
          </app-icon-button>
        </div>
      </div>
      <div class="select-option-description-details custom-html-content"
        appTargetBlank
        [innerHTML]="description | safe: 'html'">
      </div>
      <div class="link"
        (click)="openDetails()">
        {{t('moreDetails')}}
      </div>
    </div>
    <div class="select-option-footer">
      <app-pricing-details
        #pricingDetails
        size="default"
        showTextClass="hidden"
        (openDetails)="openDetails()"
        [priceFormat]="priceFormat"
        [currentAddOn]="currentAddOn"
        [currentRoomType]="currentRoomType"
        [currentAirportTransfer]="currentAirportTransfer"
        [currentPackage]="currentPackage">
      </app-pricing-details>
    </div>
  </div>
</ng-container>

<ng-template #packageDetails>
  <app-sidebar-layout>
    <ng-template #content>
      <app-package-details
        *ngIf="currentRoomType"
        [currentRoomType]="currentRoomType"
        [currentPackage]="currentPackage">
      </app-package-details>
    </ng-template>
    <ng-template #footer>
      <app-pricing-details
        size="big"
        [currentRoomType]="currentRoomType"
        [currentPackage]="currentPackage">
      </app-pricing-details>
    </ng-template>
  </app-sidebar-layout>
</ng-template>

<ng-template #airportTransferDetails>
  <app-airport-transfer-details
    [currentAirportTransfer]="currentAirportTransfer"
    [images]="images">
  </app-airport-transfer-details>
</ng-template>

<ng-template #addOnDetails>
  <app-addon-details
    *ngIf="pricingDetails"
    [currentAddOn]="currentAddOn"
    [images]="images"
    [isPerAgeGroupTypeAddon]="pricingDetails.isPerAgeGroupTypeAddon"
    [addOnsToPurchase]="pricingDetails.addOnsToPurchase"
    [disabled]="pricingDetails.disabled"
    [isActive]="pricingDetails.isActive"
    [isSoldOut]="pricingDetails.isSoldOut"
    [isSelectedPackage]="pricingDetails.isSelectedPackage"
    [unavailabilityReason]="pricingDetails.unavailabilityReason"
    [countToPurchase]="pricingDetails.countToPurchase">
  </app-addon-details>
</ng-template>