
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="./address.d.ts" />
import { core, usAutocompletePro, internationalAddressAutocomplete } from 'smartystreets-javascript-sdk';
import { environment } from 'src/environments/environment';
import { SelectOption } from './common';

//#region Types
export type AddressSelectOption = SelectOption & {
  usSuggestion?: UsAutocompleteSuggestion,
  inSuggestion?: InAutocompleteSuggestion
}

export type CountrySelectOption = SelectOption & {
  threeLetterCode?: string;
}

export interface AddressSearchInput {
  query: string;
  country: string;
  selected: AddressSelectOption;
}
//#endregion

//#region Common Helpers
export const credentials = new core.SharedCredentials(environment.smartyApiKey || process?.env['SMARTY_API_KEY'] || '');

export const buildLabel = {
  us: (suggestion: UsAutocompleteSuggestion, entriesPostfix?: string) => {
    let entries = '';

    if (suggestion.secondary && suggestion.entries > 1) {
      entries = entriesPostfix ? `(${suggestion.entries} ${entriesPostfix})` : `(${suggestion.entries})`;
    }

    return [
      suggestion.streetLine,
      suggestion.secondary,
      entries,
      suggestion.city,
      suggestion.state,
      suggestion.zipcode
    ]
      .filter(part => part)
      .join(' ');
  },
  in: (suggestion: InAutocompleteSuggestion) => {
    return [
      suggestion.street,
      suggestion.locality,
      suggestion.administrativeArea,
      suggestion.postalCode
    ]
      .filter(item => item)
      .join(' ');
  }
}

export const toAddressOption = (suggestion: UsAutocompleteSuggestion | InAutocompleteSuggestion, entriesPostfix?: string): AddressSelectOption => {
  const isUSA = !!(suggestion as UsAutocompleteSuggestion).zipcode;
  // for international it's postalCode

  if (isUSA) {
    const usSuggestion = suggestion as UsAutocompleteSuggestion;
    const label = buildLabel.us(usSuggestion, entriesPostfix);
    return { value: label, label, usSuggestion };
  }
  else {
    const inSuggestion = suggestion as InAutocompleteSuggestion;
    const label = buildLabel.in(inSuggestion);
    return { value: label, label, inSuggestion };
  }
}
//#endregion

//#region USA
export class UsAutocompleteSuggestion extends usAutocompletePro.Suggestion { }
export class UsAutocompleteLookup extends usAutocompletePro.Lookup {
  constructor(search: string, selected?: string) {
    super(search);
    if (selected) {
      this.selected = selected;
    }
  }
}

export const usClient = core.buildClient.usAutocompletePro(credentials);
//#endregion

//#region International
export class InAutocompleteSuggestion extends internationalAddressAutocomplete.Suggestion { }
export class InAutocompleteLookup extends internationalAddressAutocomplete.Lookup {
  constructor(search: string, country: string) {
    super(search, country);
    this.maxResults = 10;
  }
}

export const inClient = new core
  .ClientBuilder(credentials)
  .buildInternationalAddressAutocompleteClient();
//#endregion
