<ng-container *transloco="let t">
  <div class="special-offer"
    [class.is-notes-expanded]="isNotesExpanded"
    [class.is-selected]="isSelected">
    <div class="special-offer-container">
      <div class="special-offer-text">
        <span class="special-offer-text-value">{{name}}</span>
        <!-- <span *ngIf="currentSpecialOffer?.notes && isVisibleNotes">*</span> -->
        <app-info-label
          [text]="t('nonRefundable')"
          type="highlighted"
          *ngIf="isNonRefundable">
        </app-info-label>
      </div>

      <div class="special-offer-notes-container flex-nowrap"
        *ngIf="isVisibleNotes && notes"
        [hidden]="isNotesExpanded">
        <div class="special-offer-notes minimized"
          #notesContainer>
          {{notes}}
        </div>
        <div class="special-offer-notes-toggler link"
          (click)="toggleIsExpandedNotes($event)">
          {{t('moreDetails')}}
        </div>
      </div>
    </div>
    <div class="special-offer-price"
      *ngIf="isVisiblePrice">
      <div class="special-offer-price-final">
        <div class="price">{{ finalPrice }}</div>
        <div class="format">{{ finalPriceFormat }}</div>
      </div>
      <div class="special-offer-price-base">
        <div class="price">{{ basePrice }}</div>
        <div class="format">{{ basePriceFormat }}</div>
      </div>
    </div>
  </div>
  <div class="special-offer-notes-container flex-wrap"
    *ngIf="isNotesExpanded">
    <div class="special-offer-notes">
      {{notes}}
    </div>
    <div class="special-offer-notes-toggler link"
      (click)="toggleIsExpandedNotes($event)">
      {{t('readLess')}}
    </div>
  </div>
</ng-container>