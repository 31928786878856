<ng-container *transloco="let t">
  <div class="addon-age-groups-pricing">
    <div class="flex-column">
      <div class="addon-age-groups-item flex-between gap"
        *ngFor="let item of ageGroups">
        <div class="flex-start gap">
          {{t('ageGroupRange', { from: item.from, to: item.to })}}
          <div class="bold">
            {{item.price | appCurrency}}
          </div>
        </div>
        <div>
          <app-icon-button
            alt="decrease"
            name="minus"
            filled="default"
            [customButtonSize]="true"
            [disabled]="item.quantity <= 0 || isSoldOut"
            [stroked]="(item.quantity > 0 && !isSoldOut) ? 'selected': 'border'"
            (click)="decrease(item)">
          </app-icon-button>
          <span class="price-counter-text">{{item.quantity}}</span>
          <app-icon-button
            alt="increase"
            name="plus"
            filled="default"
            [customButtonSize]="true"
            [disabled]="item.quantity >= maxCountToPurchase || isSoldOut"
            [stroked]="(item.quantity < maxCountToPurchase && !isSoldOut) ? 'selected': 'border'"
            (click)="increase(item)">
          </app-icon-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>