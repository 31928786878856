<ng-container *transloco="let t">
  <div class="notification-warning"
    *ngIf="htmlContent">
    <div class="main-container">
      <app-notification
        [isThreeDots]="false"
        backgroundColor="default"
        borderWidth="thick"
        borderColor="highlighted">
        <ng-template #content>
          <div class="notification-warning-content"
            appTargetBlank>
            <div class="text-main"
              [innerHTML]="htmlContent | safe : 'html'">
            </div>
          </div>
        </ng-template>
      </app-notification>
    </div>
  </div>
</ng-container>