import { Component } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { HtmlContentService } from '@shared/services/html-content.service';

@Component({
  selector: 'app-notification-warnings',
  templateUrl: './notification-warnings.component.html',
  styleUrls: ['./notification-warnings.component.scss']
})
export class NotificationWarningsComponent extends ComponentBase {
  warnings?: string[];

  constructor(
    services: ComponentBaseService,
    htmlContent: HtmlContentService
  ) {
    super(services);

    const setWarnings = () => this.warnings = htmlContent.getManyFromDataset('top-notification')
      .map(item => item.content || '')
      .filter(item => item);

    if (htmlContent.isInitialSettingsLoaded) {
      if (!htmlContent.isLoaded) {
        htmlContent.setAvailableBySearchContent(false);
      }
      setWarnings();
    } else {
      this.pushSub(htmlContent.initialSettingsLoaded.subscribe(() => setWarnings()));
    }

    this.pushSub(htmlContent.getMany(result => this.warnings = result, 'top-notification', false));
  }
}
