import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Page, pages } from '@shared/models/common';
import { TenantService } from './tenant.service';

@Injectable()
export class NavigationService {
  constructor(
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _tenant: TenantService
  ) {
  }

  openSearch() {
    this._open(pages.results);
  }

  openBooking() {
    this._open(pages.booking);
  }

  openConfirmation(bookingId: string) {
    this._open(pages.confirmation, bookingId);
  }

  private _open(destination: Page, param?: string) {
    this._router.navigate([
      [this._tenant.id, destination, param].filter(x => x).join('/')
    ], {
      queryParamsHandling: 'preserve'
    });
  }
}
