import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ButtonType, Color, Size } from '@shared/models/common';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent implements OnChanges {
  @Input() name!: string;
  @Input() alt = '';
  @Input() buttonClass = '';
  @Input() type: ButtonType = 'icon';
  @Input() postfix = false;
  @Input() prefix = false;
  @Input() transparent = true;
  @Input() isLoading = false;

  @Input() stroked!: Color;
  @Input() filled!: Color;
  @Input() iconSize: Size = 'default';
  @Input() buttonSize: Size | 'input' = 'default';
  @Input() customButtonSize = false;

  @Input() disabled = false;
  @Input() tooltipText?: string;
  @Input() tooltipKey?: string;
  @Input() tooltipPosition: 'left' | 'right' | 'above' | 'below' | 'before' | 'after' = 'above';
  @Input() textStyle?: 'default' | 'selected' = 'default'
  @Input() @HostBinding('class.border-formControl') isBorder = false;

  allButtonClasses!: string;
  iconKey!: string;

  getAllButtonClasses() {
    const result = [this.buttonClass];
    if (this.transparent) {
      result.push('transparent-button');
    }

    if (!this.customButtonSize) {
      result.push(`button-size-${this.buttonSize}`);
    }
    // else if (this.type !== 'icon') {
    //   result.push('button-size-custom');
    // }

    result.push(`text-style-${this.textStyle}`)

    if (this.disabled && this.type === 'raised') {
      result.push(this.buttonSize === 'input' ? 'border-formControl' : 'border')
    }

    return result.join(' ');
  }

  getIconKey() {
    return this.alt || this.name;
  }

  ngOnChanges(_: SimpleChanges): void {
    this.allButtonClasses = this.getAllButtonClasses();
    this.iconKey = this.getIconKey();
  }
}
