import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ConfirmationService } from '@confirmation/services/confirmation.service';

@Component({
  selector: 'app-agreement-conditions',
  templateUrl: './agreement-conditions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgreementConditionsComponent {
  bookingDetails = this._confirmation.values.bookingDetails;

  paymentPolicy = this.bookingDetails.payment?.paymentPolicy;
  cancellationPolicy = this.bookingDetails.payment?.cancellationPolicy;

  constructor(
    private readonly _confirmation: ConfirmationService
  ) { }
}
