import { Component } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { PackageService } from '@shared/services/package.service';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss']
})
export class CartSummaryComponent extends ComponentBase {
  isSelectedPackage !: boolean;

  constructor(
    private readonly _package: PackageService,
    services: ComponentBaseService
  ) {
    super(services);
    super.pushSub(
      this._package.setWhenDataChanged(({ selectedPackage }) => this.isSelectedPackage = !!selectedPackage)
    );
  }
}
