export const globalBoolVariable = (key: string): boolean => {
  return !!process && !!process.env[key];
}

export const ssrAllowed = {
  data: () => globalBoolVariable('SSR_ALLOW_DATA'),
  search: () => globalBoolVariable('SSR_ALLOW_SEARCH'),
  icons: () => globalBoolVariable('SSR_ALLOW_ICONS'),
  underMaintenance: () => globalBoolVariable('UNDER_MAINTENANCE')
};
