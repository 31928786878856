<ng-container *transloco="let t">
  <div class="addon-select"
    *ngIf="totalResortAddOns">
    <div class="content-container">
      <div class="flex-column">
        <div class="section-header">
          <div class="section-header-text">
            <app-anchor type="section" [index]="orderNumber"></app-anchor>
            {{orderNumber}}. {{t('addOn.header')}}
            <div class="link"
              (click)="openPurchased(); toggler.toggleIsHidden()"
              *ngIf="toggler.isHidden && purchasedCount">
              ({{purchasedCount}})
            </div>
          </div>
          <app-section-toggler #toggler
            [isOnlyForTablet]="false">
          </app-section-toggler>
        </div>
        <div class="collapsable"
          [class.collapsed]="toggler.isHidden">
          <div class="section-subheader">
            <div class="section-subheader-text">
              {{t('addOn.subheader')}}&nbsp;
            </div>
            <div class="section-subheader-button">
              <app-icon-button
                (click)="openFilter()"
                name="sort"
                alt="addOn.filter.caption"
                stroked="iconStroke"
                [customButtonSize]="true"
                type="raised">
                {{t('addOn.filter.caption')}}
              </app-icon-button>
            </div>
          </div>
          <cdk-virtual-scroll-viewport
            [appendOnly]="true"
            class="card-options-container"
            *ngIf="isBrowser && addOns.length"
            orientation="horizontal"
            [minBufferPx]="minBufferPx"
            [maxBufferPx]="maxBufferPx"
            #options
            [itemSize]="itemSize">
            <ng-container
              *cdkVirtualFor="let addOn of addOns; trackBy: 'addOnId' | createTrackBy;">
              <app-select-option
                [currentAddOn]="addOn"
                *ngIf="!!addOn">
              </app-select-option>
            </ng-container>
          </cdk-virtual-scroll-viewport>
          <div class="addon-select-not-found not-found"
            *ngIf="!addOns.length">
            {{t('addOn.notFound')}}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #addOnFilter>
  <app-sidebar-layout scope="addOn">
    <ng-template #content>
      <app-addon-filter class="sidebar-scroll-container"></app-addon-filter>
    </ng-template>
  </app-sidebar-layout>
</ng-template>