import { Component, ChangeDetectionStrategy, Input, ContentChild, TemplateRef, Output, EventEmitter, HostBinding, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-account-layout-item',
  templateUrl: './account-layout-item.component.html',
  styleUrls: ['./account-layout-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountLayoutItemComponent implements OnChanges {
  @Input() isEditMode = false;
  @Input() viewText!: string;
  @Input() isHidden = false;

  @Output() viewTextClick = new EventEmitter();
  @ContentChild('editor') editor!: TemplateRef<unknown>;
  @HostBinding('style.flex') flex = '0 1 0%';

  clickViewText() {
    this.viewTextClick.emit();
  }

  ngOnChanges(): void {
    this.flex = this.isEditMode ? '1 1 100%' : '0 1 0%';
  }
}
