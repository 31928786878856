import { Component, ElementRef, ViewChild } from '@angular/core';
import { Package, RoomType } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { scrollHorizontally } from '@shared/common';
import { WithId } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { PackageService } from '@shared/services/package.service';

@Component({
  selector: 'app-room-select',
  templateUrl: './room-select.component.html',
  styleUrls: ['./room-select.component.scss'],
})
export class RoomSelectComponent extends ComponentBase {
  @ViewChild('options') options!: ElementRef;

  roomTypesPackagesOptions: WithId<{ roomType: RoomType, package?: WithId<Package> }>[] = [];

  constructor(
    private readonly _package: PackageService,
    services: ComponentBaseService
  ) {
    super(services);
    super.pushSub(
      this._package.setWhenDataChanged(({ roomTypesPackagesOptions }) =>
        this.roomTypesPackagesOptions = roomTypesPackagesOptions)
    );
  }

  scroll(toLeft: boolean) {
    scrollHorizontally(toLeft, this.options, this.roomTypesPackagesOptions.length);
  }
}
