import { HtmlAppendOptions } from '@utils/state-transfer';
import { environment } from 'src/environments/environment';

/**
 *
 * @param gatc googleAnalyticsTrackingCode
 * @returns if exists environemnt default code it should used
 */
const getCorrectGATC = (gatc?: string) =>
  environment.defaultGoogleAnalyticsTrackingCode || gatc;

const getCorrectGTMC = (gtmc?: string) =>
  environment.defaultGoogleTagManagerCode || gtmc;

const getCorrectMCC = (mcc?: string) =>
  environment.defaultMicrosoftClarityCode || mcc;

const getCorrectHC = (hc?: string) =>
  environment.defaultHeapCode || hc;

/**
 *
 * @param gatc googleAnalyticsTrackingCode
 * @returns load and install HtmlAppendOptions
 */
export const gtagAppendOptions = (gatc?: string): {
  load: HtmlAppendOptions,
  install: HtmlAppendOptions
} | undefined => {
  const code = getCorrectGATC(gatc);
  if (!code) {
    return undefined;
  }

  return {
    load: ({
      element: 'script',
      attributes: {
        async: true,
        defer: true,
        type: 'text/javascript',
        src: `https://www.googletagmanager.com/gtag/js?id=${code}`,
      }
    }),
    install: ({
      element: 'script',
      attributes: {
        async: true,
        defer: true,
        type: 'text/javascript',
        innerHTML: `
window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
gtag('js', new Date());

gtag('config', '${code}');
`
      }
    })
  };
};

export const gtmAppendOptions = (gtmc?: string): {
  script: HtmlAppendOptions,
  noscript: HtmlAppendOptions,
  iframe: HtmlAppendOptions
} | undefined => {
  const code = getCorrectGTMC(gtmc);
  if (!code) {
    return undefined;
  }

  return {
    script: ({
      element: 'script',
      attributes: {
        async: true,
        defer: true,
        type: 'text/javascript',
        innerHTML: `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;
f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${gtmc}');
`},
    }),
    noscript: ({
      element: 'noscript',
      parentQuery: 'body'
    }),
    iframe: {
      element: 'iframe',
      parentQuery: 'body noscript',
      style: {
        height: '0',
        width: '0',
        visibility: 'hidden',
        display: 'none'
      },
      attributes: {
        src: `https://www.googletagmanager.com/ns.html?id=${gtmc}`,
      }
    }
  };
}

export const mccAppendOptions = (gatc?: string): {
  script: HtmlAppendOptions
} | undefined => {
  const code = getCorrectMCC(gatc);
  if (!code) {
    return undefined;
  }

  return {
    script: ({
      element: 'script',
      attributes: {
        type: 'text/javascript',
        async: true,
        defer: true,
        innerHTML: `

(function(c,l,a,r,i,t,y){
  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "${code}");
`
      }
    })
  };
};

export const heapAppendOptions = (heapCode?: string): {
  script: HtmlAppendOptions
} | undefined => {
  const code = getCorrectHC(heapCode);
  if (!code) {
    return undefined;
  }

  return {
    script: ({
      element: 'script',
      attributes: {
        type: 'text/javascript',
        async: true,
        defer: true,
        innerHTML: `
window.heap = window.heap || [], heap.load = function (e, t) { window.heap.appid = e, window.heap.config = t = t || {}; var r = document.createElement("script"); r.type = "text/javascript", r.async = !0, r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js"; var a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(r, a); for (var n = function (e) { return function () { heap.push([e].concat(Array.prototype.slice.call(arguments, 0))) } }, p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "resetIdentity", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"], o = 0; o < p.length; o++)heap[p[o]] = n(p[o]) };
heap.load("${code}");
        `
      }
    })
  };
};
