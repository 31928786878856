<ng-container *transloco="let t">
  <div class="form">
    <form [formGroup]="form"
      #formElement>
      <ng-container #templates>
        <ng-template #error
          let-field="field"
          let-error="error"
          let-errorKey="errorKey">
          <span [innerHtml]="t([scope, 'error', error] | join, {
            field: [scope, errorKey || field] | translateFormComponentText: 'label',
            value: form.get(field)?.value || ''
          })">
          </span>
        </ng-template>

        <ng-template #caption
          let-field="field"
          let-required="required">
          {{[scope, field] | translateFormComponentText: 'label'}}
          <b class="color-required"
            *ngIf="required || (form?.get(field) | isFormControlRequired)">&nbsp;*</b>
        </ng-template>

        <ng-template #label
          let-field="field"
          let-required="required"
          let-labelKey="labelKey">
          <div class="label"
            *ngIf="form.get(field)?.untouched || !form.get(field)?.invalid">
            <ng-container *ngTemplateOutlet="caption; context: {
              field: labelKey || field,
              required
            }">
            </ng-container>
          </div>
          <div class="label"
            *ngIf="!form.get(field)?.untouched && !form.get(field)?.valid">
            <app-anchor
              *ngIf="anchorType"
              [type]="anchorType"
              [index]="[anchorNumber, field]">
            </app-anchor>
            <mat-error class="error-message bold">
              <ng-container *ngTemplateOutlet="error;
                context: { field, error: getFirstError(field), errorKey: labelKey }">
              </ng-container>
            </mat-error>
          </div>
        </ng-template>

        <ng-template #text
          let-field="field"
          let-required="required"
          let-labelKey="labelKey">
          <ng-container *ngTemplateOutlet="label; context: { field, required, labelKey }"></ng-container>
          <div class="value">
            <mat-form-field appearance="outline">
              <input matInput
                #input
                type="text"
                [name]="field"
                autocomplete="on"
                [id]="field"
                [placeholder]="[scope, labelKey || field] | translateFormComponentText: 'placeholder'"
                [formControlName]="field" />
            </mat-form-field>
          </div>
        </ng-template>

        <ng-template #select
          let-field="field"
          let-options="options"
          let-hideLabel="hideLabel">
          <ng-container *ngIf="!hideLabel">
            <ng-container *ngTemplateOutlet="label; context: { field }"></ng-container>
          </ng-container>
          <div class="value">
            <mat-form-field>
              <mat-select
                #select
                [id]="field"
                [placeholder]="t([scope, field] | join)"
                (focus)="selectFocus(select)"
                [compareWith]="compareWith"
                [formControlName]="field"
                (selectionChange)="selectChange(field, $event.value)">
                <mat-option
                  *ngFor="let option of options; trackBy: 'value' | createTrackBy"
                  [value]="option.value">
                  {{option.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-template>

        <ng-template #autocomplete
          let-field="field"
          let-options="options"
          let-isLoading="isLoading"
          let-isSeparatedButton="isSeparatedButton">
          <ng-container *ngTemplateOutlet="label; context: { field }"></ng-container>
          <div class="value"
            [class.flex]="isSeparatedButton">
            <mat-form-field appearance="outline">
              <input matInput
                #input
                type="text"
                [id]="field"
                [name]="field"
                autocomplete="on"
                (keydown.enter)="$event.preventDefault(); autocompleteSearch(field)"
                (input)="inputAutocompleteChange(field, input.value)"
                (blur)="isLoading !== undefined ? form.get(field)?.markAsTouched() : (0)"
                [placeholder]="[scope, field] | translateFormComponentText: 'placeholder'"
                [matAutocomplete]="formAutoComplete" />
              <app-icon-button
                name="search"
                type="icon"
                *ngIf="!isSeparatedButton"
                (click)="autocompleteSearch(field)"
                filled="transparent"
                [stroked]="input.value ? 'selected':  'border'"
                [postfix]="true"
                [isLoading]="isLoading"
                [disabled]="!input.value || isLoading"
                [transparent]="true">
              </app-icon-button>
            </mat-form-field>
            <mat-autocomplete
              (closed)="autocompleteClose(field)"
              (optionSelected)="autocompleteSelected(field, $event.option.value)"
              [autoActiveFirstOption]="false"
              [displayWith]="defaultDisplayWith"
              #formAutoComplete="matAutocomplete">
              <mat-option
                *ngFor="let option of options; trackBy: 'value' | createTrackBy"
                [value]="option"
                [disabled]="option.isDisabled">
                {{option.label}}
              </mat-option>
            </mat-autocomplete>
            <app-icon-button
              name="search"
              type="raised"
              *ngIf="isSeparatedButton"
              (click)="autocompleteSearch(field)"
              filled="transparent"
              [stroked]="input.value ? 'selected':  'border'"
              buttonSize="input"
              iconSize="big"
              class="margin-1-left"
              [postfix]="true"
              [isLoading]="isLoading"
              [disabled]="!input.value || isLoading"
              [transparent]="true">
              <span class="search-button"
                [class.color-selected]="input.value"
                [class.color-border]="!input.value">
                {{t('search')}}
              </span>
            </app-icon-button>
          </div>
        </ng-template>

        <ng-template #datetime
          let-field="field">
          <ng-container *ngTemplateOutlet="label; context: { field }"></ng-container>
          <input #timeInputPicker
            #timePicker="matTimepicker"
            matTimepicker
            [okLabel]="t('timePicker.ok')"
            [cancelLabel]="t('timePicker.cancel')"
            [anteMeridiemAbbreviation]="t('timePicker.am')"
            [postMeridiemAbbreviation]="t('timePicker.pm')"
            mode="12h"
            hidden
            (close)="$event.preventDefault()"
            (timeChange)="dateTimeChange(
              field,
              $event,
              datePicker.datepickerInput.getStartValue(),
              dateTimeInput
            )">
          <mat-form-field>
            <input #dateTimeInput
              [id]="field"
              matInput
              readonly
              [placeholder]="[scope, field] | translateFormComponentText: 'placeholder'"
              (keyup.enter)="dateTimeInput.click()"
              (click)="datePicker.opened ? (0) : datePicker.open()">
            <input #dateInput
              [matDatepicker]="datePicker"
              hidden>
            <mat-datepicker-toggle matSuffix [for]="datePicker" [tabIndex]="-1">
              <app-icon matDatepickerToggleIcon
                name="calendar"
                filled="selected">
              </app-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              panelClass="form-date-picker"
              #datePicker
              (closed)="dateTimeChange(
                field,
                timePicker.value,
                datePicker.datepickerInput.getStartValue(),
                dateTimeInput);
                timeInputPicker.click();">
            </mat-datepicker>
          </mat-form-field>
        </ng-template>
      </ng-container>

      <ng-template *ngTemplateOutlet="content; context: {
        text,
        select,
        label,
        caption,
        autocomplete,
        datetime
      }">
      </ng-template>
    </form>
  </div>
</ng-container>