import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appCurrency' })
export class AppCurrencyPipe extends CurrencyPipe implements PipeTransform {
  override transform(value: number | string | null | undefined): null;

  override transform(value: number | string | null | undefined): string | null {
    return this.getResult(value);
  }

  getResult(value: number | string | null | undefined): string | null {
    if (Number(value?.toString().trim() || -1) < 0) return null;
    const valueFormat = Number(value) % 1 === 0 ? '1.0-0' : '1.2-2';
    return super.transform(value, 'USD', 'symbol', valueFormat);
  }
}
