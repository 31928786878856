<ng-container *transloco="let t">
  <div [ngClass]="['package-pricing', size]"
    *ngIf="ratePlanType && (currentPackage || showWhenUnavailable)"
    [@packagePriceChange]="isAnimating">
    <div class="header">
      <app-info-label
        [size]="size"
        [text]="ratePlanType.name"
        type="ratePlanType">
      </app-info-label>
      <div class="header-bold">
        {{t(periodNights === 1 ? 'night' : 'nights', { value: periodNights })}}
      </div>
    </div>
    <div class="price">
      <ng-container *ngIf="currentPackage">
        <div class="price-base"
          *ngIf="basePrice !== finalPrice">
          {{basePrice | appCurrency}}
        </div>
        <div class="price-final"
          matTooltipPosition="below"
          [matTooltip]="t(['price', currentPriceFormat, 'tooltip'] | join)">
          {{finalPrice | appCurrency}}
        </div>
      </ng-container>
      <div class="price-format"
        [matTooltip]="t(['price', currentPriceFormat, 'tooltip'] | join)"
        *ngIf="currentPriceFormat !== 'total' && currentPackage">
        &nbsp;{{t(['price', currentPriceFormat, 'format'] | join)}}
      </div>
      <div class="price"
        *ngIf="!currentPackage">
        <div class="not-found" *ngIf="!unavailabilityReason">{{t('soldOut')}}</div>
        <div class="not-found" *ngIf="unavailabilityReason">{{t('price.' + unavailabilityReason.code, { value: unavailabilityReason.value})}}</div>
      </div>
    </div>
  </div>
  <ng-template #price
    let-value="value">
    <span class="price-format">
      {{ value | formatPrice: periodNights : guestsCount : roomsCount: currentPriceFormat }}
    </span>
  </ng-template>
</ng-container>