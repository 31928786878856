<ng-container *transloco="let t">
  <div class="booking-button">
    <app-icon-button
      name="lock"
      alt="cart.button.book"
      filled="default"
      [customButtonSize]="true"
      (click)="startBooking()"
      [transparent]="false"
      iconSize="big"
      buttonClass="select-button active w-100"
      type="raised">
      <div class="div">
        {{t('cart.button.book')}}
      </div>
    </app-icon-button>
    <app-payment-cards></app-payment-cards>
    <div class="loyalty-text">
      {{t('cart.subheader.loyalty')}}
    </div>
  </div>
</ng-container>