import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { Page } from '@shared/models/common';
import { SsrHelperService } from './ssr-helper.service';

@Injectable()
export class ComponentBaseService {
  constructor(
    @Inject(DOCUMENT) @Optional() public document: Document,
    public readonly ssrHelper: SsrHelperService,
  ) {
  }

  get search() {
    return this.document.location.search;
  }

  get pathName() {
    return this.document.location.pathname;
  }

  get isTest() {
    return this.search.toLowerCase().indexOf('istest=true') > -1;
  }

  getCurrentPage = (): Page | undefined => {
    const pathParts = this.pathName.split('/');
    return pathParts.length > 2 ? pathParts[2] as Page : undefined;
  }

  isPage = (page: Page) => page === this.getCurrentPage();
}
