<ng-container *transloco="let t">
  <div class="flight-summary flex"
    *ngIf="isJourney || isFlight">
    <div class="content-container">
      <app-confirmation-section
        #section
        scope="flightSummary">
        <ng-template #header *ngIf="isJourney">
          <div class="section-header-text">
            {{t([section.scope, 'section', 'header'] | join)}}
          </div>
          <div class="flight-confirmation"
            [innerHtml]="t([section.scope, recordLocator ? 'confirmed': 'notConfirmed'] | join, {
              value: recordLocator
            })">
          </div>
        </ng-template>

        <ng-template #journey
          let-type="type"
          let-summary="summary"
          let-airlines="airlines">
          <app-confirmation-section-record #record>
            <div class="flight-record flex-column">
              <div [ngClass]="record.htmlClasses.label">
                {{t([section.scope, type] | join)}}
              </div>
              <app-flight-journey
                [type]="type"
                [isDetailsMode]="true"
                [isOpenedDetails]="true"
                [journey]="summary.journey">
              </app-flight-journey>
            </div>
          </app-confirmation-section-record>
          <app-confirmation-section-record
            *ngFor="let airline of airlines"
            #record
            position="full"
            [scope]="section.scope">
            <div [ngClass]="[record.htmlClasses.row, record.positions.start]">
              <div [ngClass]="record.htmlClasses.label">
                <ng-container *ngIf="airlines.length > 1">
                  {{airline.name}} -
                </ng-container>
                {{t([section.scope, 'warning', 'baggage', 'label'] | join)}}
              </div>
              <div [ngClass]="record.htmlClasses.value"
                [innerHtml]="t([section.scope, 'warning', 'baggage', 'value'] | join, { value: airline.baggageInfoUrl })">
              </div>
            </div>
          </app-confirmation-section-record>
          <app-confirmation-section-record *ngIf="summary.transfer"
            position="start"
            [scope]="section.scope"
            field="transfer">
            {{summary.transfer.description}}
          </app-confirmation-section-record>
          <app-confirmation-section-record *ngIf="airlines.length"
            position="end"
            field="manage"
            labelWidth="50%"
            [scope]="section.scope">
            <div class="flex-column gap">
              <a [href]="airline.websiteUrl | safe: 'url'"
                class="airline link"
                *ngFor="let airline of airlines">
                {{airline.name}}
              </a>
            </div>
          </app-confirmation-section-record>
        </ng-template>

        <ng-template #flight
          let-flight="flight"
          let-type="type">

          <app-confirmation-section-record
            [field]="type"
            position="end"
            [scope]="section.scope">
            <div class="flex-start gap">
              <app-icon
                [name]="type === 'inbound' ? 'arrival' : 'departure'"
                filled="iconFlight">
              </app-icon>
              <span class="link">{{flight.flightNumber}}</span>
            </div>
          </app-confirmation-section-record>

          <app-confirmation-section-record
            field="departure"
            position="full"
            *ngIf="flight.departureDate || flight.departureAirportName"
            [scope]="section.scope">
            {{flight.departureDate | appDate: 'type3'}}
            <ng-container *ngIf="flight.departureDate && flight.departureAirportName">
              &nbsp;/&nbsp;
            </ng-container>
            {{flight.departureAirportName}}
          </app-confirmation-section-record>

          <app-confirmation-section-record
            field="arrival"
            position="full"
            *ngIf="flight.arrivalDate || flight.arrivalAirportName"
            [scope]="section.scope">
            {{flight.arrivalDate | appDate: 'type3'}}
            <ng-container *ngIf="flight.arrivalDate && flight.arrivalAirportName">
              &nbsp;/&nbsp;
            </ng-container>
            {{flight.arrivalAirportName}}
          </app-confirmation-section-record>

          <app-confirmation-section-record
            position="start"
            [scope]="section.scope"
            field="transfer">
            {{flight.airportTransfer}}
          </app-confirmation-section-record>
        </ng-template>

        <app-confirmation-section-record>
          {{t([section.scope, 'warning', 'datetime'] | join)}}
        </app-confirmation-section-record>

        <!--#region exisiting flight-->
        <ng-container *ngIf="isFlight">
          <ng-container *ngTemplateOutlet="flight; context: {
            flight: inboundFlight, type: 'inbound'
          }">
          </ng-container>
          <ng-container *ngTemplateOutlet="flight; context: {
            flight: outboundFlight, type: 'outbound'
          }">
          </ng-container>
        </ng-container>
        <!--#endregion exisiting flight-->

        <!--#region jorney-->
        <ng-container *ngIf="isJourney">
          <ng-container *ngTemplateOutlet="journey; context: {
            summary: inboundJourney,
            airlines: isTheSameAirlines ? [] : inboundAirlines,
            type: 'inbound'
          }">
          </ng-container>
          <ng-container *ngTemplateOutlet="journey; context: {
            summary: outboundJourney,
            airlines: outboundAirlines,
            type: 'outbound'
          }">
          </ng-container>
        </ng-container>
        <app-confirmation-section-record *ngIf="tickets?.length"
          position="end"
          field="tickets"
          [scope]="section.scope">
          <div class="flex-column gap">
            <b *ngFor="let ticket of tickets">
              {{ticket.ticket}}
            </b>
          </div>
        </app-confirmation-section-record>
        <app-confirmation-section-record *ngIf="inboundAirlines.length"
          [isDivider]="{ bottom: false }">
          {{t([section.scope, 'warning', 'airlines'] | join)}}
        </app-confirmation-section-record>
        <!--#endregion jorney-->
      </app-confirmation-section>
    </div>
  </div>
</ng-container>