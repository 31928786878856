import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { pages } from '@shared/models/common';

@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const newHeaders: Record<string, string> = {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept': 'application/json'
    };

    if (this.router.routerState.snapshot.url.indexOf(pages['bypass-results']) > -1) {
      newHeaders['CB-ALL-RPT'] = '3';
    }

    const ipOverride = this.router.routerState.snapshot.root?.queryParams['ipov'];
    if (ipOverride) {
      newHeaders['CB-IPOV'] = ipOverride;
    }

    request = request.clone({
      setHeaders: newHeaders,
    });

    return next.handle(request);
  }
}
