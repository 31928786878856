<ng-container *transloco="let t">
  <div class="notification-signup">
    <div class="main-container">
      <app-notification
        backgroundColor="successBackground"
        position="start"
        filled="successText"
        iconName="clock">
        <div class="color-successText">
          {{t('notification.prices')}}
        </div>
      </app-notification>
    </div>
  </div>
</ng-container>
