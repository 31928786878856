import { DateTime } from 'luxon';
import { dateFormat, formatDateTime } from 'src/app/formats/date-time';

export const formatTrackingDate = (date?: DateTime) => formatDateTime(date, dateFormat.type1);
export const stringifiers = {
  name: <T extends { name?: string }>(data?: T, idField?: keyof T) =>
    data ? `${data.name}` + (idField ? ` (${data[idField]})` : '') : '',

  fromTo: (from?: string, to?: string, defaultValue?: string) =>
    from && to ? `${from} → ${to}` : defaultValue || '',

  fromToDate: (from?: DateTime, to?: DateTime) =>
    stringifiers.fromTo(formatTrackingDate(from), formatTrackingDate(to))
}

export type TrackingEvent = 'purchase' | 'add_to_cart' | 'remove_from_cart' | 'begin_checkout';
export type TrackingData = Partial<TrackingDataField>;
export type TrackingDataInput = TrackingData | ((input: typeof stringifiers) => TrackingData);

export interface TrackingDataField {
  currency: 'USD';
  transaction_id: string;
  value: number; // total value
  items: PurchaseItem[];
}

export interface PurchaseItem {
  item_name: string;
  coupon?: string; // promocode
  discount?: number;
  index?: number; // position
  item_brand?: string; // tradingName
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_variant?: string;
  price: number; // per item
  quantity: number;
}
