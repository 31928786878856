import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferStateService } from './transfer-state.service';

@Injectable({
  providedIn: 'root'
})
export class SsrHelperService {
  readonly isServer = isPlatformServer(this._platformId);
  readonly isBrowser = isPlatformBrowser(this._platformId);

  constructor(
    @Inject(PLATFORM_ID) private readonly _platformId: string,
    private readonly _transferState: TransferStateService
  ) { }

  set<T>(key: string, data: T) {
    if (this.isServer) {
      this._transferState.set(key, JSON.stringify(data));
    }
  }

  get(key: string) {
    if (this.isBrowser) {
      return this._transferState.get<string | undefined>(key);
    }

    return undefined;
  }

  isBrowserOr(orValue: () => boolean): boolean {
    return this.isBrowser || orValue();
  }

  isServerAnd(andValue: () => boolean): boolean {
    return this.isServer && andValue();
  }

  isBrowserOrServerAnd(andValue: () => boolean) {
    return this.isBrowserOr(() => this.isServerAnd(andValue));
  }
}
