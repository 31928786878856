import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class BlockOverlayService {
  private _drawer!: MatDrawer;
  private _drawerGetter?: () => MatDrawer;
  private _onSuccessSetupDrawerAction?: () => void;

  constructor() { }

  get openedChange() {
    return this._drawer.openedChange;
  }

  get closedStart() {
    return this._drawer.closedStart;
  }

  get isOpened() {
    return this._drawer?.opened;
  }

  toggle() {
    this._toggleDrawer();
  }

  open() {
    if (!this._drawer || !this._drawer.opened) {
      this._toggleDrawer();
    }
  }

  close() {
    if (!this._drawer || this._drawer.opened) {
      this._toggleDrawer();
    }
  }

  setDrawer(drawerGetter: () => MatDrawer, onSuccessSetupDrawerAction: () => void) {
    this._drawerGetter = drawerGetter;
    this._onSuccessSetupDrawerAction = onSuccessSetupDrawerAction;
  }

  private _toggleDrawer() {
    if (this._drawerGetter) {
      this._setupDrawerSettings();
      this._toggleDrawer();
    } else if (this._drawer) {
      this._drawer.toggle();
    }
  }

  private _setupDrawerSettings() {
    if (this._drawerGetter) {
      this._drawer = this._drawerGetter();
      this._drawerGetter = undefined;
      if (this._onSuccessSetupDrawerAction) {
        this._onSuccessSetupDrawerAction();
      }
    }
  }
}
