import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ConfirmationService } from '@confirmation/services/confirmation.service';
import { unique } from '@shared/common';
import { tripProtectionCategory } from '@shared/consts/common';
import { DataService } from '@shared/services/data.service';

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentSummaryComponent {
  booking = this._confirmation.values.bookingDetails;
  payment = this.booking.payment;
  ratePlanType = this._data.values.ratePlanTypes
    .find(rpt => rpt.ratePlanTypeId === this.booking.accommodation?.ratePlanTypeId);

  addOns = (this.booking.addOns || [])
    .filter(addon => addon.categoryCode !== tripProtectionCategory)
    .map(addon => ({
      name: addon.name,
      totalPrice: (addon.price || 0) * (addon.quantity || 1),
      quantity: addon.quantity,
      ageGroup: addon.ageGroup
        ? `${addon.ageGroup?.agePeriod?.fromYear} - ${addon.ageGroup?.agePeriod?.toYear}`
        : undefined
    }));

  tripProtection = (this.booking.addOns || [])
    .find(addon => addon.categoryCode === tripProtectionCategory)

  tripProtectionCount = this.tripProtection?.quantity;
  tripProtectionTotalPrice = (this.tripProtection?.quantity || 0) * (this.tripProtection?.price || 0);

  total = this.payment?.totalPrice || 0;

  paymentPolicy = this.payment?.paymentPolicy;
  paymentScheduleItems = (this.payment?.paymentScheduleItems || []);
  paid = this.payment?.paymentsSum;

  existingFlights = this.booking?.flights?.existingFlights;
  flightsToBuy = this.booking?.flights?.flightsToBuy;
  airportTransfers = unique([
    this.existingFlights?.inboundFlight?.airportTransfer?.name,
    this.existingFlights?.outboundFlight?.airportTransfer?.name,
    this.flightsToBuy?.inboundAirportTransfer?.name,
    this.flightsToBuy?.outboundAirportTransfer?.name,
  ]);

  resort = this._data.values.resorts
    .find(resort => resort.resortId === this.booking.accommodation?.resortId);

  phone = this.resort?.phone;

  ratePlanTotal = ((this.booking.pricing?.finalPrice || 0) + (this.flightsToBuy?.itinerary?.price || 0)).toFixed(2);

  constructor(
    private readonly _confirmation: ConfirmationService,
    private readonly _data: DataService
  ) { }
}
