import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Package, SpecialOffer } from '@shared/api/be-api.generated';
import { ComponentBase } from '@shared/base/component.base';
import { WithId } from '@shared/models/common';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { PackageService } from '@shared/services/package.service';
import { PackageDiscountPipe } from 'src/app/pipes/package-discount.pipe';
import { SpecialOfferDiscountPipe } from 'src/app/pipes/special-offer-discount.pipe';

@Component({
  selector: 'app-special-offer-caption-primary',
  templateUrl: './special-offer-caption-primary.component.html',
  styleUrls: ['./special-offer-caption-primary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpecialOfferCaptionPrimaryComponent extends ComponentBase implements OnChanges, AfterViewInit {
  @Input() currentSpecialOffer!: SpecialOffer;
  @Input() isVisibleNotes = true;
  @Input() isAlwaysActive = false;

  selectedPackage?: WithId<Package>;
  specialOffer?: SpecialOffer;
  @ViewChild('notesContainer') notesContainers?: ElementRef;

  name?: string;
  notes?: string;
  discount!: string;
  isSelected!: boolean;
  isNonRefundable!: boolean;
  isNotesExpanded = false;

  constructor(
    private readonly _package: PackageService,
    private readonly _specialOfferDiscountPipe: SpecialOfferDiscountPipe,
    private readonly _packageDiscountPipe: PackageDiscountPipe,
    private readonly _cdr: ChangeDetectorRef,
    services: ComponentBaseService
  ) {
    super(services);
    if (!this.isAlwaysActive) {
      this.pushSub(
        this._package.setWhenDataChanged(() => this._setData(), false)
      );
    } else {
      this._setData();
    }
  }
  ngAfterViewInit(): void {
    if (this.services.ssrHelper.isBrowser) {
      this._trackReadMoreVisibility();
    }
  }

  private _trackReadMoreVisibility() {
    if (this.notesContainers) {
      const hiddenMoreClass = 'hidden-more';
      const observer = new ResizeObserver(items => {
        items.forEach(({ target }) => {
          if (target.clientHeight === target.scrollHeight) {
            if (!target.classList.contains(hiddenMoreClass)) {
              target.classList.add(hiddenMoreClass);
            }
          } else if (target.classList.contains(hiddenMoreClass)) {
            target.classList.remove(hiddenMoreClass);
          }
        });
      });

      observer.observe(this.notesContainers.nativeElement);
      this.onDestroyHandler = () => this.notesContainers && observer.unobserve(this.notesContainers.nativeElement);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { previousValue, currentValue } = changes['currentSpecialOffer'] || {};
    if (previousValue !== currentValue) {
      this._setSpecialOfferData(this.currentSpecialOffer);
    }
  }

  toggleIsExpandedNotes(event: Event) {
    event?.preventDefault();
    event?.stopPropagation();
    this.isNotesExpanded = !this.isNotesExpanded;
  }

  private _setData() {
    this.specialOffer = this._package.specialOffer;
    this.selectedPackage = this._package.selectedPackage;
    this._setSpecialOfferData(this.currentSpecialOffer);
    this._cdr.detectChanges();
  }

  private _setSpecialOfferData(value: SpecialOffer) {
    this.notes = value.notes;

    this.discount = this._specialOfferDiscountPipe.transform(value);

    this.name = value?.name?.toLowerCase().trim()?.replace(this.discount?.toLowerCase().trim(), '')
      .replace(this.discount.toLowerCase().replace('.00', ''), '')?.trim();

    this.isSelected = value === this._package.specialOffer || this.isAlwaysActive;

    this.isNonRefundable = !!this.currentSpecialOffer.cancellationPolicy?.isNonRefundable;
  }

  private _setDiscountFromName(value: SpecialOffer) {
    const discountFromName = value?.name?.indexOf(' ').toString()
      ? Number(value?.name?.split(' ')[0].replace('$', ''))
      : 0;

    this.discount = discountFromName > 0
      ? this._packageDiscountPipe.transform(undefined, discountFromName)
      : this._specialOfferDiscountPipe.transform(value);
  }
}
