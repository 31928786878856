import { ChangeDetectionStrategy, Component, Inject, InjectionToken, Input, OnChanges, Optional, SimpleChanges } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BASE_HREF } from '@shared/base-href.token';
import { ssrAllowed } from '@shared/consts/process-variables';
import { Color, IconScope, Size } from '@shared/models/common';
import { SsrHelperService } from '@shared/services/ssr-helper.service';
import { IconPathPipe } from 'src/app/pipes/icon-path.pipe';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnChanges {
  @Input() stroked!: Color;
  @Input() filled!: Color;
  @Input() scope: IconScope = 'icons';
  @Input() size: Size = 'default';
  @Input() name!: string;
  @Input() height?: number;
  @Input() width?: number;

  isRegistered = false;
  classValues!: string[];
  svgIcon!: string;
  iconStyles!: Partial<CSSStyleDeclaration>;

  constructor(
    @Optional() @Inject(BASE_HREF) private baseHref: InjectionToken<string>,
    private _iconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
    private _iconPath: IconPathPipe,
    private _ssrHelper: SsrHelperService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.classValues = [
      `stroked-${this.stroked}`,
      `filled-${this.filled}`,
      `icon-size-${this.size}`
    ];

    this.svgIcon = `${this.scope}:${this.name}`;

    if (changes['name'] && this._ssrHelper.isBrowserOr(ssrAllowed.icons)) {
      this._registerIcon();
    }

    if (!this.isRegistered) {
      this.classValues.push('mat-icon');
    }

    this.iconStyles = this.getIconStyles();
  }

  private getIconPath() {
    return [this.baseHref || '', this._iconPath.transform(this.name, this.scope)].join('');
  }

  private _registerIcon() {
    this._iconRegistry.addSvgIconInNamespace(this.scope, this.name,
      this._sanitizer.bypassSecurityTrustResourceUrl(this.getIconPath()));
    this.isRegistered = true;
  }

  private getIconStyles = (): Partial<CSSStyleDeclaration> =>
    this.height || this.width ? {
      height: this.height ? this.height + 'rem' : 'auto',
      width: this.width ? this.width + 'rem' : 'auto',
    } : {};
}
