import { Directive, HostListener } from '@angular/core';

@Directive({ selector: '[appTargetBlank]' })
export class TargetBlankDirective {
  @HostListener('click', ['$event']) onClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.tryOpenHref(event.target);
  }

  tryOpenHref(target: unknown) {
    if (target) {
      const { href, parentElement } = target as { href: string; parentElement: HTMLElement };
      if (href) {
        window.open(href, '_blank');
      } else if (parentElement) {
        this.tryOpenHref(parentElement);
      }
    }
  }
}
