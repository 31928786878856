import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'luxon';
import { durationStringify } from '../formats/date-time';

@Pipe({ name: 'flightDuration' })
export class FlightDurationPipe implements PipeTransform {
  transform(value?: Duration) {
    return durationStringify(value);
  }
}
