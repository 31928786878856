<ng-container *transloco="let t">
  <div [ngClass]="['full-size', 'background-color-'+ overlayColor]"
    *ngIf="isActive"
    (click)="closeBanner($event.target)">
    <div class="cursor-pointer h-100-min"
      [ngClass]="['flex-' + positionHorizontal + '-' + positionVertical]">
      <div [ngClass]="[wrapClass]">
        <div class="flex-column box-shadow cursor-default gap"
          [style.max-width]="maxWidth + unit"
          [ngClass]="'padding-' + paddingSize + ' background-color-' + background">
          <ng-container *ngIf="header">
            <ng-container *ngTemplateOutlet="header"></ng-container>
          </ng-container>
          <ng-container *ngIf="content">
            <ng-container *ngTemplateOutlet="content"></ng-container>
          </ng-container>
          <ng-container *ngIf="!content">
            <ng-content></ng-content>
          </ng-container>
          <div class="banner-button margin-top">
            <ng-container *ngIf="footer">
              <ng-container *ngTemplateOutlet="footer"></ng-container>
            </ng-container>
            <ng-container *ngIf="!footer">
              <button mat-raised-button
                color="primary"
                class="select-button w-100"
                (click)="hideBanner()">
                {{t('button.ok' )}}
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>