import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { NavigationService } from '@shared/services/navigation.service';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-booking-button',
  templateUrl: './booking-button.component.html',
  styleUrls: ['./booking-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingButtonComponent {
  @Output() bookingStarted = new EventEmitter();

  constructor(
    private readonly _navigation: NavigationService,
    private readonly _sidebar: SidebarService,
    private readonly _tracking: TrackingService
  ) { }

  startBooking() {
    this._navigation.openBooking();
    this._sidebar.close();
    this.bookingStarted.emit();
    this._tracking.beginCheckout();
  }
}
