<ng-container *ngIf="images && isVisible && isBrowser">
  <ng-image-slider
    [autoSlide]="false"
    [imagePopup]="false"
    [manageImageRatio]="true"
    [infinite]="infinite"
    [animationSpeed]="slideAnimationSpeedSeconds"
    [imageSize]="imageSize"
    [showArrow]="showArrow && images.length > 1"
    [images]="images"
    (arrowClick)="arrowClick($event)"
    #nav>
  </ng-image-slider>
  <div class="carousel-dots"
    hidden
    *ngIf="images && images.length > 1">
    <div class="carousel-dots-item"
      (click)="showImage(i)"
      *ngFor="let image of images; let i = index; trackBy: 'id' | createTrackBy"
      [class.is-active]="activeImageIndex === i">
    </div>
  </div>
</ng-container>
