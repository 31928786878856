import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ThemeService } from '@shared/services/theming/theme.service';
import Logger from 'js-logger';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CanActivateTenantGuard implements CanActivate {
  private _tenantId = '';

  constructor(
    private readonly _theme: ThemeService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const tenantId = route.params['tenantId'];
    if (!this._tenantId) {
      Logger.debug('Activating tenant #', tenantId);
      this._tenantId = tenantId;
      this._theme.setThemeForTenant(tenantId);
    }

    return true;
  }

}
