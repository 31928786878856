import { EventEmitter } from '@angular/core';
import { ApiException } from '@shared/api/be-api.generated';
import { SsrHelperService } from '@shared/services/ssr-helper.service';
import { TenantService } from '@shared/services/tenant.service';
import { Observable } from 'rxjs';
import { catchError, forkJoin } from 'rxjs';

export abstract class InitializationServiceBase<T> {
  values!: Required<T>;

  isInitialized!: boolean;
  initialazed = new EventEmitter()
  initilazeError = new EventEmitter<ApiException>();

  constructor(
    private readonly transferKey: string,
    private readonly fromJS: (data: unknown) => T,
    public readonly tenant: TenantService,
    protected readonly ssrHelper: SsrHelperService
  ) {
    this._tryRestore();
  }

  private _tryRestore() {
    const dataFromServer = this.ssrHelper.get(this.transferKey);
    if (dataFromServer) {
      this.values = this.fromJS(JSON.parse(dataFromServer)) as Required<T>;
    }

    this.isInitialized = !!dataFromServer;
  }

  initialize(dataLoaders: () => Record<keyof T, Observable<unknown[] | unknown>>) {
    if (!this.isInitialized) {
      forkJoin(dataLoaders())
        .pipe(catchError(error => this._setError(error as ApiException) && []))
        .subscribe(initializationData => this._setData(initializationData as Required<T>));
    }
    else {
      setTimeout(() => this.initialazed.emit());
    }

    return this.initialazed;
  }

  private _setData(initializationData: Required<T>) {
    this.isInitialized = true;
    this.values = { ...initializationData };
    this.initialazed.emit();
    this.ssrHelper.set(this.transferKey, this.values);
  }

  protected _setError(error: ApiException) {
    this.initilazeError.emit(error);
    return error;
  }

  setWhenInitialized(onInitialized: (input: Required<T>) => void) {
    if (this.isInitialized) {
      onInitialized(this.values);
    } else {
      return this.initialazed
        .subscribe(() => onInitialized(this.values));
    }

    return undefined;
  }
}
